import { useEffect, useState } from 'react';
import { OrderItem, ProductWithItemPendingFulfillmentStruct } from '../../store/config/types';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import { Chip } from '@mui/material';
import ItemPendingFulfillmentDetails from './ItemPendingFulfillmentDetails';
import { isKitItem } from '../../utils/fulfillment.helpers';
import { fixNumber, strOrAlt } from '../../utils/string.helpers';
import { OrderItemFulfillmentStatus } from '../../store/config/enums/warehouseWork.enum';

interface ItemPendingFulfillmentTableProps {
  items?: OrderItem[];
  // eslint-disable-next-line
  onAction?: (action: string, params: any) => void;
}

export default function ItemPendingFulfillmentTable({ items, onAction }: ItemPendingFulfillmentTableProps) {
  const [itemPendingFulfillment, setItemsPendingFulfillment] = useState<ProductWithItemPendingFulfillmentStruct[]>([]);

  useEffect(() => {
    const itemPFF: ProductWithItemPendingFulfillmentStruct[] = [];
    // const statuses={'INSUFFICIENT_INVENTORY':0, 'READY_TO_FULFILL':0, 'PENDING':0}
    // plain Items
    const plainItems: OrderItem[] = [];
    items?.forEach((item) => {
      item.additionalData = {
        ...item.additionalData,
        originalProductName: strOrAlt(item.product?.description, item.product?.name, ''),
      };
      if (isKitItem(item)) {
        item.product?.subProducts.forEach((product) => {
          const quantity = product.product_relation?.quantity ?? 0;
          const subOrder: OrderItem = {
            ...item,
            product,
            productId: product.productId,
            type: product.type,
            quantity: item.quantity * quantity,
            quantityOrdered: item.quantityOrdered * quantity,
            quantityCommitted: item.quantityCommitted * quantity,
          };
          plainItems.push(subOrder);
        });
      } else {
        plainItems.push(item);
      }
    });
    plainItems.forEach((item) => {
      const itemIndex = itemPFF.findIndex((itm) => itm.productId == item.product?.productId);
      if (itemIndex < 0) {
        itemPFF.push({
          ...item.product,
          orderItems: [item],
          statusSummary: getItemsStatusSummary([item], item.product?.productId),
          totalQuantity: item.quantity,
          totalCommited: item.quantityCommitted,
        } as ProductWithItemPendingFulfillmentStruct);
      } else {
        itemPFF[itemIndex].orderItems.push(item);
        itemPFF[itemIndex].statusSummary = getItemsStatusSummary(
          itemPFF[itemIndex].orderItems,
          item.product?.productId,
        );
        itemPFF[itemIndex].totalQuantity += item.quantity;
        itemPFF[itemIndex].totalCommited += item.quantityCommitted;
      }
    });
    setItemsPendingFulfillment(itemPFF);
  }, [items]);
  return (
    <FilterableTable
      data={itemPendingFulfillment.filter((itm) => itm.orderItems?.length)}
      columns={[
        { field: 'externalId', caption: 'ID', filterType: 'string', sortable: true },
        {
          field: 'name',
          caption: 'Product',
          filterType: 'string',
          render: (row) => (row.description ? row.description : row.name),
          sortable: true,
        },
        { field: 'type', caption: 'type', filterType: 'values', sortable: true },
        { field: 'totalQuantity', caption: 'Total', sortable: true, render: (row) => fixNumber(row.totalQuantity) },
        { field: 'totalCommited', caption: 'Commited', sortable: true, render: (row) => fixNumber(row.totalCommited) },
        { field: 'statusSummary', caption: 'Status', filterType: 'values', render: renderItemStatus, sortable: true },
      ]}
      defaultSortBy="externalId"
      extendable
      renderExtended={(row) => {
        return <ItemPendingFulfillmentDetails productWithFulfillableItems={row} onAction={onAction} />;
      }}
    />
  );
}

function renderItemStatus(row: ProductWithItemPendingFulfillmentStruct) {
  const colors: any = {
    'Needs Attention': { color: 'error', variant: 'filled' },
    'Pending Fulfillment': { color: 'info', variant: 'outlined' },
    'Ready to Fulfillment': { color: 'success', variant: 'outlined' },
  };
  if (row.statusSummary != 'Needs Attention') return <></>;
  return (
    <Chip
      label={row.statusSummary}
      size="small"
      variant={colors[row.statusSummary].variant}
      color={colors[row.statusSummary].color}
    />
  );
}
function getItemsStatusSummary(items: OrderItem[], productId?: number): string {
  if (items.some((item) => item.fulfillmentStatus == OrderItemFulfillmentStatus.INSUFFICIENT_INVENTORY))
    return 'Needs Attention';
  if (
    productId &&
    items.some(
      (item) =>
        item.fulfillmentStatus == OrderItemFulfillmentStatus.AUTOLOT_FAILED &&
        item.fulfillmentItems?.some(
          (ffItem) => ffItem.productId === productId && ffItem.lotId && !ffItem.inventoryLotQuantityId,
        ),
    )
  ) {
    return 'Needs Attention';
  }
  // if (items.every((item) => item.fulfillmentStatus == 'PENDING')) return 'Pending Fulfillment';
  // // if(items.every((item) => item.fulfillmentStatus == 'READY_TO_FULFILL'))
  // return 'Ready to Fulfillment';
  return 'Ok';
}
