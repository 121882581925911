import { Typography } from '@mui/material';
import { AIDataMeta } from '../prospero.types';
import UserDataChart from './UserDataChart';
import UserDataTable from './UserDataTable';
import UserDataNotification from './UserDataNotification';

export interface UserDataProps {
  data: any;
  type: string;
  captions?: any;
  meta?: AIDataMeta;
  introText?: string;
  debugMode?: boolean;
  // eslint-disable-next-line
  onDisplayAllClick?: (data: any) => void;
}
export default function UserData({
  type,
  data,
  captions,
  meta,
  introText,
  debugMode,
  onDisplayAllClick,
}: UserDataProps) {
  const [visualiztionType, visualizationForm] = type.split('.');
  let elm: any = null;
  switch (visualiztionType) {
    case 'chart':
      elm = (
        <UserDataChart
          data={data}
          captions={captions}
          type={visualizationForm}
          debugMode={debugMode}
          onDisplayAllClick={onDisplayAllClick}
        />
      );
      break;
    case 'notification':
      elm = <UserDataNotification data={data} debugMode={debugMode} type={visualizationForm} />;
      break;
    case 'table':
    default:
      elm = <UserDataTable data={data} meta={meta} debugMode={debugMode} onDisplayAllClick={onDisplayAllClick} />;
      break;
  }
  if (elm) {
    return (
      <div>
        {introText && (
          <Typography variant="subtitle1" mb={1}>
            {introText}
          </Typography>
        )}
        {elm}
      </div>
    );
  } else {
    return <></>;
  }
}
