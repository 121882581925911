import {
  CircularProgress,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Typography,
} from '@mui/material';
import { AIChatSession, AIChatSessionType } from '../../screens/Prospero/prospero.types';
import { AiAgentIcon } from '../../assets/images';
import { RootState } from '../../store/config/types';
import { ThunkDispatch } from 'redux-thunk';
import { aiFetchMoreSessions } from '../../store/actions/ai.actions';
import { connect, ConnectedProps } from 'react-redux';
import { useEffect, useState } from 'react';
import { Close, MoreHoriz } from '@material-ui/icons';
import './AIFloatChatbox.scss';
import moment from 'moment-timezone';
interface AISessionListProps {
  currentSessionId?: number;
  /* eslint-disable */
  onSessionClick?: (session: AIChatSession) => void;
  /* eslint-enable */
}

const mapStateToProps = (state: RootState, props: AISessionListProps) => ({
  auth: state.auth,
  ai: state.ai,
  ...props,
});
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  aiFetchMoreSessions: () => dispatch(aiFetchMoreSessions()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function AISessionList({ ai, currentSessionId, onSessionClick, aiFetchMoreSessions }: PropsFromRedux) {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSessionClick = (session: AIChatSession) => {
    if (onSessionClick) onSessionClick(session);
  };

  useEffect(() => {
    // if (auth?.account) aiFetchMoreSessions();
    if (!ai?.sessions.length) {
      aiFetchMoreSessions();
    }
  }, []);

  return (
    <Grid container flexDirection="column" style={{ maxWidth: '100%', height: '100%' }}>
      <Grid item className="ai-session-list-search-box">
        <Grid container pl={2} pr={1}>
          <Grid item flexGrow={1} position="relative">
            <InputBase placeholder="Search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
          </Grid>
          {searchQuery && (
            <Grid item>
              <IconButton size="small" onClick={() => setSearchQuery('')}>
                <Close style={{ width: 16 }} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item flexGrow={1} style={{ position: 'relative' }}>
        <div className="sessions-list">
          <List>
            {ai?.sessions
              .slice()
              .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())
              .filter((session) => !searchQuery || session.title?.toLowerCase().includes(searchQuery.toLowerCase()))
              .map((session, sIndex) => {
                const isCurrent = session.aiChatSessionId == currentSessionId;
                const totalTokens = session.conversation?.reduce((total, message) => {
                  return total + (message.usageInfo?.completionTokens ?? message.usageInfo?.promptTokens ?? 0);
                }, 0);
                return (
                  <ListItem key={sIndex}>
                    <ListItemButton
                      onClick={() => handleSessionClick(session)}
                      className={isCurrent ? 'current' : undefined}
                    >
                      <Grid container flexDirection="column">
                        <Grid item>
                          {session.type === AIChatSessionType.AGENT && (
                            <ListItemAvatar>
                              <img src={AiAgentIcon} width={24} height={24} style={{ opacity: 0.7 }} />
                            </ListItemAvatar>
                          )}
                          <Typography variant={isCurrent ? 'body1' : 'caption'} color={isCurrent ? 'white' : '#ccc'}>
                            {session.title ?? 'Untitled Conversation'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" fontSize={10} style={{ color: '#888' }}>
                            {moment(session.date).calendar()}
                            {ai?.debugMode && (
                              <span style={{ marginLeft: 24, color: '#888' }}>Usage: {totalTokens}</span>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemButton>
                  </ListItem>
                );
              })}

            <ListItem>
              <ListItemButton onClick={() => aiFetchMoreSessions()} className={'fetch-more'} disabled={ai.fetching}>
                {ai.fetching ? <CircularProgress size={20} /> : <MoreHoriz />}
                <Typography marginLeft={2} variant={'button'}>
                  More
                </Typography>
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      </Grid>
    </Grid>
  );
}
export default connector(AISessionList);
