import { Button, CircularProgress, Grid, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IntegrationService } from '../../../../services/integration.service';
import MotiveLogo from './logo';
export interface MotiveConnectorResponse {
  connected: boolean;
  error?: string;
  url?: string;
  nextState?: 'start' | 'code' | 'error' | 'connected';
}
export default function MotiveConnector(props: { afterDisconnect?: () => void }) {
  const location = useLocation();

  const [state, setState] = useState<'start' | 'code' | 'error' | 'connected'>('start');
  const [oauth2url, setOauth2url] = useState('');
  const [errorDescription, setErrorDescription] = useState<string>('');
  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    setWaiting(true);
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const error = queryParams.get('error');
    if (!error) {
      IntegrationService.connect('motive', { state, code })
        .then((data: MotiveConnectorResponse) => {
          setState(data.nextState ?? 'start');
          if (data.error) {
            setErrorDescription(data.error);
          }
          if (data.url) {
            setOauth2url(data.url);
          }
        })
        .catch((err) => {
          setState('error');
          setErrorDescription(err.message);
        })
        .finally(() => {
          setWaiting(false);
        });
    } else {
      setState('error');
      setErrorDescription(queryParams.get('error_description') ?? '');
      setWaiting(false);
    }
  }, [location]);
  const disconnect = () => {
    setWaiting(true);
    IntegrationService.disconnect('motive').finally(() => {
      setState('start');
      setWaiting(false);
      if (props.afterDisconnect) {
        props.afterDisconnect();
      }
    });
  };
  return (
    <Grid container p={2}>
      <MotiveLogo />
      {waiting ? (
        <Grid container mt={2}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {state == 'start' && (
            <>
              <Grid container mt={2}>
                <Typography variant="subtitle1">Connect to Motive</Typography>
              </Grid>
              <Grid container>
                <Link href={oauth2url}>Click here start</Link>
              </Grid>
            </>
          )}
          {state == 'error' && (
            <>
              <Grid container mt={2}>
                <Typography variant="subtitle1" color="red">
                  Error
                </Typography>
              </Grid>
              <Grid container>
                <Typography variant="body1" color="red">
                  {errorDescription}
                </Typography>
              </Grid>
            </>
          )}
          {state == 'connected' && (
            <>
              <Grid container mt={2} mb={2}>
                <Typography variant="subtitle1" color="green">
                  Connected.
                </Typography>
              </Grid>
              <Button variant="contained" color="error" onClick={disconnect}>
                Disconnect
              </Button>
            </>
          )}
        </>
      )}
    </Grid>
  );
}
