import { Grid, IconButton, Paper } from '@mui/material';
import CardHeader from '../../components/Card/CardHeader';
import Card from '../../components/Card/Card';
import { useParams } from 'react-router-dom';
import MotiveConnector from './connectors/Motive/motive';
import { Cancel } from '@material-ui/icons';
interface IntegrationParams {
  integrationName: string;
}
export default function IntegrationConnectPage() {
  const { integrationName } = useParams<IntegrationParams>();

  function cancelConnect(): void {
    location.href = '/settings/integrations';
  }

  return (
    <Card>
      <CardHeader color="primary">
        <Grid container>
          <Grid item flexGrow={1}>
            Connect to {integrationName}
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={cancelConnect}>
              <Cancel style={{ color: 'white' }} />
            </IconButton>
          </Grid>
        </Grid>
      </CardHeader>
      <Paper>
        <Grid container p={2}>
          <MotiveConnector afterDisconnect={cancelConnect} />
        </Grid>
      </Paper>
    </Card>
  );
}
