import { Tooltip } from '@mui/material';
import { ReactElement } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { DndTimeLineChartItem } from './DndTimelineChart';

interface DndTimelineItemProps {
  item: DndTimeLineChartItem;
  itemIndex: number;
  rowIndex?: number;
  itemWidth: number;
  provided: DraggableProvided;
  /* eslint-disable */
  itemRender?: (item: DndTimeLineChartItem, rowIndex?: number, itemIndex?: number) => ReactElement;
  itemToolTipRender?: (item: DndTimeLineChartItem, rowIndex?: number, itemIndex?: number) => ReactElement;
  onItemClick?: (itemIndex: number) => void;
  onItemDoubleClick?: (itemIndex: number) => void;
  /* eslint-enable */
}

export default function DndTimelineItem({
  item,
  rowIndex,
  itemIndex,
  itemWidth,
  provided,
  itemRender,
  itemToolTipRender,
  onItemClick,
  onItemDoubleClick,
}: DndTimelineItemProps) {
  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // console.log(`[${rowIndex},${itemIndex}]:${item.start.format('HH:mm')}`);
  const handleItemClick = () => {
    // setTooltipOpen(!tooltipOpen);
    onItemClick && onItemClick(itemIndex);
  };
  const handleItemDoubleClick = () => {
    // setTooltipOpen(!tooltipOpen);
    onItemDoubleClick && onItemDoubleClick(itemIndex);
  };

  return (
    <Tooltip
      // open={true}
      // onOpen={() => {
      //   setTooltipOpen(true);
      //   onOpenHandler && onOpenHandler(rowIndex ?? 0, itemIndex, true);
      // }}
      // onClose={() => {
      //   setTooltipOpen(false);
      //   onOpenHandler && onOpenHandler(rowIndex ?? 0, itemIndex, false);
      // }}
      title={
        itemToolTipRender ? (
          itemToolTipRender(item, rowIndex, itemIndex)
        ) : (
          <div className="stops-tooltip">
            <h3>{item.additionalInfo?.name ?? item.label}</h3>
          </div>
        )
      }
      placement={'top'}
      arrow
    >
      <div
        className={`dndt-row-item${item.highlight ? ' highlight' : ''}${item.hidden ? ' hidden' : ''} ${
          item.colorClass
        }`}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <div
          style={{ width: itemWidth, height: '100%' }}
          onClick={() => {
            handleItemClick();
          }}
          onDoubleClick={() => {
            handleItemDoubleClick();
          }}
        >
          {itemRender ? itemRender(item, rowIndex, itemIndex) : item.label}
        </div>
      </div>
    </Tooltip>
  );
}
