import { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { connect, ConnectedProps } from 'react-redux';
// import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment-timezone';
import { RootState } from '../../store/config/types';
import { CustomerLocationOrder, SortProperties } from '../../store/config/types/deliveryOrders.types';
import { dataTitle, TitleSection } from '../../utils/homeData';
import CardHome from '../../components/Card/CardHome';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Pagination from '../../components/Pagination/Pagination';
import SpecialInput from '../../components/SpecialInput/Input';
import { deliveryOrdersService } from '../../services/deliveryOrders.service';
import {
  deliveriesSortFunction,
  getDeliveryOrderSearchString,
  getStatusOfAllPackageGroups,
} from '../../utils/deliveryOrders.helpers';
import DeliveryOrdersAccordion from '../../components/DeliveryOrdersAccordion/DeliveryOrdersAccordion';
import { Grid, IconButton } from '@mui/material';
import { hasPermission } from '../../utils/permissions';
import { ThunkDispatch } from 'redux-thunk';
import { setToast } from '../../store/actions/toast.actions';
import { Add } from '@material-ui/icons';

const PAGE_LIMIT: number = 12;
const AUTO_REFRESH_INTERVAL: number = 60000;

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    loggedIn: state.auth.loggedIn,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Home({ auth, loggedIn }: PropsFromRedux) {
  const history: History = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState<string>('');
  const [deliveriesStatus, setDeliveriesStatus] = useState('ALL');
  const [todayDeliveryItems, setTodayDeliveryItems] = useState<CustomerLocationOrder[]>([]);
  const [sortProps, setSortProps] = useState<SortProperties>({ field: 'date', order: 'DESC' });
  const [doAutoRefresh, setDoAutoRefresh] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(new Date());

  const handleSortClick = (field: string) => {
    if (sortProps.field === field) {
      setSortProps({ ...sortProps, order: sortProps.order === 'ASC' ? 'DESC' : 'ASC' });
    } else {
      setSortProps({ ...sortProps, field });
    }
  };

  const handleDeliveryDelete = () => {
    fetchTodayDeliveryItems();
  };

  const fetchTodayDeliveryItems = () => {
    auth && auth.account && auth.account.shipper?.timezone && moment.tz.setDefault(auth.account.shipper.timezone);
    const today = moment().format('YYYY-MM-DD');
    if (auth.account?.shipper?.shipperId)
      deliveryOrdersService.fetchCustomersOrders(auth.account?.shipper?.shipperId, today).then((orders) => {
        setTodayDeliveryItems(
          orders?.map((order) => {
            return { ...order, status: getStatusOfAllPackageGroups(order) };
          }) ?? [],
        );
      });
  };

  const updateInfo = () => {
    fetchTodayDeliveryItems();
    setLastUpdate(new Date());
  };
  useEffect(() => {
    if (doAutoRefresh) {
      setDoAutoRefresh(false);
      setTimeout(() => {
        setDoAutoRefresh(true);
        updateInfo();
      }, AUTO_REFRESH_INTERVAL);
    }
  }, [doAutoRefresh]);

  useEffect(() => {
    fetchTodayDeliveryItems();
    setCurrentPage(1);
  }, [loggedIn, auth.account]);

  useEffect(() => {
    console.log(currentPage);

    if (currentPage <= 1 || todayDeliveryItems.length / PAGE_LIMIT <= 1) setCurrentPage(1);
  }, [todayDeliveryItems]);

  const handleCardHomeClick = (status: string) => {
    setDeliveriesStatus(status);
    setCurrentPage(1);
  };

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleCreateDeliveryOrder = () => history.push('/delivery-orders/create');

  const searchTextHandler = (text: string) => setSearchText(text);

  const filters = (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <SpecialInput
          element={{
            elementType: 'input',
            elementConfig: { type: 'text', placeholder: 'Search..' },
            value: searchText,
            validation: {},
          }}
          onChange={searchTextHandler}
        />
      </GridItem>
      <Grid
        textAlign="right"
        flexGrow={1}
        style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', paddingRight: 16 }}
      >
        <span style={{ color: 'gray', marginRight: 8 }}>Last Update: {moment(lastUpdate).format('hh:mm a')} </span>
        <a
          href="#"
          onClick={() => {
            updateInfo();
          }}
        >
          Update Now
        </a>
      </Grid>
    </GridContainer>
  );

  let deliveryOrdersList = null;

  if (todayDeliveryItems) {
    let deliveryOrdersToShow: CustomerLocationOrder[] = todayDeliveryItems;
    if (searchText !== '') {
      deliveryOrdersToShow = deliveryOrdersToShow.filter((deliveryOrder: CustomerLocationOrder) => {
        return getDeliveryOrderSearchString(deliveryOrder).toLowerCase().includes(searchText.toLowerCase());
      });
    }
    if (deliveriesStatus !== 'ALL') {
      deliveryOrdersToShow = deliveryOrdersToShow.filter(
        (item) => item.status?.toLowerCase() === deliveriesStatus.toLowerCase(),
      );
    }

    deliveryOrdersList = (
      <>
        {filters}
        <DeliveryOrdersAccordion
          title="Today Deliveries"
          renderAdditionalActions={() => {
            if (auth.account && hasPermission(auth.account, ['shipperAdmin.deliveryOrders.create'])) {
              return (
                <IconButton size="small" color="success" onClick={handleCreateDeliveryOrder}>
                  <Add style={{ color: 'white' }} />
                </IconButton>
              );
            } else return <></>;
          }}
          deliveryOrders={deliveryOrdersToShow
            ?.sort((a, b) => deliveriesSortFunction(sortProps.field, sortProps.order ?? 'DESC', a, b))
            .slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)}
          timezone={auth.account?.shipper?.timezone}
          onSortClick={handleSortClick}
          onDeliveriesDelete={handleDeliveryDelete}
          sortProps={sortProps}
          onError={(message) => {
            if (message) setToast(message, 'danger');
          }}
        />
        <Pagination
          totalRecords={deliveryOrdersToShow?.length}
          pageLimit={PAGE_LIMIT}
          pageNeighbours={10}
          onPageChanged={handlePageClick}
        />
      </>
    );
  } else {
    deliveryOrdersList = <div>No data to show</div>;
  }

  const titleContent = (
    <div className="card">
      {dataTitle.map((data: TitleSection) => {
        const amount =
          data.status === 'ALL'
            ? todayDeliveryItems.length
            : todayDeliveryItems.filter((item) => item.status?.toLowerCase() === data.status.toLowerCase()).length;
        return (
          <CardHome
            key={data.title}
            image={data.image}
            amount={amount}
            title={data.title}
            color={data.color}
            status={data.status}
            onCardClick={handleCardHomeClick}
            isSelected={deliveriesStatus === data.status}
          />
        );
      })}
    </div>
  );

  return (
    <Fragment>
      {titleContent}
      {deliveryOrdersList}
    </Fragment>
  );
}

export default connector(Home);
