import { CSVLine, DeliveryOrder, CustomerLocationOrder } from './types/deliveryOrders.types';
import {
  LoggedAccount,
  Courier,
  Vehicle,
  Customer,
  Trip,
  Stop,
  NotificationSettingsState,
  NotificationTemplates,
} from './types';
import { Warehouse } from './types/warehouses.types';
import { Shipper } from './types/shippers.types';
import { AIChatSession } from '../../screens/Prospero/prospero.types';

// #region Login

export const LOGGING_IN = 'LOGGING_IN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';

export interface LoggingInAction {
  type: typeof LOGGING_IN;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  token: string;
  account: LoggedAccount | undefined;
}

export interface LoginErrorAction {
  type: typeof LOGIN_ERROR;
  error: string;
}

export interface ClearLoginErrorAction {
  type: typeof CLEAR_LOGIN_ERROR;
}

export type LoginActionTypes = LoggingInAction | LoginSuccessAction | LoginErrorAction | ClearLoginErrorAction;

// #endregion Login

// #region Logout

export const LOGGING_OUT = 'LOGGING_OUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export interface LogginOutAction {
  type: typeof LOGGING_OUT;
}

export interface LogoutSuccess {
  type: typeof LOGOUT_SUCCESS;
}

export interface LogoutFailed {
  type: typeof LOGOUT_FAILED;
  error: string;
}

export type LogoutActionTypes = LogginOutAction | LogoutSuccess | LogoutFailed;

// #endregion Logout

// #region Account

export const GETTING_ACCOUNT = 'GETTING_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export interface GettingAccount {
  type: typeof GETTING_ACCOUNT;
}

export interface GetAccountSuccess {
  type: typeof GET_ACCOUNT_SUCCESS;
  account: LoggedAccount;
}

export interface GetAccountError {
  type: typeof GET_ACCOUNT_ERROR;
  error: string;
}

export type GetAccountActionTypes = GettingAccount | GetAccountSuccess | GetAccountError;

export const UPDATING_ACCOUNT = 'UPDATING_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR';

export interface UpdatingAccount {
  type: typeof UPDATING_ACCOUNT;
}

export interface UpdateAccountSuccess {
  type: typeof UPDATE_ACCOUNT_SUCCESS;
}

export interface UpdateAccountError {
  type: typeof UPDATE_ACCOUNT_ERROR;
  error: string;
}

export type UpdateAccountActionTypes = UpdatingAccount | UpdateAccountSuccess | UpdateAccountError;

export type AuthDataTypes = LoginActionTypes | LogoutActionTypes | GetAccountActionTypes | UpdateAccountActionTypes;

// #endregion Account

// #region Toast

export const SET_TOAST = 'SET_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export interface SetToastAction {
  type: typeof SET_TOAST;
  message: string;
  messageType: string;
}

export interface RemoveToastAction {
  type: typeof REMOVE_TOAST;
}

export type ToastActionTypes = SetToastAction | RemoveToastAction;

// #endregion Toast

// #region Couriers

export const FETCHING_COURIERS = 'FETCHING_COURIERS';
export const FETCH_COURIERS_SUCCESS = 'FETCH_COURIERS_SUCCESS';
export const FETCH_COURIERS_FAILED = 'FETCH_COURIERS_FAILED';

export interface FetchingCouriersAction {
  type: typeof FETCHING_COURIERS;
}

export interface FetchCouriersFailedAction {
  type: typeof FETCH_COURIERS_FAILED;
  error: string;
}

export interface FetchCouriersSuccessAction {
  type: typeof FETCH_COURIERS_SUCCESS;
  couriers: Courier[];
}

export type FetchCouriersTypes = FetchingCouriersAction | FetchCouriersSuccessAction | FetchCouriersFailedAction;

export const SET_COURIER = 'SET_COURIER';
export const UNSET_COURIER = 'UNSET_COURIER';

export interface SetCourierAction {
  type: typeof SET_COURIER;
  courierId: string;
}

export interface UnsetCourierAction {
  type: typeof UNSET_COURIER;
}

export type SetCourierType = SetCourierAction | UnsetCourierAction;

export const CREATING_COURIER = 'CREATING_COURIER';
export const CREATE_COURIER_SUCCESS = 'CREATE_COURIER_SUCCESS';
export const CREATE_COURIER_FAILED = 'CREATE_COURIER_FAILED';

export interface CreatingCourierAction {
  type: typeof CREATING_COURIER;
}

export interface CreateCourierSuccessAction {
  type: typeof CREATE_COURIER_SUCCESS;
  courier: Courier;
}

export interface CreateCourierFailedAction {
  type: typeof CREATE_COURIER_FAILED;
  error: string;
}

export type CreateCourierTypes = CreatingCourierAction | CreateCourierSuccessAction | CreateCourierFailedAction;

export const UPDATING_COURIER = 'UPDATING_COURIER';
export const UPDATE_COURIER_SUCCESS = 'UPDATE_COURIER_SUCCESS';
export const UPDATE_COURIER_FAILED = 'UPDATE_COURIER_FAILED';

export interface UpdatingCourierAction {
  type: typeof UPDATING_COURIER;
}

export interface UpdateCourierSuccessAction {
  type: typeof UPDATE_COURIER_SUCCESS;
  courier: Courier;
}

export interface UpdateCourierFailedAction {
  type: typeof UPDATE_COURIER_FAILED;
  error: string;
}

export type UpdateCourierTypes = UpdatingCourierAction | UpdateCourierSuccessAction | UpdateCourierFailedAction;

export const DELETING_COURIER = 'DELETING_COURIER';
export const DELETE_COURIER_SUCCESS = 'DELETE_COURIER_SUCCESS';
export const DELETE_COURIER_FAILED = 'DELETE_COURIER_FAILED';

export interface DeletingCourierAction {
  type: typeof DELETING_COURIER;
}

export interface DeleteCourierSuccessAction {
  type: typeof DELETE_COURIER_SUCCESS;
  courierId: number;
}

export interface DeleteCourierFailedAction {
  type: typeof DELETE_COURIER_FAILED;
  error: string;
}

export type DeleteCourierTypes = DeletingCourierAction | DeleteCourierSuccessAction | DeleteCourierFailedAction;

export const ADDING_VEHICLE = 'ADDING_VEHICLE';
export const ADD_VEHICLE_SUCCESS = 'ADD_VEHICLE_SUCCESS';
export const ADD_VEHICLE_FAILED = 'ADD_VEHICLE_FAILED';

export interface AddingVehicleAction {
  type: typeof ADDING_VEHICLE;
}

export interface AddVehicleSuccessAction {
  type: typeof ADD_VEHICLE_SUCCESS;
  vehicle: Vehicle;
}

export interface AddVehicleFailureAction {
  type: typeof ADD_VEHICLE_FAILED;
  error: string;
}

export type AddVehiclesTypes = AddingVehicleAction | AddVehicleSuccessAction | AddVehicleFailureAction;

export const UPDATING_VEHICLE = 'UPDATING_VEHICLE';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_FAILED = 'UPDATE_VEHICLE_FAILED';

export interface UpdatingVehicleAction {
  type: typeof UPDATING_VEHICLE;
}

export interface UpdateVehicleSuccessAction {
  type: typeof UPDATE_VEHICLE_SUCCESS;
  vehicle: Vehicle;
}

export interface UpdateVehicleFailureAction {
  type: typeof UPDATE_VEHICLE_FAILED;
  error: string;
}

export type UpdateVehiclesTypes = UpdatingVehicleAction | UpdateVehicleSuccessAction | UpdateVehicleFailureAction;

export const DELETING_VEHICLE = 'DELETING_VEHICLE';
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS';
export const DELETE_VEHICLE_FAILED = 'DELETE_VEHICLE_FAILED';

export interface DeletingVehicleAction {
  type: typeof DELETING_VEHICLE;
}

export interface DeleteVehicleSuccessAction {
  type: typeof DELETE_VEHICLE_SUCCESS;
  vehicleId: string;
}

export interface DeleteVehicleFailureAction {
  type: typeof DELETE_VEHICLE_FAILED;
  error: string;
}

export type DeleteVehiclesTypes = DeletingVehicleAction | DeleteVehicleSuccessAction | DeleteVehicleFailureAction;

export const CLEAR_COURIERS_STATE = 'CLEAR_COURIERS_STATE';

export interface ClearCouriersStateType {
  type: typeof CLEAR_COURIERS_STATE;
}

export type CouriersActionTypes =
  | FetchCouriersTypes
  | CreateCourierTypes
  | UpdateCourierTypes
  | DeleteCourierTypes
  | AddVehiclesTypes
  | UpdateVehiclesTypes
  | DeleteVehiclesTypes
  | SetCourierType
  | ClearCouriersStateType;

// #endregion Couriers

// #region Customers

export const FETCHING_CUSTOMERS = 'FETCHING_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILED = 'FETCH_CUSTOMERS_FAILED';

export interface FetchingCustomersAction {
  type: typeof FETCHING_CUSTOMERS;
}

export interface FetchCustomersFailedAction {
  type: typeof FETCH_CUSTOMERS_FAILED;
  error: string;
}

export interface FetchCustomersSuccessAction {
  type: typeof FETCH_CUSTOMERS_SUCCESS;
  customers: Customer[];
}

export type FetchCustomersTypes = FetchingCustomersAction | FetchCustomersFailedAction | FetchCustomersSuccessAction;

export const SET_CUSTOMER = 'SET_CUSTOMER';
export const UNSET_CUSTOMER = 'UNSET_CUSTOMER';

export interface SetCustomerAction {
  type: typeof SET_CUSTOMER;
  customerId: string;
}

export interface UnsetCustomerAction {
  type: typeof UNSET_CUSTOMER;
}

export type SetCustomerType = SetCustomerAction | UnsetCustomerAction;

export const CREATING_CUSTOMER = 'CREATING_CUSTOMER';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED';

export interface CreatingCustomerAction {
  type: typeof CREATING_CUSTOMER;
}

export interface CreateCustomerSuccessAction {
  type: typeof CREATE_CUSTOMER_SUCCESS;
  customer: Customer;
}

export interface CreateCustomerFailedAction {
  type: typeof CREATE_CUSTOMER_FAILED;
  error: string;
}

export type CreateCustomerTypes = CreatingCustomerAction | CreateCustomerSuccessAction | CreateCustomerFailedAction;

export const UPDATING_CUSTOMER = 'UPDATING_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED';

export interface UpdatingCustomerAction {
  type: typeof UPDATING_CUSTOMER;
}

export interface UpdateCustomerSuccessAction {
  type: typeof UPDATE_CUSTOMER_SUCCESS;
  customer: Customer;
}

export interface UpdateCustomerFailedAction {
  type: typeof UPDATE_CUSTOMER_FAILED;
  error: string;
}

export type UpdateCustomerTypes = UpdatingCustomerAction | UpdateCustomerSuccessAction | UpdateCustomerFailedAction;

export const CLEAR_CUSTOMERS_STATE = 'CLEAR_CUSTOMERS_STATE';

export interface ClearCustomersStateType {
  type: typeof CLEAR_CUSTOMERS_STATE;
}

export type CustomersActionTypes =
  | FetchCustomersTypes
  | CreateCustomerTypes
  | UpdateCustomerTypes
  | SetCustomerType
  | ClearCustomersStateType;

// #endregion Customers

// #region Shippers

export const FETCHING_SHIPPERS = 'FETCHING_SHIPPERS';
export const FETCH_SHIPPERS_SUCCESS = 'FETCH_SHIPPERS_SUCCESS';
export const FETCH_SHIPPERS_FAILED = 'FETCH_SHIPPERS_FAILED';

export interface FetchingShippersAction {
  type: typeof FETCHING_SHIPPERS;
}

export interface FetchShippersFailedAction {
  type: typeof FETCH_SHIPPERS_FAILED;
  error: string;
}

export interface FetchShippersSuccessAction {
  type: typeof FETCH_SHIPPERS_SUCCESS;
  shippers: Shipper[];
}

export type FetchShippersTypes = FetchingShippersAction | FetchShippersFailedAction | FetchShippersSuccessAction;

export const SET_SHIPPER = 'SET_SHIPPER';
export const UNSET_SHIPPER = 'UNSET_SHIPPER';

export interface setShipperInState {
  type: typeof SET_SHIPPER;
  shipperId: number;
}

export interface unsetShipperInState {
  type: typeof UNSET_SHIPPER;
}

export type SetShipperTypes = setShipperInState | unsetShipperInState;

export const CREATING_SHIPPER = 'CREATING_SHIPPER';
export const CREATE_SHIPPER_SUCCESS = 'CREATE_SHIPPER_SUCCESS';
export const CREATE_SHIPPER_FAILED = 'CREATE_SHIPPER_FAILED';

export interface CreatingShipperAction {
  type: typeof CREATING_SHIPPER;
}

export interface CreateShipperSuccessAction {
  type: typeof CREATE_SHIPPER_SUCCESS;
  shipper: Shipper;
}

export interface CreateShipperFailedAction {
  type: typeof CREATE_SHIPPER_FAILED;
  error: string;
}

export type CreateShipperTypes = CreatingShipperAction | CreateShipperSuccessAction | CreateShipperFailedAction;

export const UPDATING_SHIPPER = 'UPDATING_SHIPPER';
export const UPDATE_SHIPPER_SUCCESS = 'UPDATE_SHIPPER_SUCCESS';
export const UPDATE_SHIPPER_FAILED = 'UPDATE_SHIPPER_FAILED';

export interface UpdatingShipperAction {
  type: typeof UPDATING_SHIPPER;
}

export interface UpdateShipperSuccessAction {
  type: typeof UPDATE_SHIPPER_SUCCESS;
  shipper: Shipper;
}

export interface UpdateShipperFailedAction {
  type: typeof UPDATE_SHIPPER_FAILED;
  error: string;
}

export type UpdateShipperTypes = UpdatingShipperAction | UpdateShipperSuccessAction | UpdateShipperFailedAction;

export const DELETING_SHIPPER = 'DELETING_SHIPPER';
export const DELETE_SHIPPER_SUCCESS = 'DELETE_SHIPPER_SUCCESS';
export const DELETE_SHIPPER_FAILED = 'DELETE_SHIPPER_FAILED';

export interface DeletingShipperAction {
  type: typeof DELETING_SHIPPER;
}

export interface DeleteShipperSuccessAction {
  type: typeof DELETE_SHIPPER_SUCCESS;
  shipperId: number;
}

export interface DeleteShipperFailedAction {
  type: typeof DELETE_SHIPPER_FAILED;
  error: string;
}

export type DeleteShipperTypes = DeletingShipperAction | DeleteShipperSuccessAction | DeleteShipperFailedAction;

export type ShippersActionTypes =
  | FetchShippersTypes
  | SetShipperTypes
  | CreateShipperTypes
  | UpdateShipperTypes
  | DeleteShipperTypes;

// #endregion Shippers

// #region Warehouses

export const FETCHING_WAREHOUSES = 'FETCHING_WAREHOUSES';
export const FETCH_WAREHOUSES_SUCCESS = 'FETCH_WAREHOUSES_SUCCESS';
export const FETCH_WAREHOUSES_FAILED = 'FETCH_WAREHOUSES_FAILED';

export interface FetchingWarehousesAction {
  type: typeof FETCHING_WAREHOUSES;
}

export interface FetchWarehousesFailedAction {
  type: typeof FETCH_WAREHOUSES_FAILED;
  error: string;
}

export interface FetchWarehousesSuccessAction {
  type: typeof FETCH_WAREHOUSES_SUCCESS;
  warehouses: Warehouse[];
}

export type FetchWarehousesTypes =
  | FetchingWarehousesAction
  | FetchWarehousesSuccessAction
  | FetchWarehousesFailedAction;

export const CREATING_WAREHOUSE = 'CREATING_WAREHOUSE';
export const CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS';
export const CREATE_WAREHOUSE_FAILED = 'CREATE_WAREHOUSE_FAILED';

export interface CreatingWarehouseAction {
  type: typeof CREATING_WAREHOUSE;
}

export interface CreateWarehouseSuccessAction {
  type: typeof CREATE_WAREHOUSE_SUCCESS;
  warehouse: Warehouse;
}

export interface CreateWarehouseFailedAction {
  type: typeof CREATE_WAREHOUSE_FAILED;
  error: string;
}

export type CreateWarehouseTypes = CreatingWarehouseAction | CreateWarehouseSuccessAction | CreateWarehouseFailedAction;

export const UPDATING_WAREHOUSE = 'UPDATING_WAREHOUSE';
export const UPDATE_WAREHOUSE_SUCCESS = 'UPDATE_WAREHOUSE_SUCCESS';
export const UPDATE_WAREHOUSE_FAILED = 'UPDATE_WAREHOUSE_FAILED';

export interface UpdatingWarehouseAction {
  type: typeof UPDATING_WAREHOUSE;
}

export interface UpdateWarehouseSuccessAction {
  type: typeof UPDATE_WAREHOUSE_SUCCESS;
  warehouse: Warehouse;
}

export interface UpdateWarehouseFailedAction {
  type: typeof UPDATE_WAREHOUSE_FAILED;
  error: string;
}

export type UpdateWarehouseTypes = UpdatingWarehouseAction | UpdateWarehouseSuccessAction | UpdateWarehouseFailedAction;

export const DELETING_WAREHOUSE = 'DELETING_WAREHOUSE';
export const DELETE_WAREHOUSE_SUCCESS = 'DELETE_WAREHOUSE_SUCCESS';
export const DELETE_WAREHOUSE_FAILED = 'DELETE_WAREHOUSE_FAILED';

export interface DeletingWarehouseAction {
  type: typeof DELETING_WAREHOUSE;
}

export interface DeleteWarehouseSuccessAction {
  type: typeof DELETE_WAREHOUSE_SUCCESS;
  warehouseId: number;
}

export interface DeleteWarehouseFailedAction {
  type: typeof DELETE_WAREHOUSE_FAILED;
  error: string;
}

export type DeleteWarehouseTypes = DeletingWarehouseAction | DeleteWarehouseSuccessAction | DeleteWarehouseFailedAction;

export const CLEAR_WAREHOUSES_STATE = 'CLEAR_WAREHOUSES_STATE';

export interface ClearWarehousesStateType {
  type: typeof CLEAR_WAREHOUSES_STATE;
}

export type WarehousesActionTypes =
  | FetchWarehousesTypes
  | CreateWarehouseTypes
  | UpdateWarehouseTypes
  | DeleteWarehouseTypes
  | ClearWarehousesStateType;

// #endregion Warehouses

// #region Delivery orders

export const FETCHING_DELIVERY_ORDERS = 'FETCHING_DELIVERY_ORDERS';
export const FETCH_DELIVERY_ORDERS_SUCCESS = 'FETCH_DELIVERY_ORDERS_SUCCESS';
export const FETCH_DELIVERY_ORDERS_FAILED = 'FETCH_DELIVERY_ORDERS_FAILED';

export interface FetchingDeliveryOrdersAction {
  type: typeof FETCHING_DELIVERY_ORDERS;
}

export interface FetchDeliveryOrdersSuccessAction {
  type: typeof FETCH_DELIVERY_ORDERS_SUCCESS;
  deliveryOrders: DeliveryOrder[];
}

export interface FetchDeliveryOrdersFailedAction {
  type: typeof FETCH_DELIVERY_ORDERS_FAILED;
  error: string;
}

export type FetchDeliveryOrdersTypes =
  | FetchingDeliveryOrdersAction
  | FetchDeliveryOrdersSuccessAction
  | FetchDeliveryOrdersFailedAction;

export const FETCHING_CUSTOMERS_DELIVERY_ORDERS = 'FETCHING_CUSTOMERS_DELIVERY_ORDERS';
export const FETCH_CUSTOMERS_DELIVERY_ORDERS_SUCCESS = 'FETCH_CUSTOMERS_DELIVERY_ORDERS_SUCCESS';
export const FETCH_CUSTOMERS_DELIVERY_ORDERS_FAILED = 'FETCH_CUSTOMERS_DELIVERY_ORDERS_FAILED';

export interface FetchingCustomersDeliveryOrdersAction {
  type: typeof FETCHING_CUSTOMERS_DELIVERY_ORDERS;
}

export interface FetchCustomersDeliveryOrdersSuccessAction {
  type: typeof FETCH_CUSTOMERS_DELIVERY_ORDERS_SUCCESS;
  customerDeliveryOrders: CustomerLocationOrder[];
}

export interface FetchCustomersDeliveryOrdersFailedAction {
  type: typeof FETCH_CUSTOMERS_DELIVERY_ORDERS_FAILED;
  error: string;
}
export type FetchCustomersDeliveryOrdersTypes =
  | FetchingCustomersDeliveryOrdersAction
  | FetchCustomersDeliveryOrdersSuccessAction
  | FetchCustomersDeliveryOrdersFailedAction;

export const CREATING_DELIVERY_ORDER = 'CREATING_DELIVERY_ORDER';
export const CREATE_DELIVERY_ORDER_SUCCESS = 'CREATE_DELIVERY_ORDER_SUCCESS';
export const CREATE_DELIVERY_ORDER_FAILED = 'CREATE_DELIVERY_ORDER_FAILED';

export interface CreatingDeliveryOrderAction {
  type: typeof CREATING_DELIVERY_ORDER;
}

export interface CreateDeliveryOrderSuccessAction {
  type: typeof CREATE_DELIVERY_ORDER_SUCCESS;
  deliveryOrder: DeliveryOrder;
}

export interface CreateDeliveryOrderFailedAction {
  type: typeof CREATE_DELIVERY_ORDER_FAILED;
  error: string;
}

export type CreateDeliveryOrderTypes =
  | CreatingDeliveryOrderAction
  | CreateDeliveryOrderSuccessAction
  | CreateDeliveryOrderFailedAction;

export const PARSING_CSV = 'PARSING_CSV';
export const PARSE_CSV_SUCCESS = 'PARSE_CSV_SUCCESS';
export const PARSE_CSV_FAILED = 'PARSE_CSV_FAILED';

export const EDIT_CSV_LINE = 'EDIT_CSV_LINE';
export const DELETE_CSV_LINE = 'DELETE_CSV_LINE';

export interface ParsingCSVAction {
  type: typeof PARSING_CSV;
}

export interface ParseCSVSuccessAction {
  type: typeof PARSE_CSV_SUCCESS;
  parsedCSVLines: CSVLine[];
}

export interface ParseCSVFailedAction {
  type: typeof PARSE_CSV_FAILED;
  error: string;
}

export interface EditCSVLineAction {
  type: typeof EDIT_CSV_LINE;
  csvLine: CSVLine;
}

export interface DeleteCSVLineAction {
  type: typeof DELETE_CSV_LINE;
  packageId: string;
}

export type ParseCSVTypes =
  | ParsingCSVAction
  | ParseCSVSuccessAction
  | ParseCSVFailedAction
  | EditCSVLineAction
  | DeleteCSVLineAction;

export const CLEAR_DELIVERY_ORDERS_STATE = 'CLEAR_DELIVERY_ORDERS_STATE';

export interface ClearDeliveryOrdersStateType {
  type: typeof CLEAR_DELIVERY_ORDERS_STATE;
}

export type DeliveryOrdersActionTypes =
  | FetchDeliveryOrdersTypes
  | FetchCustomersDeliveryOrdersTypes
  | CreateDeliveryOrderTypes
  | ParseCSVTypes
  | ClearDeliveryOrdersStateType;

// #endregion Delivery Orders

// #region Trips

export const FETCHING_TRIPS = 'FETCHING_TRIPS';
export const FETCH_TRIPS_SUCCESS = 'FETCH_TRIPS_SUCCESS';
export const FETCH_TRIPS_FAILED = 'FETCH_TRIPS_FAILED';

export const FETCHING_ALIVE_TRIPS = 'FETCHING_ALIVE_TRIPS';
export const FETCH_ALIVE_TRIPS_SUCCESS = 'FETCH_ALIVE_TRIPS_SUCCESS';
export const FETCH_ALIVE_TRIPS_FAILED = 'FETCH_ALIVE_TRIPS_FAILED';

export const FETCHING_TRIP_STOPS = 'FETCHING_TRIP_STOPS';
export const FETCH_TRIP_STOPS_SUCCESS = 'FETCH_TRIP_STOPS_SUCCESS';
export const FETCH_TRIP_STOPS_FAILED = 'FETCH_TRIP_STOPS_FAILED';

export interface FetchingTripsAction {
  type: typeof FETCHING_TRIPS;
}

export interface FetchTripsFailedAction {
  type: typeof FETCH_TRIPS_FAILED;
  error: string;
}

export interface FetchTripsSuccessAction {
  type: typeof FETCH_TRIPS_SUCCESS;
  // trips: Trip[];
  data: { trips: Trip[]; unAssignedOrders: CustomerLocationOrder[] };
}

export interface FetchingAliveTripsAction {
  type: typeof FETCHING_ALIVE_TRIPS;
}

export interface FetchAliveTripsFailedAction {
  type: typeof FETCH_ALIVE_TRIPS_FAILED;
  error: string;
}

export interface FetchAliveTripsSuccessAction {
  type: typeof FETCH_ALIVE_TRIPS_SUCCESS;
  // trips: Trip[];
  data: { trips: Trip[]; unAssignedOrders: CustomerLocationOrder[] };
}

export type FetchTripsTypes =
  | FetchingTripsAction
  | FetchTripsFailedAction
  | FetchTripsSuccessAction
  | FetchingAliveTripsAction
  | FetchAliveTripsFailedAction
  | FetchAliveTripsSuccessAction;

export interface FetchingTripStopsAction {
  type: typeof FETCHING_TRIP_STOPS;
}

export interface FetchTripStopsFailedAction {
  type: typeof FETCH_TRIP_STOPS_FAILED;
  error: string;
}

export interface FetchTripStopsSuccessAction {
  type: typeof FETCH_TRIP_STOPS_SUCCESS;
  stops: Stop[];
}

export type FetchTripStopsTypes = FetchingTripStopsAction | FetchTripStopsFailedAction | FetchTripStopsSuccessAction;

export const CREATING_TRIP = 'CREATING_TRIP';
export const CREATE_TRIP_SUCCESS = 'CREATE_TRIP_SUCCESS';
export const CREATE_TRIP_FAILED = 'CREATE_TRIP_FAILED';

export interface CreatingTripAction {
  type: typeof CREATING_TRIP;
}

export interface CreateTripSuccessAction {
  type: typeof CREATE_TRIP_SUCCESS;
  trips: Trip[];
}

export interface CreateTripFailedAction {
  type: typeof CREATE_TRIP_FAILED;
  error: string;
}

export type CreateTripTypes = CreatingTripAction | CreateTripSuccessAction | CreateTripFailedAction;

export const CREATING_TRIP_OFFER = 'CREATING_TRIP_OFFER';
export const CREATE_TRIP_OFFER_SUCCESS = 'CREATE_TRIP_OFFER_SUCCESS';
export const CREATE_TRIP_OFFER_FAILED = 'CREATE_TRIP_OFFER_FAILED';

export interface CreatingTripOfferAction {
  type: typeof CREATING_TRIP_OFFER;
}

export interface CreateTripOfferSuccessAction {
  type: typeof CREATE_TRIP_OFFER_SUCCESS;
  updatedTrip: Trip;
}

export interface CreateTripOfferFailedAction {
  type: typeof CREATE_TRIP_OFFER_FAILED;
  error: string;
}

export type CreateTripOfferTypes = CreatingTripOfferAction | CreateTripOfferSuccessAction | CreateTripOfferFailedAction;

export const DELETING_TRIP_OFFER = 'DELETING_TRIP_OFFER';
export const DELETE_TRIP_OFFER_SUCCESS = 'DELETE_TRIP_OFFER_SUCCESS';
export const DELETE_TRIP_OFFER_FAILED = 'DELETE_TRIP_OFFER_FAILED';

export interface DeletingTripOfferAction {
  type: typeof DELETING_TRIP_OFFER;
}

export interface DeleteTripOfferSuccessAction {
  type: typeof DELETE_TRIP_OFFER_SUCCESS;
  tripOfferId: number;
}

export interface DeleteTripOfferFailedAction {
  type: typeof DELETE_TRIP_OFFER_FAILED;
  error: string;
}

export type DeleteTripOfferTypes = DeletingTripOfferAction | DeleteTripOfferSuccessAction | DeleteTripOfferFailedAction;

export type TripsActionTypes =
  | FetchTripsTypes
  | CreateTripTypes
  | CreateTripOfferTypes
  | DeleteTripOfferTypes
  | FetchTripStopsTypes;

// #endregion Trips

// #region NotificationSystem
export const FETCHING_NOTIFICATION_SETTINGS = 'FETCHING_NOTIFICATION_SETTINGS';
export const FETCHING_NOTIFICATION_SETTINGS_SUCCESS = 'FETCHING_NOTIFICATION_SETTINGS_SUCCESS';
export const FETCHING_NOTIFICATION_SETTINGS_FAILED = 'FETCHING_NOTIFICATION_SETTINGS_FAILED';

export interface FetchingNotificationSettingsAction {
  type: typeof FETCHING_NOTIFICATION_SETTINGS;
}

export interface FetchNotificationSettingsFailedAction {
  type: typeof FETCHING_NOTIFICATION_SETTINGS_FAILED;
  error: string;
}

export interface FetchNotificationSettingsSuccessAction {
  type: typeof FETCHING_NOTIFICATION_SETTINGS_SUCCESS;
  settings: NotificationSettingsState;
}

export const UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS';
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_NOTIFICATION_SETTINGS_SUCCESS';
export const UPDATE_NOTIFICATION_SETTINGS_FAILED = 'UPDATE_NOTIFICATION_SETTINGS_FAILED';

export interface UpdateNotificationSettingsAction {
  type: typeof UPDATE_NOTIFICATION_SETTINGS;
}
export interface UpdateNotificationSettingsSuccessAction {
  type: typeof UPDATE_NOTIFICATION_SETTINGS_SUCCESS;
  settings: NotificationSettingsState;
}
export interface UpdateNotificationSettingsFailedAction {
  type: typeof UPDATE_NOTIFICATION_SETTINGS_FAILED;
  error: string;
}

export const FETCHING_NOTIFICATION_TEMPLATES = 'FETCH_NOTIFICATION_TEMPLATES';
export const FETCHING_NOTIFICATION_TEMPLATES_SUCCESS = 'FETCH_NOTIFICATION_TEMPLATES_SUCCESS';
export const FETCHING_NOTIFICATION_TEMPLATES_FAILED = 'FETCH_NOTIFICATION_TEMPLATES_FAILED';

export interface fetchingNotificationTemplatesAction {
  type: typeof FETCHING_NOTIFICATION_TEMPLATES;
}
export interface fetchNotificationTemplatesSuccessAction {
  type: typeof FETCHING_NOTIFICATION_TEMPLATES_SUCCESS;
  templates: NotificationTemplates;
}
export interface fetchNotificationTemplatesFailedAction {
  type: typeof FETCHING_NOTIFICATION_TEMPLATES_FAILED;
  error: string;
}

export type NotificationSystemActionTypes =
  | FetchingNotificationSettingsAction
  | FetchNotificationSettingsSuccessAction
  | FetchNotificationSettingsFailedAction
  | UpdateNotificationSettingsAction
  | UpdateNotificationSettingsSuccessAction
  | UpdateNotificationSettingsFailedAction
  | fetchingNotificationTemplatesAction
  | fetchNotificationTemplatesSuccessAction
  | fetchNotificationTemplatesFailedAction;

// #endregion NotificationSystem
export const SET_TIME = 'SET_TIME';
export interface setTimeAction {
  type: typeof SET_TIME;
  time: string;
}
export type TimeActionTypes = setTimeAction;

// #region AI
export const AI_FETCHING_SESSIONS = 'AI_FETCHING_SESSIONS';
export const AI_FETCH_SESSION_SUCCESS = 'AI_FETCH_SESSION_SUCCESS';
export const AI_FETCH_SESSION_FAILED = 'AI_FETCH_SESSION_FAILED';
export const AI_DEBUG_MODE = 'AI_DEBUG_MODE';

export interface AIFetchingSessionAction {
  type: typeof AI_FETCHING_SESSIONS;
}

export interface AIFetchSessionFailedAction {
  type: typeof AI_FETCH_SESSION_FAILED;
  error: string;
}

export interface AIFetchSessionsSuccessAction {
  type: typeof AI_FETCH_SESSION_SUCCESS;
  sessions: AIChatSession[];
}

export interface AIDebugModeAction {
  type: typeof AI_DEBUG_MODE;
  debugMode: boolean | undefined;
}

export type AIActionTypes =
  | AIFetchingSessionAction
  | AIFetchSessionFailedAction
  | AIFetchSessionsSuccessAction
  | AIDebugModeAction;

// #endregion AI

export type AppActions =
  | AuthDataTypes
  | ToastActionTypes
  | CouriersActionTypes
  | WarehousesActionTypes
  | DeliveryOrdersActionTypes
  | CustomersActionTypes
  | ShippersActionTypes
  | TripsActionTypes
  | NotificationSystemActionTypes
  | TimeActionTypes
  | AIActionTypes;
