import { Update, Search, Tune, Timeline, Close } from '@material-ui/icons';
import { CircularProgress, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import './TripsDnd.styles.scss';

interface TripDndTitleBarProps {
  title?: string;
  showOptimizeButton?: boolean;
  showZoneRoutingButton?: boolean;
  routing?: boolean;
  searchQuery?: string;
  // eslint-disable-next-line no-unused-vars
  onSearchQuery?: (query: string) => void;
  onReset?: () => void;
  onOptimizeClick?: () => void;
  onZoneRoutingClick?: () => void;
}
// const CssTextField = styled(TextField)({
//     '& label.Mui-focused': {
//       color: 'green',
//     },
//     '& .MuiInput-underline:after': {
//       borderBottomColor: 'green',
//     },
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: 'red',
//       },
//       '&:hover fieldset': {
//         borderColor: 'yellow',
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: 'green',
//       },
//     },
//   });
export default function TripDndTitleBar({
  title,
  routing,
  showOptimizeButton,
  showZoneRoutingButton,
  searchQuery,
  onSearchQuery,
  onOptimizeClick,
  onZoneRoutingClick,
  onReset,
}: TripDndTitleBarProps) {
  const handleClearClick = () => {
    if (onSearchQuery) onSearchQuery('');
  };
  return (
    <div className="trips-dnd-title-area">
      <div className="title">{title ?? 'Timeline'}</div>
      <div className="search-area">
        <TextField
          size="small"
          className="search-box"
          value={searchQuery}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className="search-icon" />
              </InputAdornment>
            ),
            endAdornment: searchQuery ? (
              <IconButton size="small" onClick={handleClearClick}>
                <Close className="search-icon" />
              </IconButton>
            ) : undefined,
          }}
          onChange={(e) => {
            onSearchQuery && onSearchQuery(e.currentTarget.value);
          }}
        />
      </div>
      <div className="action-area">
        {showZoneRoutingButton && (
          <>
            {routing ? (
              <Tooltip title="Zone Routing In Progress">
                <IconButton onClick={() => {}}>
                  <CircularProgress size={24} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Zone Routing">
                <IconButton
                  onClick={() => {
                    onZoneRoutingClick && onZoneRoutingClick();
                  }}
                >
                  <Timeline style={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
        {showOptimizeButton && (
          <Tooltip title="Optimize Board">
            <IconButton
              onClick={() => {
                onOptimizeClick && onOptimizeClick();
              }}
            >
              <Tune style={{ color: 'white' }} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Reset Board">
          <IconButton
            onClick={() => {
              onReset && onReset();
            }}
          >
            <Update style={{ color: 'white' }} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}
