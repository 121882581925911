import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../store/config/types';
import { useEffect, useState } from 'react';
import { authService } from '../../services/auth.service';
// import axios from '../../services/axios';

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
  };
};
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function Analysis({ auth }: PropsFromRedux) {
  const [config, setConfig] = useState<any>();
  useEffect(() => {
    if (auth) {
      const currentToken = config?.ds0?.token;
      authService.getDataProviderToken(currentToken).then((token) => {
        setConfig({ ds0: { token: token?.data } });
      });
    }
  }, [auth]);
  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
      }}
    >
      {config && config.ds0?.token ? (
        <iframe
          src={`${auth?.account?.shipper?.additionalInfo?.analysisPage}?config=${encodeURIComponent(
            JSON.stringify(config ?? {}),
          )}`}
          style={{ border: 0, width: '100%', display: 'flex', flexGrow: 1 }}
          allowFullScreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        ></iframe>
      ) : (
        <div>No Data Provided.</div>
      )}
    </div>
  );
}

export default connector(Analysis);
