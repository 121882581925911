import { Grid, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';

export interface UserDataNotificationProps {
  data: any;
  debugMode?: boolean;
  captions?: any;
  type: string;
  // eslint-disable-next-line
  onDisplayAllClick?: (data: any) => void;
}
export default function UserDataNotification({ data }: UserDataNotificationProps) {
  // Prepare data
  const { addToQueue, message, recipients }: { addToQueue: boolean; message: string; recipients: string[] } = data;

  return (
    <Grid item>
      <Typography variant="subtitle1">
        {addToQueue ? 'The email has been successfully added to the outbox' : 'Failed to send email'}
      </Typography>
      <Table size="small">
        <TableBody>
          {recipients && recipients.length && (
            <TableRow>
              <TableCell>To</TableCell>
              <TableCell>{recipients.join(', ')}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={2}>Message:</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>{message}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
}
