/* eslint-disable */
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from '../../store/config/types';
import { setToast } from '../../store/actions/toast.actions';
import { connect, ConnectedProps } from 'react-redux';
import { useEffect, useState } from 'react';
import { hasPermission } from '../../utils/permissions';
import { Chip, Grid, IconButton, Paper, Typography } from '@mui/material';
import { AiAgentIcon } from '../../assets/images';
import './AIAgents.style.scss';
import AIAgentWelcomeMessage from './AIAgentWelcomeMessage';
import ProsperoService from '../../services/prospero.service';
import { AIAgent } from '../Prospero/prospero.types';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import AIAgentDetails from './AIAgentDetails';
import { on } from 'events';
import moment from 'moment-timezone';
import { Launch } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import AIAgentActiveToggle from './AIAgentActiveToggle';

export const dateFormat = 'MM/DD/YYYY hh:mm a';

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
function AIAgents({ auth, setToast }: PropsFromRedux) {
  const [debugMode, setDebugMode] = useState(true);
  const [isAIAdmin, setIsAIAdmin] = useState(false);
  const [aiAgents, setAiAgents] = useState<AIAgent[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (auth.account?.shipperUserId) {
      ProsperoService.getAIAgents().then((agents) => {
        agents.forEach((agent, aIndex) => {
          agents[aIndex].solution.stepChain.sort((a, b) => (a.stepNumber ?? 0) - (b.stepNumber ?? 0));
        });
        setAiAgents(agents);
      });
      setIsAIAdmin(hasPermission(auth?.account, ['AI.adminMode']));
      setDebugMode(hasPermission(auth?.account, ['AI.adminMode']));
    }
  }, [auth]);

  const onOperation = async (operation: string, agent: AIAgent) => {
    let opr: string = '';
    let data = {};
    let onDone: any = () => {};
    switch (operation) {
      case 'active':
      case 'deactive':
        opr = 'active';
        data = { active: operation == 'active' };
        break;
      case 'execute':
        opr = 'execute';
        data = agent;
        onDone = (agent: AIAgent) => {
          openChatSession({}, agent.aiChatSessionId);
        };
        break;
    }
    if (opr) {
      const result = await ProsperoService.execAIAgentOperation(opr, { aiAgentId: agent.aiAgentId, data });
      if (result.result) {
        const newAgent: AIAgent = result.data;
        newAgent.solution?.stepChain?.sort((a, b) => (a.stepNumber ?? 0) - (b.stepNumber ?? 0));
        setAiAgents([...aiAgents.map((ag) => (ag.aiAgentId === newAgent.aiAgentId ? newAgent : ag))]);
        onDone(newAgent);
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const onNotification = (message: string, variant: string) => setToast(message, variant);

  function openChatSession(e: any, aiChatSessionId: number): void {
    if (e.stopPropagation) e.stopPropagation();
    history.push(`/prospero/${aiChatSessionId}`);
  }

  function renderTitle(row: AIAgent) {
    return (
      <>
        <IconButton
          onClick={(e) => openChatSession(e, row.aiChatSessionId)}
          size="small"
          disabled={!row.aiChatSessionId}
          color={row.aiChatSessionId ? 'primary' : 'default'}
        >
          <Launch />
        </IconButton>

        {row.title}
      </>
    );
  }

  return (
    <Grid container flexDirection="column" flexGrow={1}>
      <AIAgentWelcomeMessage />
      <Grid p={1} mt={2}>
        <Paper>
          <FilterableTable
            columns={[
              { field: 'title', caption: 'Title', filterType: 'string', sortable: true, render: renderTitle },
              {
                field: 'active',
                caption: 'Active',
                filterType: 'boolean',
                render: (row) => <AIAgentActiveToggle agent={row} onOperation={onOperation} />,
              },
              {
                field: 'lastExecution',
                caption: 'Last Execution',
                filterType: 'date',
                sortable: true,
                render: (row) => (
                  <Chip
                    label={moment(row.lastExecution).format(dateFormat)}
                    size="small"
                    variant="outlined"
                    color={row.additionalData?.lastExecutionIsSuccessful ? 'success' : 'error'}
                  />
                ),
              },
            ]}
            renderExtended={(row) => (
              <AIAgentDetails agent={row} onOperation={onOperation} onNotification={onNotification} />
            )}
            data={aiAgents.map((ag) => ({ ...ag, lastExecution: ag.additionalData?.lastExecutionDate }))}
            itemPerPage={10}
            extendable
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
export default connector(AIAgents);
