import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';
import { ChangeEvent, useEffect, useState } from 'react';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridItem from '../../components/Grid/GridItem';
import SpecialInput from '../../components/SpecialInput/Input';
import TableList from '../../components/TableList/TableList';
import Spinner from '../../components/Spinner/Spinner';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  ArrangeTripRequest,
  Courier,
  Customer,
  // CreateTripOfferRequest,
  CustomerLocationOrder,
  RootState,
  TripArrangeStop,
  TripRequest,
  TripWithStopsDetails,
} from '../../store/config/types';
import { fetchCouriers } from '../../store/actions/couriers.actions';
import { formatTimeWindow, maskPhone } from '../../utils/string.helpers';
import Checkbox from '@mui/material/Checkbox';
import { deliveryOrdersService } from '../../services/deliveryOrders.service';
import { createTrip } from '../../store/actions/trips.actions';
import { couriersService } from '../../services/couriers.service';
import tripsService from '../../services/trips.service';
// import TripDnd from '../../components/TripsDnd/TripsDnd';
import { useHistory, useParams } from 'react-router-dom';
import { fetchTrips } from '../../store/actions/trips.actions';
import { DeliveryServiceProvider } from '../../store/config/types/deliveryServiceProvider.types';
import CourierWithAvatar from '../../components/TripsDnd/CourierWithAvatar';
import { TripOptimizingMethod } from '../../store/config/enums/trips.enum';
import TripEditView from './TripEditView';
import { Warehouse } from '../../store/config/types/warehouses.types';

// import TripRow from '../../components/TripsDnd/TripRow';

interface TripWizardParams {
  date: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    couriers: state.couriers,
    trips: state.trips,
    loggedIn: state.auth.loggedIn,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchCouriers: () => dispatch(fetchCouriers()),
  fetchTrips: (from: Date | null = null, to: Date | null = null) => dispatch(fetchTrips(from, to)),
  createTrip: (tripRequest: TripRequest) => dispatch(createTrip(tripRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function TripCreationWizard({ fetchCouriers, fetchTrips, couriers, loggedIn, auth }: PropsFromRedux) {
  if (auth.account?.shipper?.timezone) moment.tz.setDefault(auth.account?.shipper?.timezone);

  const [activeStep, setActiveStep] = useState(0);
  const [tripDate, setTripDate] = useState(moment().format('YYYY-MM-DD'));
  const [packagesReadyTime, setPackagesReadyTime] = useState(
    moment().add(90, 'minutes').startOf('hour').format('HH:mm'),
  );
  const [selectedCouriers, setSelectedCouriers] = useState<number[]>([]);
  const [availableCouriers, setAvailableCouriers] = useState<number[]>([]);
  const [selectedDeliveryOrders, setSelectedDeliveryOrders] = useState<number[]>([]);

  const [deliveryServiceProviders, setDeliveryServiceProviders] = useState<DeliveryServiceProvider[]>([]);
  const [selectedDeliveryServiceProviders, setSelectedDeliveryServiceProviders] = useState<number[]>([]);
  const [isRoundTrip, setIsRoundTrip] = useState(true);
  const [searchText, setSearchText] = useState<string>('');
  const [deliveryOrdersOfDate, setDeliveryOrdersOfDate] = useState<CustomerLocationOrder[]>([]);
  const [routingIsInProgress, setRoutingIsInProgress] = useState(false);
  const [routingReturnsError, setRoutingReturnsError] = useState(false);
  const [arrangedTrips, setArrangedTrips] = useState<TripWithStopsDetails[] | undefined>(undefined);
  const [unAssignStops, setUnAssignStops] = useState<TripArrangeStop[]>([]);
  const [defaultOptimizingMethod, setDefaultOptimizingMethod] = useState<TripOptimizingMethod>(
    TripOptimizingMethod.NONE,
  );
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const history = useHistory();
  const { date } = useParams<TripWizardParams>();

  const fetchDeliveryServiceProviders = () => {
    setDeliveryServiceProviders([]);
    if (auth?.account?.shipper?.shipperId) {
      couriersService.fetchDeliveryServiceProviders().then((dsps) => setDeliveryServiceProviders(dsps ?? []));
    }
  };

  const fetchTripArranges = async () => {
    let everythingOk = packagesReadyTime.trim() != '' && tripDate.trim() != '';
    everythingOk &&= deliveryOrdersOfDate.length > 0;
    everythingOk &&= selectedCouriers.length > 0 || selectedDeliveryServiceProviders.length > 0;
    everythingOk &&= selectedDeliveryOrders.length > 0;
    if (everythingOk) {
      //const fixedTripDate = moment(tripDate, 'YYYY-MM-DD') < moment() ? moment().format('YYYY-MM-DD') : tripDate;
      const arrangedTripsReq: ArrangeTripRequest = {
        courierIds: selectedCouriers,
        packageGroupIds:
          selectedDeliveryOrders
            .map((deliveryOrderIndex) => deliveryOrdersOfDate[deliveryOrderIndex])
            .reduce<number[]>((finalArray, deliveryOrder) => {
              return [
                ...finalArray,
                ...(deliveryOrder.packageGroups.map((packageGroup) => packageGroup.packageGroupId ?? 0) ?? []),
              ];
            }, []) ?? [],
        startsAt: `${tripDate} ${packagesReadyTime}`,
        isRoundTrip,
      };

      return tripsService.arrangeTrip(arrangedTripsReq);
    }
  };
  const handleCancel = () => {
    history.goBack();
  };

  const handleEditCancel = () => {
    setArrangedTrips(undefined);
    setActiveStep(2);
  };
  const afterSave = () => {
    fetchTrips(moment(date, 'YYYY-MM-DD').toDate(), moment(date, 'YYYY-MM-DD').toDate()).finally(() => {
      history.goBack();
    });
  };

  useEffect(() => {
    fetchDeliveryServiceProviders();
  }, []);

  useEffect(() => {
    setTripDate(date);
  }, [date]);

  useEffect(() => {
    if (!couriers.loadingCouriers && !couriers.couriers && !couriers.couriersErrorMessage) {
      fetchCouriers();
    }
  }, [fetchCouriers, couriers.couriers, couriers.couriersErrorMessage, couriers.loadingCouriers, loggedIn]);

  useEffect(() => {
    if (tripDate) {
      deliveryOrdersService.fetchUnassignedOrders(tripDate).then((deliveryOrders) => {
        setDeliveryOrdersOfDate(deliveryOrders ?? []);
        setSelectedDeliveryOrders(deliveryOrders?.map((deliveryOrders, index) => index) ?? []);
        const orderCustomers: Customer[] = [];
        const orderWarehouse: Warehouse[] = [];
        deliveryOrders?.forEach((order) => {
          if (orderCustomers.findIndex((c) => c.customerId === order.customerId) < 0) {
            orderCustomers.push(order.customer);
          }
          if (orderWarehouse.findIndex((c) => c.warehouseId === order.warehouseId) < 0) {
            orderWarehouse.push(order.warehouse);
          }
        });
        setWarehouses(orderWarehouse);
        setCustomers(orderCustomers);
      });
      couriersService.fetchCouriersByDate(tripDate).then((couriers) => {
        if (couriers) {
          setSelectedCouriers(couriers.map((c) => c.courierId ?? 0));
          setAvailableCouriers(couriers.map((c) => c.courierId ?? 0));
        }
      });
    }
  }, [tripDate]);

  const searchTextHandler = (text: string) => setSearchText(text);
  const makeTripsToReview = (): void => {
    setRoutingIsInProgress(true);

    fetchTripArranges()
      .then((data: any) => {
        const trips = data?.trips;
        const optimizingMethod = data?.defaultTripOptimizingMethod;
        if (optimizingMethod) setDefaultOptimizingMethod(optimizingMethod);
        const startColorPaletteIndex = data?.startColorPaletteIndex ?? 0;
        // initial optimization
        if (trips && trips.length > 0) {
          tripsService.optimizeTrips(trips, true, optimizingMethod).then((trips) => {
            let hasError = false;
            trips.forEach((trip, tripIndex) => {
              trips[tripIndex].additionalData = {
                ...trips[tripIndex].additionalData,
                colorPaletteIndex: startColorPaletteIndex + tripIndex,
              };
              if (trip.status === 'optimizationError') {
                hasError = true;
              } else {
                trip.tripStops.forEach((stop, stopIndex) => {
                  const customer = deliveryOrdersOfDate.find((d) => d.customerId == stop.placeInfo?.placeId)?.customer;
                  stop.label = tripsService.makeStopLabel(tripIndex, stopIndex);
                  stop.location.customer = customer;
                });
              }
            });
            if (hasError) {
              setRoutingReturnsError(true);
              setRoutingIsInProgress(false);
            } else {
              const unAssignedStops = trips.filter((trip) => trip.tripId === -1);
              // const assignedTrips = trips.filter((trip) => trip.tripId !== -1);
              setRoutingReturnsError(false);
              setUnAssignStops(unAssignedStops.length > 0 ? unAssignedStops[0].tripStops : []);
              setArrangedTrips(JSON.parse(JSON.stringify(trips?.filter((trip) => trip.tripId !== -1))));
              setRoutingIsInProgress(false);
            }
          });

          // setRoutingReturnsError(false);

          // setArrangedTrips(trips);
          // setRoutingIsInProgress(false);
        } else {
          setRoutingReturnsError(true);
          setRoutingIsInProgress(false);
        }
      })
      .catch(() => {
        setRoutingReturnsError(true);
        setRoutingIsInProgress(false);
      });

    setActiveStep(3);
  };
  // Calculate routs

  const courierSearchBox = (
    <GridItem xs={12}>
      <SpecialInput
        element={{
          elementType: 'input',
          elementConfig: { type: 'text', placeholder: 'Search..' },
          value: searchText,
          validation: {},
        }}
        onChange={(e) => searchTextHandler(e)}
      />
    </GridItem>
  );

  let couriersContent = null;
  let thirdPartyContent = <>third-party not found</>;
  let deliveryOrdersContent = null;
  if (couriers.couriers) {
    let couriersToShow = couriers.couriers || [];

    if (searchText !== '') {
      couriersToShow = couriersToShow?.filter((courier) => {
        return ['firstName', 'lastName', 'phoneNumber'].some((key) => {
          return (courier as any)[key]?.toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }

    const selectCourierToggleSwitch = (courier: Courier) => {
      return (
        <>
          <Switch
            checked={selectedCouriers.includes(courier.courierId ?? -1)}
            onChange={(e) => {
              const idx = selectedCouriers.findIndex((item) => item == courier.courierId);
              if (e.target.checked) {
                if (idx < 0) setSelectedCouriers([...selectedCouriers, courier.courierId ?? 0]);
              } else {
                if (idx >= 0) setSelectedCouriers([...selectedCouriers.filter((id) => id != courier.courierId)]);
              }
            }}
          />
        </>
      );
    };

    const selectDeliveryServiceProviderToggleSwitch = (dsp: DeliveryServiceProvider) => {
      return (
        <>
          <Switch
            checked={selectedDeliveryServiceProviders.includes(dsp.deliveryServiceProviderId ?? -1)}
            onChange={(e) => {
              const idx = selectedDeliveryServiceProviders.findIndex((item) => item === dsp.deliveryServiceProviderId);
              if (e.target.checked) {
                if (idx < 0)
                  setSelectedDeliveryServiceProviders([
                    ...selectedDeliveryServiceProviders,
                    dsp.deliveryServiceProviderId ?? 0,
                  ]);
              } else {
                if (idx >= 0)
                  setSelectedDeliveryServiceProviders([
                    ...selectedDeliveryServiceProviders.filter((id) => id != dsp.deliveryServiceProviderId),
                  ]);
              }
            }}
          />
        </>
      );
    };

    const selectDeliveryOrderToggleSwitch = (deliveryOrderIndex: number) => {
      return (
        <Checkbox
          checked={selectedDeliveryOrders.includes(deliveryOrderIndex)}
          onChange={(e) => {
            const idx = selectedDeliveryOrders.findIndex((item) => item == deliveryOrderIndex);
            if (e.target.checked) {
              if (idx < 0) setSelectedDeliveryOrders([...selectedDeliveryOrders, deliveryOrderIndex]);
            } else {
              if (idx >= 0)
                setSelectedDeliveryOrders([...selectedDeliveryOrders.filter((id) => id != deliveryOrderIndex)]);
            }
          }}
        />
      );
    };

    const selectAllCourierToggleSwitch = () => {
      return (
        <Checkbox
          onChange={(e) => {
            if (e.target.checked && couriers.couriers) {
              setSelectedCouriers(couriers.couriers?.map((courier) => courier.courierId ?? 0));
            } else {
              setSelectedCouriers([]);
            }
          }}
        />
      );
    };

    const selectAllthirdPartySwitch = () => {
      return (
        <Checkbox
          onChange={(e) => {
            if (e.target.checked && couriers.couriers) {
              setSelectedDeliveryServiceProviders(
                deliveryServiceProviders?.map((dsp) => dsp.deliveryServiceProviderId ?? 0),
              );
            } else {
              setSelectedDeliveryServiceProviders([]);
            }
          }}
        />
      );
    };

    const selectAllDeliveryOrderToggleSwitch = () => {
      return (
        <Checkbox
          defaultChecked={true}
          onChange={(e) => {
            if (e.target.checked && deliveryOrdersOfDate) {
              setSelectedDeliveryOrders(deliveryOrdersOfDate?.map((deliveryOrder, index) => index));
            } else {
              setSelectedDeliveryOrders([]);
            }
          }}
        />
      );
    };
    const options = couriersToShow.map((courier) => {
      const vehicle = courier.vehicle && !!courier.vehicle.length ? courier.vehicle[0].vehicleType : null;
      const courierImage = courier.photoUrl
        ? courier.photoUrl
        : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';

      return [
        courier.courierId,
        selectCourierToggleSwitch(courier),
        availableCouriers.includes(courier.courierId ?? -1) ? 'Available' : '',
        courierImage,
        `${courier.firstName} ${courier.lastName}`,
        maskPhone(courier.phoneNumber),
        vehicle,
      ];
    });

    couriersContent = (
      <Box style={{ maxHeight: '80vh', overflow: 'auto', width: '100%' }}>
        <TableList
          head={['Id', selectAllCourierToggleSwitch(), '', 'Photo', 'Name', 'Phone number', 'Vehicle']}
          data={options}
        />
      </Box>
    );

    deliveryOrdersContent = (
      <Box style={{ maxHeight: '80vh', overflow: 'auto', width: '100%' }}>
        <TableList
          head={[
            'Id',
            selectAllDeliveryOrderToggleSwitch(),
            'Customer',
            'Address',
            'Packages',
            'Warehouse',
            'Time window',
          ]}
          data={deliveryOrdersOfDate?.map((deliveryOrder, index) => {
            const numberOfPackages = deliveryOrder.packageGroups?.reduce((total, packageGroup) => {
              return total + packageGroup.packages.length;
            }, 0);

            const fullName = `${deliveryOrder.customer.firstName} ${deliveryOrder.customer.lastName}`;
            const displayName = deliveryOrder.customer.companyName
              ? deliveryOrder.customer.companyName + ` (${fullName})`
              : fullName;

            return [
              index,
              selectDeliveryOrderToggleSwitch(index),
              displayName,
              `${deliveryOrder.shippingLocation?.streetAddress}`,
              <div key={index} style={{ textAlign: 'left' }}>
                {numberOfPackages}
              </div>,
              deliveryOrder.warehouse.name,
              formatTimeWindow(
                deliveryOrder.packageGroups[0]?.timeWindowFrom ?? null,
                deliveryOrder.packageGroups[0]?.timeWindowTo ?? null,
                false,
              ),
            ];
          })}
        />
      </Box>
    );

    if (deliveryServiceProviders.length > 0)
      thirdPartyContent = (
        <Grid container>
          <Grid>{selectAllthirdPartySwitch()} Use Third-party Network</Grid>
          {deliveryServiceProviders.map((dsp) => (
            <Grid container key={`third-party-${dsp.name}`} style={{ alignItems: 'center' }}>
              <Grid>{selectDeliveryServiceProviderToggleSwitch(dsp)}</Grid>
              <Grid>
                <CourierWithAvatar avatarUrl={dsp.logo} name={dsp.displayName} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      );
  } else if (couriers.loadingCouriers) {
    couriersContent = <Spinner />;
  } else {
    couriersContent = <div>No data to show</div>;
  }

  if (!couriers.couriersFetched) {
    return <Spinner />;
  }

  const changeDate = (date: string) => {
    if (moment(date).startOf('day').unix() >= moment().startOf('day').unix()) setTripDate(date);
  };

  const changeTime = (time: string) => {
    const selectedDate = moment(tripDate + ` ${time}`);
    if (selectedDate.unix() >= moment().unix()) setPackagesReadyTime(time);
  };

  if (arrangedTrips && !routingIsInProgress && !routingReturnsError) {
    return (
      <TripEditView
        availableCourierIds={availableCouriers}
        couriers={couriers?.couriers ?? []}
        customers={customers}
        date={date}
        deliveryServiceProviders={deliveryServiceProviders}
        timezone={auth?.account?.timezone}
        trips={arrangedTrips ?? []}
        unassigned={unAssignStops ?? []}
        warehouses={warehouses}
        onCancel={handleEditCancel}
        onAfterSave={afterSave}
        allTripsIsNew={true}
        defaultOptimizingMethod={defaultOptimizingMethod}
      />
    );
  } else {
    return (
      <div className="trip-form">
        <Card>
          <CardHeader color="primary" className="trip-header">
            <h4>New trip</h4>
          </CardHeader>
          <CardBody style={{ textAlign: 'left' }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step key={0}>
                <StepLabel
                  optional={
                    activeStep != 0 && (
                      <Typography variant="caption">{`${tripDate} at ${packagesReadyTime}`}</Typography>
                    )
                  }
                >
                  Trip&apos;s date and time
                </StepLabel>
                <StepContent>
                  <Typography variant="subtitle2" gutterBottom>
                    Please set the trip&apos;s date and specify the time when the packages are ready, If the packages
                    are available and the trip date is today, just click continue.
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth sx={{ m: 1 }}>
                        <TextField
                          id="date"
                          type="date"
                          label="Date"
                          variant="outlined"
                          value={tripDate}
                          onChange={(e) => {
                            changeDate(e.target.value);
                          }}
                          required
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth sx={{ m: 1 }}>
                        <TextField
                          id="time"
                          type="time"
                          label="Packages ready by"
                          variant="outlined"
                          value={packagesReadyTime}
                          onChange={(e) => changeTime(e.target.value)}
                          required
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="caption" color={deliveryOrdersOfDate.length > 0 ? 'primery' : 'error'}>
                        {`${deliveryOrdersOfDate.length > 0 ? deliveryOrdersOfDate.length : 'No'} Deliver${
                          deliveryOrdersOfDate.length > 1 ? 'ies' : 'y'
                        } found.`}
                      </Typography>
                    </Box>
                    <Box>
                      <Button onClick={() => handleCancel()} sx={{ mt: 1, mr: 1 }}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setActiveStep(1)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={
                          packagesReadyTime.trim() == '' || tripDate.trim() == '' || deliveryOrdersOfDate.length <= 0
                        }
                      >
                        Continue
                      </Button>
                    </Box>
                  </Box>
                </StepContent>
              </Step>

              <Step key={1}>
                <StepLabel
                  optional={
                    activeStep > 1 && (
                      <Typography variant="caption">
                        {(selectedCouriers.length > 0
                          ? `You have selected ${selectedCouriers.length} Courier` +
                            (selectedCouriers.length > 1 ? 's ' : ' ')
                          : 'You have not selected any Couriers ') +
                          (selectedDeliveryServiceProviders.length > 0
                            ? (selectedCouriers.length > 0 ? 'and also ' : 'but ') + 'enabled third-party network'
                            : '')}
                      </Typography>
                    )
                  }
                >
                  Select Couriers
                </StepLabel>
                <StepContent>
                  <Typography variant="subtitle2" gutterBottom>
                    Active couriers selected by default and you may manage manually
                  </Typography>
                  <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-start' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="isRoundTrip"
                          color="primary"
                          checked={isRoundTrip}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setIsRoundTrip(event.target.checked);
                          }}
                        />
                      }
                      label="Drivers have to return (Round Trip)."
                    />
                  </Box>
                  {courierSearchBox}
                  <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-start' }}>{couriersContent}</Box>
                  {deliveryServiceProviders.length > 0 && <Box>{thirdPartyContent}</Box>}
                  <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => setActiveStep(0)} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setActiveStep(2)}
                      sx={{ mt: 1, mr: 1 }}
                      disabled={selectedCouriers.length == 0 && selectedDeliveryServiceProviders.length === 0}
                    >
                      Continue
                    </Button>
                  </Box>
                </StepContent>
              </Step>

              <Step key={2}>
                <StepLabel
                  optional={
                    activeStep > 2 && (
                      <Typography variant="caption">
                        {selectedDeliveryOrders.length > 0
                          ? `${selectedDeliveryOrders.length} Deliver${
                              selectedDeliveryOrders.length > 1 ? 'ies' : 'y'
                            } selected`
                          : 'You have not selected any Delivery Orders '}
                      </Typography>
                    )
                  }
                >
                  Select Deliveries
                </StepLabel>
                <StepContent>
                  <Typography variant="subtitle2" gutterBottom>
                    description
                  </Typography>
                  <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-start' }}>{deliveryOrdersContent}</Box>
                  <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => setActiveStep(1)} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => makeTripsToReview()}
                      sx={{ mt: 1, mr: 1 }}
                      disabled={selectedDeliveryOrders.length < 1}
                    >
                      Continue
                    </Button>
                  </Box>
                </StepContent>
              </Step>

              <Step key={3}>
                <StepLabel optional={activeStep > 3 && <Typography variant="caption">{`Trips Summary`}</Typography>}>
                  Review Trips
                </StepLabel>
                <StepContent>
                  <Typography variant="subtitle2" gutterBottom>
                    {routingIsInProgress
                      ? `Routing is in progress, please wait ...`
                      : routingReturnsError
                      ? `Error!`
                      : `Trips are ready, you can edit them.`}
                  </Typography>
                </StepContent>
              </Step>
            </Stepper>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default connector(TripCreationWizard);
