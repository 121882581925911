import { Alert, Button, Chip, CircularProgress, Grid, Typography } from '@mui/material';
import { AIAgent } from '../Prospero/prospero.types';
import { AccessTime, PlayCircleOutline } from '@material-ui/icons';
import moment from 'moment-timezone';

import parser from 'cron-parser';
import { useState } from 'react';
import { dateFormat } from './AIAgents';

interface AIAgentDetailsProps {
  agent: AIAgent;
  /* eslint-disable */
  onOperation?: (operation: string, agent: AIAgent) => Promise<any>;
  onNotification?: (message: string, variant: string) => void;
  /* eslint-enable */
}
export default function AIAgentDetails({ agent, onOperation, onNotification }: AIAgentDetailsProps) {
  const interval = parser.parseExpression(agent.schedule, { utc: true });
  const nextExecution = interval.next().toDate();
  const [executing, setExecuting] = useState(false);

  const handleExecuteClick = () => {
    if (onOperation && !executing) {
      setExecuting(true);
      onOperation('execute', agent)
        .then((completed) => {
          if (onNotification) {
            const msg = completed ? 'Successfuly Executed' : 'Execution Failed';
            const variant = completed ? 'success' : 'danger';
            onNotification(msg, variant);
          }
        })
        .finally(() => {
          setExecuting(false);
        });
    }
  };
  // const handleActiveChange = (active: boolean) => {
  //   if (onOperation && !executing) {
  //     setExecuting(true);
  //     onOperation(active ? 'active' : 'deactive', agent).finally(() => {
  //       setExecuting(false);
  //     });
  //   }
  // };

  return (
    <Grid container p={2} flexDirection="column">
      <Grid item>
        <Typography variant="subtitle1">{agent.summary}</Typography>
      </Grid>
      <Grid item>
        <Grid container p={2} flexDirection="column">
          {agent.solution.stepChain.map((step) => (
            <Grid key={step.stepNumber}>
              {step.stepNumber}. {step.description}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <Alert icon={<AccessTime />} color="info">
          {agent.scheduleDescription}
        </Alert>
      </Grid>
      <Grid item>
        <Grid container mt={1}>
          {agent.active ? (
            <>
              <Grid item flexGrow={1}></Grid>
              <Grid item>
                <Typography variant="caption">
                  Will automatically execute at <Chip label={moment(nextExecution).format(dateFormat)} size="small" />
                </Typography>
                <Button
                  color="info"
                  variant="contained"
                  style={{ marginLeft: 8 }}
                  onClick={handleExecuteClick}
                  disabled={executing}
                >
                  {executing ? (
                    <CircularProgress size={16} style={{ marginRight: 8 }} />
                  ) : (
                    <PlayCircleOutline style={{ marginRight: 8 }} />
                  )}
                  Execute Now
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item flexGrow={1}></Grid>
              <Grid item>
                <Typography variant="caption">
                  To automatically execute at <Chip label={moment(nextExecution).format(dateFormat)} size="small" />,
                  activate the agent.
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
