import { Grid, IconButton, Typography } from '@mui/material';
import { AIAgentSetupSession, AIMessageParticipantType } from './prospero.types';
import { Assignment, Close, Receipt, Schedule } from '@material-ui/icons';
import { useEffect } from 'react';

interface AIAgentSetupFormProps {
  setupSession?: AIAgentSetupSession;
  processing?: boolean;
  onCancel?: () => void;
}

interface ConversationStructure {
  sender: 'AGENT' | 'USER';
  message: string;
}

export function AIAgentSetupForm({ setupSession, processing, onCancel }: AIAgentSetupFormProps) {
  useEffect(() => {
    const conversation: ConversationStructure[] = [];
    setupSession?.conversation.forEach((msg) => {
      if (msg.sender === AIMessageParticipantType.USER && msg.message.startsWith('MESSAGE')) {
        conversation.push({ sender: 'USER', message: msg.message.substring(8) });
      }
    });
    // setMessages(conversation);
  }, [setupSession, setupSession?.conversation]);
  if (!setupSession) return <></>;
  return (
    <Grid container flexDirection="column" className="agent-setup-form">
      <Grid item className="agent-setup-form-title" p={1} alignItems="center">
        <Grid container wrap="nowrap">
          <Grid item flexGrow={1} pl={1}>
            <Typography variant="body1">Setup {setupSession.title ? `${setupSession.title}` : 'an'} agent</Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              className="agent-setup-form-cancel-button"
              onClick={onCancel}
              disabled={processing}
            >
              <Close color="error" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item flexGrow={1}>
        <Grid container flexDirection="row" flexWrap="nowrap" className="agent-setup-form-content">
          <Grid item className="agent-setup-form-description">
            <Grid container flexDirection="column" flexGrow={1} style={{ height: '100%' }}>
              <Grid item flexGrow={1} className="agent-setup-form-summary box">
                <Assignment className="icon" />
                {setupSession.summary ?? ''}
              </Grid>
              <Grid item className="agent-setup-form-schedule box">
                <Schedule className="icon" />
                {setupSession.scheduleDescription ?? ''}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="agent-setup-form-solution box" p={1} flexGrow={1}>
            <Receipt className="icon" />
            {(setupSession.solver_instructions ?? []).map((step, idx) => (
              <Grid key={idx}>
                {idx + 1}.{step.description}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
