import { Grid } from '@mui/material';

interface PageHeaderProps {
  renderLeftArea?: () => any;
  renderTitleArea?: () => any;
  renderActionArea?: () => any;
}
export default function PageHeader({ renderLeftArea, renderActionArea, renderTitleArea }: PageHeaderProps) {
  return (
    <Grid container alignItems="center">
      {renderLeftArea && <Grid item>{renderLeftArea()}</Grid>}

      <Grid item flexGrow={1} alignContent="center">
        {renderTitleArea && renderTitleArea()}
      </Grid>

      {renderActionArea && <Grid item>{renderActionArea()}</Grid>}
    </Grid>
  );
}
