/* eslint-disable */
interface CumulativeOperationRunnerEvents {
  onCallSuccess?: (index?: any) => void;
  onCallFailed?: (index?: any) => void;
  onCompleted?: (canceled?: boolean) => void;
  onStartExecution?: (index?: any) => void;
}
export interface ExecutableOperation {
  index?: any;
  status?: 'queued' | 'executing' | 'success' | 'failed';
  operation: () => Promise<boolean>;
}
/* eslint-enable */
export default class CumulativeOperationRunner {
  private _events: CumulativeOperationRunnerEvents;
  private _operations: ExecutableOperation[];
  private _stopping = false;
  constructor(events: CumulativeOperationRunnerEvents = {}, operations: ExecutableOperation[]) {
    this._events = events;
    this._operations = operations;
    this._stopping = false;
  }
  setOperation(operations: ExecutableOperation[]) {
    this._operations = operations.map((opr) => {
      opr.status = 'queued';
      return opr;
    });
  }
  resetOperations() {
    // this._operations = [];
  }
  stop() {
    this._stopping = true;
  }
  async execute() {
    this._stopping = false;
    for (const opr of this._operations) {
      if (this._stopping) {
        break;
      }
      if (this._events.onStartExecution) {
        this._events.onStartExecution(opr.index);
      }
      try {
        await opr.operation().then(
          (result) => {
            console.log('result', result);
            if (result) {
              if (this._events.onCallSuccess) this._events.onCallSuccess(opr.index);
            } else {
              if (this._events.onCallFailed) this._events.onCallFailed(opr.index);
            }
          },
          () => {
            if (this._events.onCallFailed) this._events.onCallFailed(opr.index);
          },
        );
      } catch {
        if (this._events.onCallFailed) this._events.onCallFailed(opr.index);
      }
    }
    if (this._events.onCompleted) this._events.onCompleted(this._stopping);
  }
}
