import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Customer, RootState, TripArrangeStop, TripWithStopsDetails } from '../../store/config/types';
import { fetchCouriers } from '../../store/actions/couriers.actions';
import { couriersService } from '../../services/couriers.service';
import tripsService from '../../services/trips.service';
import { useHistory, useParams } from 'react-router-dom';
import { fetchTrips } from '../../store/actions/trips.actions';
import { DeliveryServiceProvider } from '../../store/config/types/deliveryServiceProvider.types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import TripEditView from './TripEditView';
import { TripOptimizingMethod } from '../../store/config/enums/trips.enum';

// import TripRow from '../../components/TripsDnd/TripRow';

interface EditTripsParams {
  date: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    couriers: state.couriers,
    trips: state.trips,
    loggedIn: state.auth.loggedIn,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchCouriers: () => dispatch(fetchCouriers()),
  fetchTrips: (from: Date | null = null, to: Date | null = null) => dispatch(fetchTrips(from, to)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function TripEdit({ fetchCouriers, fetchTrips, couriers, loggedIn, auth }: PropsFromRedux) {
  if (auth.account?.shipper?.timezone) moment.tz.setDefault(auth.account?.shipper?.timezone);

  const { date } = useParams<EditTripsParams>();
  const history = useHistory();

  const [availableCouriers, setAvailableCouriers] = useState<number[]>([]);
  const [deliveryServiceProviders, setDeliveryServiceProviders] = useState<DeliveryServiceProvider[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  // const [searchText, setSearchText] = useState<string>('');
  const [arrangedTrips, setArrangedTrips] = useState<TripWithStopsDetails[] | undefined>(undefined);
  const [unAssignStops, setUnAssignStops] = useState<TripArrangeStop[]>([]);
  const [defaultOptimizingMethod, setDefaultOptimizingMethod] = useState<TripOptimizingMethod>(
    TripOptimizingMethod.NONE,
  );

  const fetchDeliveryServiceProviders = () => {
    setDeliveryServiceProviders([]);
    if (auth?.account?.shipper?.shipperId) {
      couriersService.fetchDeliveryServiceProviders().then((dsps) => setDeliveryServiceProviders(dsps ?? []));
    }
  };

  const handleCancel = () => {
    tripsService
      .cancelEditTrip()
      .then((result) => {
        if (result) history.goBack();
      })
      .finally(() => {
        // setSaving(false);
      });
  };

  const afterSave = () => {
    fetchTrips(moment(date, 'YYYY-MM-DD').toDate(), moment(date, 'YYYY-MM-DD').toDate()).finally(() => {
      history.goBack();
    });
  };

  useEffect(() => {
    fetchDeliveryServiceProviders();
  }, []);

  useEffect(() => {
    if (!couriers.loadingCouriers && !couriers.couriers && !couriers.couriersErrorMessage) {
      fetchCouriers();
    }
  }, [fetchCouriers, couriers.couriers, couriers.couriersErrorMessage, couriers.loadingCouriers, loggedIn]);

  useEffect(() => {
    if (date) {
      tripsService
        .fetchTripsOfDateForEdit({ date: moment(date).toDate() })
        .then((result) => {
          if (result?.defaultTripOptimizingMethod) setDefaultOptimizingMethod(result.defaultTripOptimizingMethod);
          if (result?.customers) setCustomers(result.customers);
          if (result?.warehouses) setWarehouses(result.warehouses);
          const trips = result?.trips;
          if (trips) {
            const unAssignedStops = trips.filter((trip) => trip.tripId === -1);
            setUnAssignStops(unAssignedStops.length > 0 ? unAssignedStops[0].tripStops : []);
            setArrangedTrips(JSON.parse(JSON.stringify(trips?.filter((trip) => trip.tripId !== -1))));
          }
        })
        .catch((e) => console.error(e));

      couriersService.fetchCouriersByDate(date).then((couriers) => {
        if (couriers) {
          setAvailableCouriers(couriers.map((c) => c.courierId ?? 0));
        }
      });
    }
  }, [date]);

  // const searchTextHandler = (text: string) => setSearchText(text);

  // return (
  //   <div className="trips-dnd-full-page">
  //     {(arrangedTrips || unAssignStops) && (
  //       <>
  //         <div className="trips-dnd">
  //           <div className="trips-dnd-title-area">
  //             <Grid container>
  //               {!saving && (
  //                 <Grid flexGrow={1} textAlign="right">
  //                   <IconButton
  //                     size="small"
  //                     onClick={() => {
  //                       startSaving();
  //                     }}
  //                     disabled={saving || optimizing}
  //                   >
  //                     <Save style={{ color: saving ? 'gray' : 'white' }} />
  //                   </IconButton>

  //                   <IconButton
  //                     size="small"
  //                     onClick={() => {
  //                       handleCancel();
  //                     }}
  //                     disabled={saving}
  //                   >
  //                     <Cancel style={{ color: 'white' }} />
  //                   </IconButton>
  //                 </Grid>
  //               )}
  //               {saving && (
  //                 <Grid flexGrow={1} textAlign="right">
  //                   <IconButton size="small">
  //                     <CircularProgress size={24} />
  //                   </IconButton>
  //                   <IconButton size="small">
  //                     <CircularProgress size={24} />
  //                   </IconButton>
  //                 </Grid>
  //               )}
  //             </Grid>
  //           </div>
  //           <div className="map-area">
  //             <Map
  //               trips={(arrangedTrips ?? []).filter((t) => !t.additionalData?.unvisibleOnMap)}
  //               warehouses={warehouses}
  //               customersLocations={unAssignStops.map((stop) => stop.location)}
  //               onCustomerClick={handleCustomerClick}
  //               boundPoints={mapBoundPoints}
  //               highlightStopId={selectedStopId}
  //               onStopClick={(stopId) => {
  //                 setSelectedStopId(stopId);
  //               }}
  //             />
  //           </div>
  //           <div className="chart-area">
  //             <TripDndTimeline
  //               onTripVisibilityOnMapChange={handleTripVisibilityOnMapChange}
  //               onAllTripsVisibilityoggle={handleAllTripsVisibilityChange}
  //               trips={arrangedTrips ?? []}
  //               unAssignStops={unAssignStops}
  //               customers={customers}
  //               selectedCusomerIds={selectedCustomerIds}
  //               warehouses={warehouses}
  //               couriers={couriers.couriers ?? []}
  //               availableCouriersIds={availableCouriers}
  //               deliveryServiceProviders={deliveryServiceProviders}
  //               onReset={() => {
  //                 setArrangedTrips(JSON.parse(JSON.stringify(originalTrips?.filter((trip) => trip.tripId !== -1))));
  //                 setUnAssignStops(JSON.parse(JSON.stringify(originalUnAssignStops)));
  //               }}
  //               onBeforeChange={handleChange}
  //               onOptimizeLockChange={(tripIndex, value) => {
  //                 const trips: TripWithStopsDetails[] = JSON.parse(JSON.stringify(arrangedTrips));
  //                 trips[tripIndex].doNotOptimize = value;
  //                 setArrangedTrips(trips);
  //               }}
  //               onOptimizeClick={optimizeArrangedTrips}
  //               onZoneRoutingClick={doZoneRouting}
  //               routing={routing}
  //               showCurrentTime={true}
  //               timezone={auth.account?.shipper?.timezone}
  //               disableDnd={false}
  //               optimizing={optimizing}
  //               editMode={true}
  //             />
  //           </div>
  //         </div>
  //       </>
  //     )}

  //     <ThirdPartyDeliveryConfirm
  //       open={thirdPartyConfirmation}
  //       trips={arrangedTrips?.filter((trip) => (trip.editable ?? true) && trip.DSP)}
  //       customers={customers}
  //       onClose={() => {
  //         setThirdPartyConfirmation(false);
  //       }}
  //       onDeliveryCreationComplete={removeFailedThirdPartyAndSave}
  //     />
  //   </div>
  // );

  return (
    <TripEditView
      availableCourierIds={availableCouriers}
      couriers={couriers?.couriers ?? []}
      customers={customers}
      date={date}
      deliveryServiceProviders={deliveryServiceProviders}
      timezone={auth?.account?.timezone}
      trips={arrangedTrips ?? []}
      unassigned={unAssignStops ?? []}
      warehouses={warehouses}
      onCancel={handleCancel}
      onAfterSave={afterSave}
      defaultOptimizingMethod={defaultOptimizingMethod}
    />
  );
}

export default connector(TripEdit);
