import { Typography } from '@mui/material';
import { AiAgentIcon } from '../../assets/images';

export default function AIAgentWelcomeMessage() {
  return (
    <div style={{ textAlign: 'center' }} className="aiagents-titlebar">
      <img src={AiAgentIcon} alt="AI Agents" className="logo" />
      <Typography variant="h4">Prospero AI Agents</Typography>
      <Typography variant="body1">Your Analytical AI Agent</Typography>
      <Typography variant="body1" pt={2}>
        Welcome! Select an agent to explore insights
      </Typography>
    </div>
  );
}
