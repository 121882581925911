import axios from './axios';

async function connect(connector: string, data: any) {
  const res: any = await axios.post(`/integration/${connector}`, data);

  if (res.data.result) {
    return res.data.data;
  } else {
    throw new Error(res.data.data);
  }
}

async function disconnect(connector: string) {
  const res: any = await axios.post(`/integration/disconnect/${connector}`);

  if (res.data.result) {
    return res.data.data;
  } else {
    throw new Error(res.data.data);
  }
}

export const IntegrationService = { connect, disconnect };
