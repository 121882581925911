import { DndTimeLineChartItem, DndTimelineChartRow } from './DndTimelineChart';
import moment from 'moment-timezone';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ReactElement } from 'react';
import DndTimelineItem from './DndTimelineItem';
import { ColorPalette } from '../../store/config/types/ColorSpectrum';

export const MINIMUM_ITEM_WIDTH = 16;

interface DndTimelineRowProps {
  row: DndTimelineChartRow;
  rowIndex: number;
  additionAreaWidth?: number;
  start?: moment.Moment;
  minutesToPixel?: number;
  disableDragAndDrop?: boolean;
  /* eslint-disable */
  additionalAreaRender?: (rowIndex: number) => ReactElement;
  itemRender?: (item: DndTimeLineChartItem, rowIndex?: number, itemIndex?: number) => ReactElement;
  itemToolTipRender?: (item: DndTimeLineChartItem, rowIndex?: number, itemIndex?: number) => ReactElement;
  spacerRender?: (width: number, rowIndex?: number, item?: DndTimeLineChartItem, itemIndex?: number) => ReactElement;
  rowEndRender?: (rowIndex: number) => ReactElement;
  onItemClick?: (item: DndTimeLineChartItem) => void;
  onItemDoubleClick?: (item: DndTimeLineChartItem) => void;
  /* eslint-enable */
}

export default function DndTimelineRow({
  row,
  rowIndex,
  additionAreaWidth,
  start,
  minutesToPixel,
  disableDragAndDrop = false,
  additionalAreaRender,
  itemRender,
  itemToolTipRender,
  spacerRender,
  rowEndRender,
  onItemClick,
  onItemDoubleClick,
}: DndTimelineRowProps) {
  let durationBefore = 0;
  let prevItemIndex = 0;
  let fakeWidth = 0;
  const hasSecondaryLine = true;
  const handleItemClick = (itemIndex: number) => {
    onItemClick && onItemClick(row.items[itemIndex]);
  };
  const handleItemDoubleClick = (itemIndex: number) => {
    onItemDoubleClick && onItemDoubleClick(row.items[itemIndex]);
  };
  return (
    <Droppable isDropDisabled={!row.dropable} droppableId={`r${rowIndex}`} direction="horizontal">
      {(provided, snapshot) => (
        <div
          className={`dndt-row${snapshot.isDraggingOver ? ' highlight' : ''}${
            row.className ? ' ' + row.className : ''
          }${hasSecondaryLine ? ' hasSecondaryLine' : ''}`}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <div className="dndt-addition-area" style={{ width: additionAreaWidth }}>
            {additionalAreaRender && additionalAreaRender(rowIndex)}
          </div>
          <div className="dndt-chart-area">
            {row.items.map((item, itemIndex) => {
              if (item.hidden) return <></>;
              if (itemIndex > 0) {
                durationBefore =
                  (item.start.diff(row.items[prevItemIndex].end, 'seconds') / 60) * (minutesToPixel ?? 1);
                if (fakeWidth > 0) {
                  const prevDurationBeforeValue = durationBefore;
                  if (durationBefore - fakeWidth > 0) {
                    durationBefore = durationBefore - fakeWidth;
                    fakeWidth = 0;
                  } else {
                    durationBefore = 0;
                    fakeWidth = fakeWidth - prevDurationBeforeValue;
                  }
                }
              } else {
                durationBefore =
                  (start && row && row.items && row.items[0] ? item.start.diff(start, 'seconds') / 60 : 0) *
                  (minutesToPixel ?? 1);
                fakeWidth = 0;
              }

              const draggableId = `r${rowIndex}-i${itemIndex}`;
              let itemWidth = (item.end.diff(item.start, 'seconds') / 60) * (minutesToPixel ?? 1);
              if (itemWidth < MINIMUM_ITEM_WIDTH) {
                fakeWidth += MINIMUM_ITEM_WIDTH - itemWidth;
                itemWidth = MINIMUM_ITEM_WIDTH;
              }
              if (item.hidden) itemWidth = 0;
              const pIndex = item?.colorIndex ?? rowIndex ?? 0;
              const pColor = ColorPalette[pIndex % ColorPalette.length];

              prevItemIndex = itemIndex;
              return (
                <>
                  {spacerRender ? (
                    spacerRender(durationBefore, rowIndex, item, itemIndex)
                  ) : (
                    <div
                      className={`dndt-row-spacer${itemIndex === 0 ? ' first' : ''}`}
                      style={
                        itemIndex === 0
                          ? { width: durationBefore, borderColor: 'transparent' }
                          : {
                              width: durationBefore,
                              borderColor: pColor.dark40,
                              backgroundColor: pColor.main,
                            }
                      }
                    ></div>
                  )}
                  {item.hidden ? (
                    <></>
                  ) : (
                    <Draggable
                      key={draggableId}
                      draggableId={draggableId}
                      index={itemIndex}
                      isDragDisabled={disableDragAndDrop || !item.dragable}
                      disableInteractiveElementBlocking={true}
                    >
                      {(provided) => (
                        <DndTimelineItem
                          item={item}
                          rowIndex={rowIndex}
                          itemIndex={itemIndex}
                          itemWidth={itemWidth}
                          itemRender={itemRender}
                          itemToolTipRender={itemToolTipRender}
                          provided={provided}
                          key={`item-${rowIndex}-${itemIndex}`}
                          onItemClick={handleItemClick}
                          onItemDoubleClick={handleItemDoubleClick}
                        />
                      )}
                    </Draggable>
                  )}
                </>
              );
            })}
            <div className="dndt-row-end-area">{rowEndRender && rowEndRender(rowIndex)}</div>
          </div>
        </div>
      )}
    </Droppable>
  );
}
