import {
  Accordion,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from '@mui/material';

import { CustomerLocationOrder, SortProperties } from '../../store/config/types';
import { calculateDeliveryExpectedTime } from '../../utils/string.helpers';
import './style.scss';

import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import Card from '../Card/Card';

import { ArrowDropUp, ArrowDropDown, Store, LocationOn, CalendarToday } from '@material-ui/icons';
import { useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deliveryOrdersService } from '../../services/deliveryOrders.service';

import DeliveryOrderPODView from './DeliveryOrderPODView';
import SingleDeliveryAccordion from './SingleDeliveryOrderAccordion';

interface DeliveryOrdersAccordionProps {
  deliveryOrders: CustomerLocationOrder[];
  title: string;
  timezone?: string;
  sortProps?: SortProperties;
  /* eslint-disable */
  renderAdditionalActions?: () => JSX.Element;
  onSortClick?: (field: string) => void;
  onDeliveriesDelete?: (packageGroupIds?: number[]) => void;
  onError?: (message?: string) => void;
  /* eslint-enable */
}
const DeliveryOrdersAccordion = ({
  deliveryOrders,
  title,
  timezone,
  sortProps,
  renderAdditionalActions,
  onSortClick,
  onDeliveriesDelete,
  onError,
}: DeliveryOrdersAccordionProps) => {
  const [deliveryToAction, setDeliveryToAction] = useState<CustomerLocationOrder | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openPODView, setOpenPODView] = useState(false);

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
    setDeliveryToAction(null);
  };
  const handlePODDialogClose = () => {
    setOpenPODView(false);
    setDeliveryToAction(null);
  };
  const handleDeleteDelivery = async () => {
    if (deliveryToAction) {
      const ids = deliveryToAction.packageGroups.map((packageGroup) => packageGroup.packageGroupId ?? 0);
      try {
        const result: number[] = await deliveryOrdersService.deletePackageGroups(ids);
        if (result.length > 0) {
          handleDeleteDialogClose();
          onDeliveriesDelete && onDeliveriesDelete(result);
        }
      } catch (error: any) {
        if (onError && error.response && error.response.data.message) {
          onError(error.response.data.message);
        }
      }
    }
  };

  const handleSortLinkClick = (field: string) => {
    if (onSortClick) onSortClick(field);
  };
  const SortableField = (props: { title: string; field: string; sortProps?: SortProperties }) => {
    const order = props.sortProps ? props.sortProps.order : 'ASC';
    const sortArrow = <span>{order === 'DESC' ? <ArrowDropDown /> : <ArrowDropUp />}</span>;
    return sortProps ? (
      <a href="javascript:;" onClick={() => handleSortLinkClick(props.field)}>
        {props.title}
        {props.sortProps?.field === props.field && sortArrow}
      </a>
    ) : (
      <div>{props.title}</div>
    );
  };

  return (
    <Card>
      {(title || renderAdditionalActions) && (
        <CardHeader color="primary" className="delivery-order-header">
          <Grid container>
            <Grid item flexGrow={1}>
              <span>{title}</span>
            </Grid>
            {renderAdditionalActions && <Grid item>{renderAdditionalActions()}</Grid>}
          </Grid>
        </CardHeader>
      )}
      <CardBody>
        <Accordion className="delivery-order-accordion" expanded={false}>
          <AccordionSummary className="doa-head title" expandIcon={<span style={{ width: 24 }}></span>}>
            <Typography className="doa-label" style={{ marginLeft: 24 }}>
              ID
            </Typography>
            <Typography className="doa-company sortable-field">
              <SortableField field="companyName" title="Company" sortProps={sortProps} />
            </Typography>
            <Typography className="doa-name sortable-field">
              <SortableField field="name" title="Name" sortProps={sortProps} />
            </Typography>
            <Typography className="doa-addressee sortable-field">
              <SortableField field="shipToAddressee" title="Addressee" sortProps={sortProps} />
            </Typography>
            <Typography className="doa-address sortable-field">
              <SortableField field="address" title="Shipping Address" sortProps={sortProps} />
            </Typography>
            <Typography className="doa-packages-count">#</Typography>
            <Typography className="doa-time sortable-field">
              <SortableField field="date" title="Time" sortProps={sortProps} />
            </Typography>
            <Typography className="doa-shipVia sortable-field">
              <SortableField field="shipVia" title="Ship Via" sortProps={sortProps} />
            </Typography>
            <Typography className="doa-status sortable-field">
              <SortableField field="status" title="Status" sortProps={sortProps} />
            </Typography>
          </AccordionSummary>
        </Accordion>
        {deliveryOrders.map((deliveryOrder, index) => (
          <SingleDeliveryAccordion
            key={`accordion-${index}`}
            deliveryOrder={deliveryOrder}
            index={index}
            setDeliveryToAction={setDeliveryToAction}
            setOpenDeleteDialog={setOpenDeleteDialog}
            setOpenPODView={setOpenPODView}
            timezone={timezone}
          />
        ))}
      </CardBody>
      <Dialog open={openDeleteDialog} onClose={() => handleDeleteDialogClose()}>
        <DialogTitle>Delete Delivery</DialogTitle>
        {deliveryToAction && (
          <DialogContent>
            <DialogContentText>
              <Grid container>
                <Grid item paddingRight={2}>
                  <Store />
                </Grid>
                <Grid item>
                  {deliveryToAction?.customer.companyName} {deliveryToAction?.customer.firstName}{' '}
                  {deliveryToAction?.customer.lastName}
                </Grid>
              </Grid>
            </DialogContentText>
            <DialogContentText>
              <Grid container>
                <Grid item paddingRight={2}>
                  <LocationOn />
                </Grid>
                <Grid item></Grid>
                {deliveryToAction?.shippingLocation?.streetAddress}
              </Grid>
            </DialogContentText>

            <DialogContentText>
              <Grid container>
                <Grid item paddingRight={2}>
                  <CalendarToday />
                </Grid>
                <Grid item>{calculateDeliveryExpectedTime(deliveryToAction, timezone) ?? ''}</Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button color="success" variant="contained" onClick={() => handleDeleteDialogClose()}>
            Cancel
          </Button>
          <Button color="error" variant="outlined" onClick={() => handleDeleteDelivery()}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <DeliveryOrderPODView handleClose={handlePODDialogClose} deliveryOrder={deliveryToAction} open={openPODView} />
    </Card>
  );
};

export default DeliveryOrdersAccordion;
