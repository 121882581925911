import { AIActionTypes } from '../config/ActionTypes';
import { AIState } from '../config/types/ai.types';

export const initialState: AIState = {
  sessions: [],
  fetching: false,
  error: undefined,
  debugMode: false,
};

export function aiReducer(state = initialState, action: AIActionTypes): AIState {
  switch (action.type) {
    case 'AI_FETCHING_SESSIONS':
      return {
        ...state,
        fetching: true,
      };

    case 'AI_FETCH_SESSION_SUCCESS':
      const sessions = state.sessions;
      action.sessions?.forEach((session) => {
        const sessionIndex = sessions.findIndex((s) => s.aiChatSessionId === session.aiChatSessionId);
        if (sessionIndex < 0) {
          sessions.push(session);
        } else {
          sessions.splice(sessionIndex, 1, session);
        }
        sessions.sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());
      });

      return {
        ...state,
        sessions,
        fetching: false,
        error: undefined,
      };

    case 'AI_FETCH_SESSION_FAILED':
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    case 'AI_DEBUG_MODE':
      return { ...state, debugMode: action.debugMode };

    default:
      return state;
  }
}
