import { useEffect, useState, Fragment, SyntheticEvent, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import moment from 'moment-timezone';
import { RootState } from '../../store/config/types';
import { OrderItem, Product } from '../../store/config/types/deliveryOrders.types';

import { ThunkDispatch } from 'redux-thunk';
import { setToast } from '../../store/actions/toast.actions';
import { SearchBox } from '../../components/SearchBox/SearchBox';

import { Badge, Button, Chip, CircularProgress, Grid, IconButton, Link, Tab, Tabs, Typography } from '@mui/material';

import { FulfillmentService } from '../../services/warehouseWork.service';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import { DoneAll, HighlightOff, Info, Input, Refresh, Sync } from '@material-ui/icons';
import {
  PackageGroupFulfillmentReadableStatus,
  PackageGroupFulfillmentStatus,
} from '../../store/config/enums/warehouseWork.enum';
import {
  FulfillmentSalesOrderStruct,
  ProductWithItemPendingFulfillmentStruct,
} from '../../store/config/types/warehouseWork.types';
import CreateAssemblyWorkorderDialog from './CreateAssemblyWorkorderDialog';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import { formatDate } from '../../utils/string.helpers';
import SalesOrderDetails from './SalesOrderDetails';
import SalesOrderAction from './SalesOrderAction';
import ColumnFilter from '../../components/FilterableTable/ColumnFilter';
import ItemPendingFulfillmentTable from './ItemPendingFulfillmentTable';
import { getReadableItemStatus } from '../../utils/fulfillment.helpers';
import LotsDialog from './LotsDialog';
import CumulativeOperationDialog from '../../components/CumulativeOperationDialog/CumulativeOperationDialog';
import { ExecutableOperation } from '../../utils/CumulativeOperationRunner';

const PAGE_ITEMS: number = 12;
const REFRESH_MILISECONDS = 60000;

/* eslint-disable */
enum PageModes {
  ALL_DELIVERY_ORDERS = 'all-delivery-orders',
  ITEMS_PENDING_FULFILLMENT = 'items-pending-fulfillment',
  FULFILLMENT_STATUS_TBD = 'fulfillment-status-tbd',
}
/* es lint-enable */

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    loggedIn: state.auth.loggedIn,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Fulfillment({ auth, loggedIn, setToast }: PropsFromRedux) {
  auth && auth.account && auth.account.shipper?.timezone && moment.tz.setDefault(auth.account.shipper.timezone);

  const [orderDate, setOrderDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(false);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [pageMode, setPageMode] = useState<PageModes>(PageModes.ALL_DELIVERY_ORDERS);

  const [itemToAssemblyWorkorderCreation, setItemToAssemblyWorkorderCreation] =
    useState<ProductWithItemPendingFulfillmentStruct>();
  const [orderItemsToEditLots, setOrderItemsToEditLots] = useState<OrderItem[]>();
  const [productItem, setProductItem] = useState<Product>();
  const [tabValue, setTabValue] = useState(0);
  const [shipVias, setShipVias] = useState<string[]>([]);
  const [itemStatuses, setItemStatuses] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>();
  const [salesOrderFilter, setSalesOrderFilter] = useState<string>();
  const [shipVia, setShipVia] = useState<string[]>([]);
  const [itemStatus, setItemStatus] = useState<string[]>([]);
  const [shippingDate, setShippingDate] = useState<string>();
  const [salesOrderNeedsAttetionCount, setSalesOrderNeedsAttentionCount] = useState(0);
  const [itemsNeedsAttetionCount, setItemsNeedsAttentionCount] = useState(0);
  const [cumulativeOperationDialogOpen, setCumulativeOperationDialogOpen] = useState(false);
  const [cumulativeOperationDialogMessage, setCumulativeOperationDialogMessage] = useState<string>('');
  const [cumulativeOperationDialogTitle, setCumulativeOperationDialogTitle] = useState<string>();
  const actionsRef = useRef<ExecutableOperation[]>([]);
  const orderDateRef = useRef<string>(orderDate);

  // const [statuses, setStatuses] = useState<any>({
  //   [PackageGroupFulfillmentStatus.PENDING]: 0,
  //   [PackageGroupFulfillmentStatus.PROCESSING]: 0,
  //   [PackageGroupFulfillmentStatus.FULFILLED]: 0,
  // });

  const pageModes = [
    PageModes.ALL_DELIVERY_ORDERS,
    PageModes.FULFILLMENT_STATUS_TBD,
    PageModes.ITEMS_PENDING_FULFILLMENT,
  ];

  const [salesOrderPendingFulfillment, setSalesOrderPendingFulfillment] = useState<FulfillmentSalesOrderStruct[]>([]);
  const [salesOrderStatusTBD, setSalesOrderStatusTBD] = useState<FulfillmentSalesOrderStruct[]>([]);
  const [itemsPendingFulfillment, setItemsPendingFulfillment] = useState<OrderItem[]>([]);
  const [newPackageGroupsIds, setNewPackageGroupsIds] = useState<number[]>([]);
  const [lastRefetch, setLastRefetch] = useState<Date>(new Date());
  const timerRef = useRef<NodeJS.Timeout>();

  const handleTabChange = (event: SyntheticEvent, value: number) => {
    setTabValue(value);
    setPageMode(pageModes[value]);
  };

  // #region Handle Operations
  const handleFulfillmentOperation = (op: string, params: any) => {
    if (op == 'lot-batch-change') {
      setProductItem(params.product);
      // setLotIdToChange(params.inventoryLotQuantityId);
      // setLotCaptionToChange(params.lotId);
    } else if (op == 'edit-order-items-lot') {
      setProductItem(params.product);
      setOrderItemsToEditLots(params.orderItems);
    } else if (op == 'create-assembly-workorder') {
      setItemToAssemblyWorkorderCreation(params);
    } else if (op == 'skip') {
      setLoading(true);
      FulfillmentService.skipFulfillment(params.packageGroupId)
        .then((result) => {
          if (result && result.successfulIds?.length) {
            refetchData();
            setToast('Successfully Skipped', 'success');
          }
        })
        .catch((err) => {
          setToast('Skip failed', 'error');
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
          refetchData();
        });
    } else if (op == 'skip-selected') {
      const skippableItems = (params as FulfillmentSalesOrderStruct[]).filter(
        (pg) =>
          ![
            PackageGroupFulfillmentStatus.SKIPPED,
            PackageGroupFulfillmentStatus.EXTERNALLY_FULFILLED,
            PackageGroupFulfillmentStatus.FULFILLED,
            PackageGroupFulfillmentStatus.SKIPPED,
            PackageGroupFulfillmentStatus.SYNCED,
            PackageGroupFulfillmentStatus.SYNC_FAILED,
          ].includes(pg.status),
      );

      setCumulativeOperationDialogMessage(
        `To skip ${skippableItems.length} selected orders click on Start, You can back them from TBD later.`,
      );
      setCumulativeOperationDialogTitle('Skip');
      actionsRef.current = skippableItems.map(
        (item) =>
          ({
            operation: () => FulfillmentService.skipFulfillment(item.packageGroupId),
            index: item.label,
          } as ExecutableOperation),
      );
      setCumulativeOperationDialogOpen(true);
    } else if (op == 'convert-to-pending') {
      setLoading(true);
      FulfillmentService.convertFulfillment([params.packageGroupId], 'PENDING')
        .then((result) => {
          if (result) {
            refetchData();
            setToast('Successfully added to queue', 'success');
          }
        })
        .catch((err) => {
          setToast('Adding to queue failed', 'error');
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (op == 'partial-commit') {
      setLoading(true);
      FulfillmentService.convertFulfillment([params.packageGroupId], 'COMMITTED')
        .then((result) => {
          if (result) {
            refetchData();
            setToast('Successfully Committed', 'success');
          }
        })
        .catch((err) => {
          setToast('Committment Failed', 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (op == 'convert-all-to-pending') {
      setCumulativeOperationDialogMessage(
        'Are you sure you want to add all new orders to the fulfillment queue?\n' +
          'They will be processed automatically after a short delay. This action cannot be undone.',
      );
      setCumulativeOperationDialogTitle('Convert');
      actionsRef.current =
        salesOrderPendingFulfillment
          .filter((so) =>
            [PackageGroupFulfillmentStatus.NEW, PackageGroupFulfillmentStatus.COMMITTED].includes(so.status),
          )
          ?.map((so) => ({
            operation: () => FulfillmentService.convertFulfillment([so.packageGroupId], 'PENDING'),
            index: so.label,
          })) ?? [];
      setCumulativeOperationDialogOpen(true);
    } else if (op == 'sync-with-esm') {
      setLoading(true);
      FulfillmentService.syncFulfillment(params.packageGroupId)
        .then((result) => {
          if (result.result) {
            refetchData();
            setToast('Successfully synced', 'success');
          } else {
            const errorString: string = result.data.errors.join('\n');
            setToast(errorString, 'danger');
          }
        })
        .catch((err) => {
          setToast('Sync failed', 'danger');
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (op == 'convert-selected-to-fulfilled') {
      const convertableItems = (params as FulfillmentSalesOrderStruct[]).filter(
        (pg) => pg.status === PackageGroupFulfillmentStatus.PENDING,
      );
      setCumulativeOperationDialogTitle('Convert');
      handleConvertSelected(convertableItems, PackageGroupFulfillmentStatus.FULFILLED);
    } else if (op == 'convert-selected-to-pending') {
      const convertableItems = (params as FulfillmentSalesOrderStruct[]).filter((pg) =>
        [PackageGroupFulfillmentStatus.NEW, PackageGroupFulfillmentStatus.COMMITTED].includes(pg.status),
      );
      setCumulativeOperationDialogTitle('Convert');
      handleConvertSelected(convertableItems, PackageGroupFulfillmentStatus.PENDING);
    } else if (op == 'retry-convert-selected-to-pending') {
      const convertableItems = (params as FulfillmentSalesOrderStruct[]).filter((pg) =>
        [PackageGroupFulfillmentStatus.NEEDS_ATTENTION, PackageGroupFulfillmentStatus.SKIPPED].includes(pg.status),
      );
      setCumulativeOperationDialogTitle('Convert');
      handleConvertSelected(convertableItems, PackageGroupFulfillmentStatus.PENDING);
    } else if (op == 'update-fulfillment-items') {
      refetchData();
    } else {
      console.error(`unknown Operation "${op}"`, params);
    }
  };

  function handleConvertAllNew() {
    setLoading(true);
    FulfillmentService.convertFulfillment(newPackageGroupsIds ?? [], 'PENDING')
      .then((result) => {
        setToast('Successfully added to queue', 'success');
        refetchData();
      })
      .catch((err) => {
        setToast('Error', 'error');
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        setCumulativeOperationDialogOpen(false);
      });
  }
  function handleConvertSelected(
    convertableItems: FulfillmentSalesOrderStruct[],
    convertTo: PackageGroupFulfillmentStatus,
  ) {
    setCumulativeOperationDialogMessage(
      `Are you sure to convert ${convertableItems.length} order${convertableItems.length > 1 ? 's' : ''} to ${
        PackageGroupFulfillmentReadableStatus[convertTo]
      }`,
    );

    actionsRef.current = convertableItems.map(
      (item) =>
        ({
          operation: () => FulfillmentService.convertFulfillment([item.packageGroupId], convertTo),
          index: item.label,
        } as ExecutableOperation),
    );
    setCumulativeOperationDialogOpen(true);
  }

  function SalesOrderLink(row: any) {
    return (
      <>
        <SalesOrderAction salesOrder={row} onAction={handleFulfillmentOperation} />
        <Link href={row.externalLink} target="_blank">
          {row.label}
        </Link>
      </>
    );
  }

  // function handleChangeDefaultLotClose(): void {
  //   setLotIdToChange(undefined);
  //   setLotCaptionToChange(null);
  //   // fetchItemsPendinfFulfillment();
  // }

  function handleExternalFilterChanged(filters: any) {
    if (filters.customerDisplayName !== searchText) {
      console.log('filter', filters.customerDisplayName, searchText);
      setSearchText(filters.customerDisplayName);
    }
  }

  function handleOrderItemsLotsClose(): void {
    setOrderItemsToEditLots(undefined);
    // fetchItemsPendinfFulfillment();
  }

  function handleCreateAssemblyWorkorderClose(): void {
    setItemToAssemblyWorkorderCreation(undefined);
  }

  const fetchDeliveryOrders = async () => {
    if (auth && auth.account && auth.account.shipper?.timezone) moment.tz.setDefault(auth.account.shipper.timezone);
    if (auth.account?.shipper?.shipperId) return FulfillmentService.fetchFulfillmentOrders(orderDateRef.current);
  };

  const refetchData = () => {
    setNeedsRefresh(false);
    if (!loading) {
      if (timerRef.current) {
        try {
          clearTimeout(timerRef.current);
          timerRef.current = setTimeout(() => refetchData(), REFRESH_MILISECONDS);
        } catch (err) {
          console.error(err);
        }
      }
      setLastRefetch(new Date());
      setLoading(true);
      fetchDeliveryOrders()
        .then((salesOrders) => {
          setSalesOrderPendingFulfillment(salesOrders?.salesOrderPendingFulfillment ?? []);
          setSalesOrderStatusTBD(salesOrders?.salesOrderStatusTBD ?? []);
          setNewPackageGroupsIds(
            salesOrders?.salesOrderPendingFulfillment
              .filter((so) =>
                [PackageGroupFulfillmentStatus.NEW, PackageGroupFulfillmentStatus.COMMITTED].includes(so.status),
              )
              ?.map((so) => so.packageGroupId) ?? [],
          );
          const needsAttentions =
            salesOrders?.salesOrderStatusTBD?.filter((so) => so.status == PackageGroupFulfillmentStatus.NEEDS_ATTENTION)
              .length ?? 0;
          setSalesOrderNeedsAttentionCount(needsAttentions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    setShipVia([]);
    setShippingDate(undefined);
    setItemStatus([]);
  }, [salesOrderPendingFulfillment, salesOrderStatusTBD]);

  // #region extract IPFF
  let needsAttentions = 0;
  useEffect(() => {
    const items: OrderItem[] = [];
    const allShipVias: string[] = [];
    const allItemStatuses: string[] = [];
    [...salesOrderPendingFulfillment, ...salesOrderStatusTBD]
      .filter((so) => {
        if (!allShipVias.includes(so.shipVia)) allShipVias.push(so.shipVia);
        if (shippingDate && !moment(shippingDate).isSame(so.shippingDate, 'day')) return false;
        if (shipVia.length && !shipVia.includes(so.shipVia)) return false;
        if (searchText && !so.customerDisplayName.toLowerCase().includes(searchText.toLowerCase())) return false;
        if (salesOrderFilter && !so.label.toLowerCase().includes(salesOrderFilter.toLowerCase())) return false;
        return true;
      })
      .forEach((so) => {
        so.items.forEach((item) => {
          item.additionalData = {
            ...item.additionalData,
            label: so.label,
            externalLink: so.externalLink,
            customerDisplayName: so.customerDisplayName,
            warehouse: so.warehouse,
          };
          if (item.fulfillmentStatus == 'INSUFFICIENT_INVENTORY') {
            needsAttentions++;
          }
          if (
            ['INSUFFICIENT_INVENTORY', 'READY_TO_FULFILL', 'PENDING', 'AUTOLOT_FAILED'].includes(item.fulfillmentStatus)
          ) {
            if (!allItemStatuses.includes(item.fulfillmentStatus)) allItemStatuses.push(item.fulfillmentStatus);
            if (itemStatus.length === 0 || itemStatus.includes(item.fulfillmentStatus)) {
              items.push(item);
            }
          }
        });
      });
    setItemsNeedsAttentionCount(needsAttentions);
    setShipVias(allShipVias);
    setItemStatuses(allItemStatuses);
    setItemsPendingFulfillment(items);
  }, [
    salesOrderPendingFulfillment,
    salesOrderStatusTBD,
    shipVia,
    shippingDate,
    itemStatus,
    searchText,
    salesOrderFilter,
  ]);
  useEffect(() => {
    setNeedsRefresh(true);
  }, [loggedIn, auth.account]);

  useEffect(() => {
    if (needsRefresh && !loading) {
      refetchData();
    }
  }, [needsRefresh]);
  useEffect(() => {
    timerRef.current = setTimeout(refetchData, REFRESH_MILISECONDS);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  const handleFilterChange = (filters: any) => {
    // if (filters.shipVia != shipVia) {
    //   setShipVia(filters.shipVia);
    // }
    // if (filters.itemType != itemType) {
    //   setItemType(filters.itemType);
    // }
    // if (filters.itemStatus != itemStatus) {
    //   setItemStatus(filters.itemStatus);
    // }

    setOrderDate(filters.orderDate);
    orderDateRef.current = filters.orderDate;
    setNeedsRefresh(true);
  };

  const handleCumulativeOperation = (operation: string, rows: any[]) => {
    handleFulfillmentOperation(operation, rows);
  };

  function handleConfirmationClose(): void {
    setCumulativeOperationDialogOpen(false);
    setCumulativeOperationDialogMessage('');
    setCumulativeOperationDialogTitle('');
    actionsRef.current = [];
    refetchData();
  }

  return (
    <Fragment>
      <SearchBox
        query={searchText}
        onQueryChange={setSearchText}
        disabled={loading}
        // selectableFilters={getSelectableFiltersOfPage()}
        dateFilters={{ orderDate }}
        onFilterApplying={handleFilterChange}
        captions={{
          shippingDate: 'Shipping Date',
          orderDate: 'Order Date',
          shipVia: 'Ship Via',
          // itemType: 'Item Type',
          itemStatus: 'Item Status',
        }}
      />
      {/* <Grid container spacing={1} margin={1}>
        <Grid item>
          <InfoCard
            caption="Pending"
            number={statuses[PackageGroupFulfillmentStatus.PENDING]}
            icon={<AccessTime />}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <InfoCard
            caption="Processing"
            number={statuses[PackageGroupFulfillmentStatus.PROCESSING]}
            icon={<BlurCircular />}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <InfoCard
            caption="Partialy Fulfilled"
            number={statuses[PackageGroupFulfillmentStatus.PARTIAL_FULFILLED]}
            icon={<Brightness6 />}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <InfoCard
            caption="Fulfilled"
            number={statuses[PackageGroupFulfillmentStatus.FULFILLED]}
            icon={<Brightness5 />}
            loading={loading}
          />
        </Grid>
      </Grid> */}

      <Card>
        <CardHeader color="primary">
          <Grid container p={0} m={0} alignItems="center">
            <Grid item flexGrow={1}>
              <span>Fulfillment</span>
            </Grid>
            <Grid item alignItems="center">
              <Typography variant="caption">Last Update {moment(lastRefetch).format('HH:mm')}</Typography>
              <IconButton
                size="small"
                onClick={() => {
                  if (!loading) setNeedsRefresh(true);
                }}
              >
                {loading ? (
                  <CircularProgress size={24} style={{ color: 'white' }} />
                ) : (
                  <Refresh style={{ color: 'white' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </CardHeader>
        <CardBody>
          {Boolean(newPackageGroupsIds.length) && (
            <Grid container style={{ backgroundColor: 'rgb(245,245,190)' }} alignItems="center" pl={2} pr={2} mb={2}>
              <Info color="primary" />
              <Typography variant="body1" ml={2}>
                You can{' '}
                <Button
                  disabled={loading}
                  onClick={() =>
                    handleFulfillmentOperation('convert-all-to-pending', { packageGroupIds: newPackageGroupsIds })
                  }
                >
                  add all new orders to the fulfillment queue
                </Button>
                . They will be processed automatically after a short delay.
              </Typography>
            </Grid>
          )}
          <Grid item mb={2}>
            {/* 
            //#region TABS 
            */}
            <Tabs value={tabValue} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tab label="Sales Orders Pending Fulfillment" />
              <Tab
                label={
                  <Badge overlap="rectangular" badgeContent={salesOrderNeedsAttetionCount} color="error">
                    <Grid>Fulfillment Status TBD</Grid>
                  </Badge>
                }
              />
              <Tab
                label={
                  <Badge overlap="rectangular" badgeContent={itemsNeedsAttetionCount} color="error">
                    <Grid>Items Pending Fulfillment</Grid>
                  </Badge>
                }
              />
            </Tabs>
          </Grid>

          {
            // #region AllDeliveryOrder
            pageMode === PageModes.ALL_DELIVERY_ORDERS && (
              <FilterableTable
                selectable
                cumulativeOperations={[
                  {
                    operation: 'convert-selected-to-pending',
                    caption: 'Add Selected To Fulfillment Queue ',
                    icon: <Input color="primary" />,
                    isDisabled: (rows: FulfillmentSalesOrderStruct[]) =>
                      !rows.some((row) =>
                        [PackageGroupFulfillmentStatus.COMMITTED, PackageGroupFulfillmentStatus.NEW].includes(
                          row.status,
                        ),
                      ),
                  },
                  {
                    operation: 'convert-selected-to-fulfilled',
                    caption: 'Convert to Fulfilled ',
                    icon: <DoneAll style={{ color: 'blue' }} />,
                    isDisabled: (rows: FulfillmentSalesOrderStruct[]) =>
                      !rows.some((row) =>
                        [PackageGroupFulfillmentStatus.PENDING, PackageGroupFulfillmentStatus.PROCESSING].includes(
                          row.status,
                        ),
                      ),
                  },
                  {
                    operation: 'skip-selected',
                    caption: 'Skip Fulfillment',
                    icon: <HighlightOff color="error" />,
                  },
                ]}
                onCumulativeOperation={handleCumulativeOperation}
                itemPerPage={PAGE_ITEMS}
                columns={[
                  { field: 'label', caption: 'ID', filterType: 'string', render: SalesOrderLink, sortable: true },
                  {
                    field: 'customerDisplayName',
                    caption: 'Customer',
                    filterType: 'string',
                    sortable: true,
                  },
                  {
                    field: 'shippingDate',
                    caption: 'Ship Date',
                    filterType: 'date',
                    sortable: true,
                    render: (row) => formatDate(row.shippingDate),
                  },
                  { field: 'shipVia', caption: 'Ship Via', filterType: 'values' },
                  { field: 'isPartial', caption: 'is Partial', filterType: 'boolean', render: renderPartial },
                  {
                    field: 'statusReadable',
                    caption: 'Status',
                    filterType: 'values',
                    render: readableStatus,
                    sortable: true,
                  },
                  { field: 'pickerDisplayName', caption: 'Picker', filterType: 'string', sortable: true },
                ]}
                defaultSortBy="label"
                primaryKey="packageGroupId"
                externalFilterValues={{ customerDisplayName: searchText }}
                onFilterChanged={handleExternalFilterChanged}
                data={salesOrderPendingFulfillment}
                extendable
                renderExtended={(row) => <SalesOrderDetails salesOrder={row} />}
              />
            )
          }
          {
            // #region TBD
            pageMode === PageModes.FULFILLMENT_STATUS_TBD && (
              <FilterableTable
                itemPerPage={PAGE_ITEMS}
                columns={[
                  { field: 'label', caption: 'ID', filterType: 'string', render: SalesOrderLink, sortable: true },
                  { field: 'customerDisplayName', caption: 'Customer', filterType: 'string', sortable: true },
                  {
                    field: 'shippingDate',
                    caption: 'Ship Date',
                    filterType: 'date',
                    sortable: true,
                    render: (row) => formatDate(row.shippingDate),
                  },
                  { field: 'shipVia', caption: 'Ship Via', filterType: 'values' },
                  // { field: 'isPartial', caption: 'is Partial', filterType: 'boolean', render: renderPartial },
                  {
                    field: 'statusReadable',
                    caption: 'Status',
                    filterType: 'values',
                    render: readableStatus,
                    sortable: true,
                  },
                  // { field: 'picker', caption: 'Picker', filterType: 'string', sortable: true },
                ]}
                defaultSortBy="label"
                data={salesOrderStatusTBD}
                externalFilterValues={{ customerDisplayName: searchText }}
                onFilterChanged={handleExternalFilterChanged}
                extendable
                renderExtended={(row) => <SalesOrderDetails salesOrder={row} />}
                selectable
                primaryKey="packageGroupId"
                cumulativeOperations={[
                  {
                    operation: 'retry-convert-selected-to-pending',
                    caption: 'Send To Pending',
                    icon: <Sync color="primary" />,
                  },
                  {
                    operation: 'skip-selected',
                    caption: 'Skip Fulfillment',
                    icon: <HighlightOff color="error" />,
                  },
                ]}
                onCumulativeOperation={handleCumulativeOperation}
              />
            )
          }
          {
            // #region ItemsPendingFulfillment
            pageMode === PageModes.ITEMS_PENDING_FULFILLMENT && (
              <>
                <Grid container p={2} alignItems={'baseline'}>
                  <Grid item pr={2}>
                    <Typography variant="button">Items Filter</Typography>
                  </Grid>

                  <Grid item pl={2}>
                    <Grid container flexDirection="column">
                      <Grid item>
                        Sales Order
                        <ColumnFilter
                          type={'string'}
                          value={salesOrderFilter}
                          onFilterChanged={(newValue) => setSalesOrderFilter(newValue)}
                        />
                      </Grid>
                      <Grid item>
                        {salesOrderFilter && (
                          <Chip
                            color="info"
                            variant="outlined"
                            label={salesOrderFilter}
                            onDelete={() => setSalesOrderFilter(undefined)}
                            style={{ margin: 0 }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item pl={2}>
                    <Grid container flexDirection="column">
                      <Grid item>
                        Customer
                        <ColumnFilter
                          type={'string'}
                          value={searchText}
                          onFilterChanged={(newValue) => setSearchText(newValue)}
                        />
                      </Grid>
                      <Grid item>
                        {searchText && (
                          <Chip
                            color="info"
                            variant="outlined"
                            label={searchText}
                            onDelete={() => setSearchText(undefined)}
                            style={{ margin: 0 }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item pl={2}>
                    <Grid container flexDirection="column">
                      <Grid item>
                        Shipping Date
                        <ColumnFilter
                          type={'date'}
                          value={shippingDate}
                          onFilterChanged={(newDate) => setShippingDate(newDate)}
                        />
                      </Grid>
                      <Grid item>
                        {shippingDate && (
                          <Chip
                            color="info"
                            variant="outlined"
                            label={formatDate(shippingDate)}
                            onDelete={() => setShippingDate(undefined)}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item pl={2}>
                    <Grid container flexDirection="column">
                      <Grid item>
                        Ship Via
                        <ColumnFilter
                          type={'values'}
                          values={shipVias}
                          value={shipVia}
                          onFilterChanged={(newShipVia) => setShipVia(newShipVia)}
                        />
                      </Grid>
                      <Grid item>
                        {shipVia.length > 0 && (
                          <Chip
                            color="info"
                            variant="outlined"
                            label={shipVia.length === 1 ? shipVia[0] : `${shipVia.length} Selected`}
                            onDelete={() => setShipVia([])}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item pl={2}>
                    <Grid container flexDirection="column">
                      <Grid item>
                        Status
                        <ColumnFilter
                          type={'values'}
                          values={itemStatuses}
                          value={itemStatus}
                          onFilterChanged={(newStatuses) => setItemStatus(newStatuses)}
                          getValueCaption={getReadableItemStatus}
                        />
                      </Grid>
                      <Grid item>
                        {itemStatus.length > 0 && (
                          <Chip
                            color="info"
                            variant="outlined"
                            label={
                              itemStatus.length === 1
                                ? getReadableItemStatus(itemStatus[0])
                                : `${itemStatus.length} Selected`
                            }
                            onDelete={() => setItemStatus([])}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <ItemPendingFulfillmentTable items={itemsPendingFulfillment} onAction={handleFulfillmentOperation} />
              </>
            )
          }
        </CardBody>
        {/* <ChangeLotDialog
          lotIdToChange={lotIdToChange}
          lotCaptionToChange={lotCaptionToChange}
          onClose={handleChangeDefaultLotClose}
          itemPendingFulfillment={productItem}
        /> */}
        <LotsDialog
          onClose={handleOrderItemsLotsClose}
          product={productItem}
          orderItems={orderItemsToEditLots}
          onSuccess={(updates) => handleFulfillmentOperation('update-fulfillment-items', updates)}
        />
        <CumulativeOperationDialog
          open={cumulativeOperationDialogOpen}
          operations={actionsRef.current}
          message={cumulativeOperationDialogMessage}
          title={cumulativeOperationDialogTitle}
          onCancel={() => handleConfirmationClose()}
          onComplete={() => handleConfirmationClose()}
        />
        <CreateAssemblyWorkorderDialog
          defaultCount={1}
          productWithItemPendingFulfillment={itemToAssemblyWorkorderCreation}
          onClose={handleCreateAssemblyWorkorderClose}
          onNotify={setToast}
        />
      </Card>
    </Fragment>
  );
}

function readableStatus(row: any) {
  const caption = PackageGroupFulfillmentReadableStatus[row.status as PackageGroupFulfillmentStatus];
  let color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' = 'primary';
  switch (row.status) {
    case PackageGroupFulfillmentStatus.FULFILLED:
      color = 'default';
      break;
    case PackageGroupFulfillmentStatus.NEEDS_ATTENTION:
      color = 'error';
      break;
    case PackageGroupFulfillmentStatus.PENDING:
      color = 'info';
      break;
    case PackageGroupFulfillmentStatus.NEW:
    case PackageGroupFulfillmentStatus.COMMITTED:
      color = 'primary';
      break;
    case PackageGroupFulfillmentStatus.PROCESSING:
      color = 'success';
      break;

    default:
      color = 'default';
      break;
  }
  return <Chip label={caption} size="small" color={color} />;
}
function renderPartial(row: any) {
  return row.isPartial ? <Chip size="small" label="Partial" color="secondary" /> : <></>;
}

export default connector(Fulfillment);
