import axios from './axios';
import {
  Trip,
  CreateTripOfferRequest,
  TripRequest,
  Stop,
  ArrangeTripRequest,
  TripWithStopsDetails,
  TripReCalculateWithGoogleDirectionRequest,
  LatLng,
  CurbhubTrip,
  TripArrangeStop,
  StopLocation,
  EditTripRequest,
  TripArrangeStopPackage,
  TripParking,
  DeliveryZoneWithLocations,
  DeliveryZoneWithLocationIds,
  TripOfferUpdateRequest,
  CourierTrackInfo,
  TripReCalculationRequest,
} from '../store/config/types/trips.types';
import moment, { Moment } from 'moment';
import { Customer, CustomerLocationOrder, LocationPoints } from '../store/config/types';
import { Warehouse } from '../store/config/types/warehouses.types';
import { InteractionTypeEnum } from '../store/config/enums/interaction.enum';
import { TripOptimizingMethod } from '../store/config/enums/trips.enum';

const locationsPaths: LocationPoints[] = [];
const tripParkings: TripParking[] = [];
const trackedPaths: CourierTrackInfo[] = [];
const LocationsPathService = {
  updateTrackedPath: (trackedPath: CourierTrackInfo) => {
    const trackIdx = trackedPaths.findIndex((tp) => tp.courierId === trackedPath.courierId);
    if (trackIdx < 0) {
      trackedPaths.push(trackedPath);
    } else {
      trackedPaths[trackIdx] = trackedPath;
    }
  },
  updatePoints: (startLocationId: number, endLocationId: number, type: string = 'router', points: LatLng[]) => {
    const idx = locationsPaths.findIndex(
      (locInfo) =>
        locInfo.startLocationId === startLocationId && locInfo.endLocationId === endLocationId && type === locInfo.type,
    );
    if (idx >= 0) {
      locationsPaths[idx] = { startLocationId, endLocationId, points, type };
    } else {
      locationsPaths.push({ startLocationId, endLocationId, points, type });
    }
  },
  updateTripPoints: (trip: TripWithStopsDetails) => {
    for (let idx = 1; idx < trip.tripStops.length; idx++) {
      if (!trip.tripStops[idx].routePoints?.length) continue;
      const prevLocation = trip.tripStops[idx - 1].location;
      const thisLocation = trip.tripStops[idx].location;
      const points = trip.tripStops[idx].routePoints?.length
        ? trip.tripStops[idx].routePoints
        : [
            { lat: prevLocation.latitude, lng: prevLocation.longitude },
            { lat: thisLocation.latitude, lng: thisLocation.longitude },
          ];
      LocationsPathService.updatePoints(prevLocation.locationId, thisLocation.locationId, 'router', points ?? []);
    }
  },
  getPoints: (locationsIds: number[], type: string = 'router') => {
    const points: LatLng[] = [];
    if (locationsIds.length > 1) {
      for (let locIdx = 1; locIdx < locationsIds.length; locIdx++) {
        const pathIdx = locationsPaths.findIndex(
          (locInfo) =>
            locInfo.startLocationId === locationsIds[locIdx - 1] &&
            locInfo.endLocationId === locationsIds[locIdx] &&
            locInfo.type === type,
        );
        if (pathIdx >= 0) {
          points.push(...locationsPaths[pathIdx].points);
        }
      }
    }
    return points;
  },
  getPointsOfLocations: (locations: StopLocation[], type: string = 'router') => {
    const points: LatLng[] = [];
    if (locations.length > 1) {
      for (let locIdx = 1; locIdx < locations.length; locIdx++) {
        const pathIdx = locationsPaths.findIndex(
          (locInfo) =>
            locInfo.startLocationId === locations[locIdx - 1].locationId &&
            locInfo.endLocationId === locations[locIdx].locationId &&
            locInfo.type === type,
        );
        if (pathIdx >= 0) {
          points.push(...locationsPaths[pathIdx].points);
        } else {
          locationsPaths.push({
            startLocationId: locations[locIdx - 1].locationId,
            endLocationId: locations[locIdx].locationId,
            type,
            points: [
              { lat: locations[locIdx - 1].latitude, lng: locations[locIdx - 1].longitude },
              { lat: locations[locIdx].latitude, lng: locations[locIdx].longitude },
            ],
          });
        }
      }
    }
    return points;
  },
  getTripParkings: (tripId: number) => {
    return tripParkings.filter((parking) => parking.tripId === tripId);
  },
  getCourierTrack: (courierId: number) => {
    return trackedPaths.find((track) => track.courierId == courierId);
  },
  loadTripsLocationsDistances: async (tripIds: number[]) => {
    try {
      const res = await axios.post('/trips/get-location-distances', { tripIds });
      const locationDistances = res.data.data;

      if (locationDistances && locationDistances.length) {
        locationDistances.forEach((locInfo: any) =>
          LocationsPathService.updatePoints(
            locInfo.startLocationId,
            locInfo.endLocationId,
            locInfo.additionalInfo.type,
            locInfo.routePoints.map((point: any) => ({ lat: point.latitude, lng: point.longitude })),
          ),
        );
      }
    } catch (err) {
      console.log(err);
    }
  },

  loadCouriersTrackedPath: async (courierIds: number[], date: Date) => {
    try {
      const res = await axios.post('/trips/tracked-paths', { courierIds, date });
      const paths: CourierTrackInfo[] = res.data.data;

      if (paths && paths.length) {
        paths.forEach((pathInfo: CourierTrackInfo) => LocationsPathService.updateTrackedPath(pathInfo));
      }
    } catch (err) {
      console.log(err);
    }
  },

  loadTripsParkings: async (tripIds: number[]) => {
    try {
      const res = await axios.post('/trips/get-parkings', { tripIds });
      const parkings: TripParking[] = res.data.data;
      tripParkings.splice(0, tripParkings.length, ...parkings);
    } catch (err) {
      console.log(err);
    }
  },
};

async function fetchTrips(
  from: Date | null = null,
  to: Date | null = null,
): Promise<{ trips: Trip[]; unAssignedOrders: CustomerLocationOrder[] } | undefined> {
  const queries: string[] = [];
  // fetch only one day trips
  const tripsDate = from ?? to ?? moment().toDate();
  // if (from) queries.push(`from=${moment(tripsDate).format('YYYY-MM-DD')}`);
  // if (to) queries.push(`to=${moment(tripsDate).format('YYYY-MM-DD')}`);
  queries.push(`date=${moment(tripsDate).format('YYYY-MM-DD')}`);
  const query = queries.length ? '?' + queries.join('&') : '';
  const res = await axios.get('/trips2' + query);
  return res.data.data;
}

async function fetchAliveTrips(
  tripsIds: number[],
): Promise<{ trips: Trip[]; unAssignedOrders: CustomerLocationOrder[] } | undefined> {
  const res = await axios.post('/trips/alive-trips', { tripsIds });
  return res.data.data;
}

async function fetchTripStops(tripId: number): Promise<Stop[] | undefined> {
  const res = await axios.get(`trips/${tripId}/stops/?mapTripStopWithLocation=true`);
  return res.data.data;
}

async function createTrip(tripRequest: TripRequest): Promise<Trip[] | undefined> {
  const res = await axios.post('/trips', tripRequest);
  return res.data.data;
}
export const makeStopLabel = (tripIdx: number, stopIdx: number) => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let label = '';
  do {
    label = letters[tripIdx % 25] + label;
    tripIdx = Math.floor(tripIdx / 25);
  } while (tripIdx > 25);
  return `${label}${stopIdx > 0 ? stopIdx : ''}`;
};

export const makeColorClass = (idx: number) => `color${idx % 6}`;

const TripArrangeStopSeqToTripWithStopsDetails = (stops: TripArrangeStop[], startDate: Date): TripWithStopsDetails => {
  const arriveDate = moment(startDate);
  return {
    tripId: -1,
    status: 'unAssigned',
    courierId: null,
    tripOffers: [],
    tripStops: stops.map((stop, stopIndex) => {
      return {
        ...stop,
        label: '.' + stopIndex,
        colorClass: 'color-unassigned',
        stopIndex,
        tripIndex: -1,
        additionalInfo: stop.additionalInfo ?? 'skipped by router',
      };
    }),
    firstLeg: true,
    totalMiles: 0,
    curbhubTrip: {
      trip_id: '-1',
      courier_id: 0,
      first_leg: true,
      skippedStops: true,
      stops: stops.map((stop) => {
        return {
          arrival_time: arriveDate.toDate(),
          departure_time: arriveDate.add(5, 'minutes').toDate(),
          distance_from_previous_stop: 0,
          loc_id: stop.location.locationId,
          transfer_type: 'give',
          connecting_trip_ids: [],
          packages: stop.stopPackages ? stop.stopPackages.map((pck) => `${pck.packageId}`) : [],
        };
      }),
    },
  } as TripWithStopsDetails;
};

async function arrangeTrip(arrangeTripRequest: ArrangeTripRequest): Promise<any> {
  const res = await axios.post('/trips/arrange-trips', arrangeTripRequest);
  if (res && res.data) {
    const data: {
      trips: TripWithStopsDetails[];
      skippedStops?: TripArrangeStop[];
      defaultTripOptimizingMethod?: TripOptimizingMethod;
      startColorPaletteIndex?: number;
    } = res.data.data;
    const trips: TripWithStopsDetails[] = data.trips.map((trip, tripIndex) => {
      return {
        ...trip,
        editable: true,
        tripStops: trip.tripStops.map((stop, stopIndex) => {
          return {
            ...stop,
            label: makeStopLabel(tripIndex, stopIndex),
            colorClass: makeColorClass(tripIndex),
            stopIndex,
            tripIndex,
          };
        }),
      };
    });
    if (data.skippedStops && data.skippedStops.length > 0) {
      const startDate: Date = trips.reduce((minDate, trip) => {
        return trip.startsAt && minDate.valueOf() > trip.startsAt?.valueOf() ? trip.startsAt : minDate;
      }, new Date());
      trips.push(TripArrangeStopSeqToTripWithStopsDetails(data.skippedStops, startDate));
    }
    return {
      trips,
      defaultTripOptimizingMethod: data.defaultTripOptimizingMethod,
      startColorPaletteIndex: data.startColorPaletteIndex,
    };
  }
  return res.data.data;
}

async function fetchTripsOfDateForEdit(editRequest: EditTripRequest): Promise<
  | {
      trips: TripWithStopsDetails[];
      customers?: Customer[];
      warehouses?: Warehouse[];
      defaultTripOptimizingMethod?: TripOptimizingMethod;
    }
  | undefined
> {
  const res = await axios.post('/edit_trips/get', editRequest);
  if (res && res.data) {
    const data: {
      trips: TripWithStopsDetails[];
      unAssignedStops?: TripArrangeStop[];
      customers?: Customer[];
      warehouses?: Warehouse[];
      defaultTripOptimizingMethod?: TripOptimizingMethod;
    } = res.data.data;
    // console.log(data);

    const trips: TripWithStopsDetails[] = data.trips.map((trip, tripIndex) => {
      if (trip.additionalData?.colorPaletteIndex === undefined) {
        trip.additionalData = { ...(trip.additionalData ?? {}), colorPaletteIndex: tripIndex };
      }
      return {
        ...trip,
        tripStops: trip.tripStops.map((stop, stopIndex) => {
          return {
            ...stop,
            label: makeStopLabel(tripIndex, stopIndex),
            stopIndex,
            tripIndex,
          };
        }),
      };
    });
    if (data.unAssignedStops && data.unAssignedStops.length > 0) {
      const startDate: Date = trips.reduce((minDate, trip) => {
        return trip.startsAt && minDate.valueOf() > trip.startsAt?.valueOf() ? trip.startsAt : minDate;
      }, new Date());
      trips.push(TripArrangeStopSeqToTripWithStopsDetails(data.unAssignedStops, startDate));
    }
    return {
      trips,
      customers: data.customers,
      warehouses: data.warehouses,
      defaultTripOptimizingMethod: data.defaultTripOptimizingMethod,
    };
  }
  return res.data.data;
}

async function updateArrangedTrip(
  editedTrips: TripWithStopsDetails[],
  deletedTripsIds: number[],
  unassignedStopsIds: number[],
  tripOffers: TripOfferUpdateRequest[],
) {
  // console.log(editedTrips);
  const res = await axios.patch('/edit_trips/update', { editedTrips, deletedTripsIds, unassignedStopsIds, tripOffers });
  return res.data.data;
}

async function cancelEditTrip() {
  const res = await axios.patch('/edit_trips/cancel');
  return res.data.data;
}

async function optimizeTrips(
  trips: TripWithStopsDetails[],
  all: boolean = false,
  defaultOptimizingMethod: TripOptimizingMethod = TripOptimizingMethod.NONE,
) {
  const optTrips = await Promise.all(
    trips.map((trip) => {
      if (trip.tripId === -1 || !(trip.editable ?? true) || !!trip.DSP) return trip;
      const method: TripOptimizingMethod = trip.optimizingMethod ?? defaultOptimizingMethod;
      console.log(`Optimizing use ${method}`);
      // const tripStartAt = moment(trip.tripStops[0].routerArrivalAt);
      if (all || trip.needsOptimize) {
        try {
          return tripsService.reCalculateTrip({
            trip,
            reArrange: !trip.doNotOptimize,
            startsAt: trip.tripStops[0].routerArrivalAt,
            method,
          });
        } catch (err) {
          console.error(err);
          trip.optimizingMethod = undefined;
          return trip;
        }
      } else return trip;
    }),
  );
  return await Promise.all(
    optTrips.map((trip) => {
      LocationsPathService.updateTripPoints(trip);
      return attachTotalInvoiceAndGrossProfitOfTrip(trip);
    }),
  );
}
async function attachTotalInvoiceAndGrossProfitOfTrip(trip: TripWithStopsDetails) {
  const packageId: number[] = [];
  trip.tripStops.forEach((stop, stopIdx) => {
    if (stopIdx > 0) {
      packageId.push(...(stop.stopPackages ?? []).map((sp) => sp.packageId));
    }
  });

  if (packageId.length) {
    const res = await axios.post('/package_groups/sales-and-gross-profit', { packageId, tripId: trip.tripId });
    const info: { invoiceTotal?: number; grossProfit?: number } = res.data.data;
    if (!trip.additionalData) trip.additionalData = {};
    trip.additionalData = { ...trip.additionalData, ...info };
  }
  return trip;
}
async function saveArrangedTrip(
  arrangedTrips: (CurbhubTrip | undefined)[],
  courierIds: (number | null)[],
): Promise<Trip[]> {
  const res = await axios.post('/trips/arrange-trips/save', { arrangedTrips, courierIds });
  return res.data.data;
}

const secondsDiff = (d1: Date, d2: Date): number => {
  return moment(d1).diff(moment(d2), 'seconds');
};

async function reCalculateTrip({
  trip,
  reArrange = false,
  startsAt,
  method,
}: TripReCalculationRequest): Promise<TripWithStopsDetails> {
  return axios.post('/trips/arrange-trips/optimize', { trip, reArrange, startsAt, method }).then((res) => {
    if (res.request) {
      const optResponse: {
        trips: TripWithStopsDetails[];
        skippedStops?: TripArrangeStop[];
        defaultTripOptimizingMethod?: TripOptimizingMethod;
      } = res.data.data;
      if (optResponse.trips?.length) {
        const optTrip = optResponse.trips[0];
        optTrip.needsOptimize = trip.needsOptimize && trip.doNotOptimize;
        optTrip.optimizingMethod = method;
        return optTrip;
      }
    }
    return trip;
  });
}

async function reCalculateTripByGoogleDirections(
  trip: TripWithStopsDetails,
  reArrange: boolean = false,
  startsAt?: Moment,
): Promise<TripWithStopsDetails> {
  const origin: TripArrangeStop = trip.tripStops[0];
  const destination: TripArrangeStop = trip.isRoundTrip ? trip.tripStops[0] : trip.tripStops[trip.tripStops.length - 1];
  const wayPoints: TripArrangeStop[] = trip.tripStops.filter(
    (stop, index) => index !== 0 && (index !== trip.tripStops.length - 1 || trip.isRoundTrip),
  );

  const request: TripReCalculateWithGoogleDirectionRequest = {
    origin: { lat: origin.location.latitude, lng: origin.location.longitude },
    destination: { lat: destination.location.latitude, lng: destination.location.longitude },
    departureTime: startsAt?.toDate(),
    wayPoints: wayPoints.map((stop) => ({ lat: stop.location.latitude, lng: stop.location.longitude } as LatLng)),
    reArrange,
  };

  const fixStopsTimes = (
    startsAt: moment.Moment | undefined,
    StopOrders: number[],
    wayPointsDurations: number[],
    wayPointsDistances: number[],
  ) => {
    let newTripStops: TripArrangeStop[] = [];
    const currentTime = startsAt && startsAt > moment() ? startsAt : moment();

    StopOrders.forEach((orderIndex: number, index: number) => {
      const stop = trip.tripStops[orderIndex];
      const stopWait: number = secondsDiff(stop.routerDepartureAt, stop.routerArrivalAt);
      currentTime.add(wayPointsDurations[index], 'seconds');
      stop.routerArrivalAt = currentTime.toDate(); //.format(dateFormat) as unknown as Date;
      currentTime.add(stopWait, 'seconds');
      stop.routerDepartureAt = currentTime.toDate(); //.format(dateFormat) as unknown as Date;
      stop.distanceFromPreviousStop = wayPointsDistances[index];
      // stop.points = wayPointsPoints[index].map((point) => ({ lat: point[0], lng: point[1] }));
      newTripStops.push(stop);
    });
    return newTripStops;
  };
  return axios.post('/trips/arrange-trips/recalculate', request).then((res) => {
    if (!res.data.result) {
      const errorTrip: TripWithStopsDetails = {
        ...trip,
        status: 'optimizationError',
      };
      return errorTrip;
    }

    const wayPoints = res.data.data.wayPointsOrder ?? [];
    const StopOrders = [0, ...wayPoints.map((idx: number) => idx + 1)];
    if (!trip.isRoundTrip) StopOrders.push(trip.tripStops.length - 1);
    const stopsDurations: number[] = [
      res.data.data.origin.duration,
      ...res.data.data.wayPoints.map((point: any) => point.duration),
      res.data.data.destination.duration,
    ];
    const stopsDistances: number[] = [
      res.data.data.origin.distance,
      ...res.data.data.wayPoints.map((point: any) => point.distance),
      res.data.data.destination.distance,
    ];

    const tripStopsSeq = fixStopsTimes(startsAt, StopOrders, stopsDurations, stopsDistances);

    for (let orderIdx = 0; orderIdx < wayPoints.length; orderIdx++) {
      const prevLocation = trip.tripStops[StopOrders[orderIdx]].location;
      const thisLocation = trip.tripStops[StopOrders[orderIdx + 1]].location;
      const points = res.data.data.wayPoints[orderIdx].points?.length
        ? res.data.data.wayPoints[orderIdx].points
        : [
            [prevLocation.latitude, prevLocation.longitude],
            [thisLocation.latitude, thisLocation.longitude],
          ];
      LocationsPathService.updatePoints(
        prevLocation.locationId,
        thisLocation.locationId,
        'router',
        points.map((latLng: number[]) => ({ lat: latLng[0], lng: latLng[1] })),
      );
    }

    if (trip.isRoundTrip) {
      LocationsPathService.updatePoints(
        trip.tripStops[StopOrders[StopOrders.length - 1]].location.locationId,
        trip.tripStops[0].location.locationId,
        'router',
        (res.data.data.destination.points ?? []).map((latLng: number[]) => ({ lat: latLng[0], lng: latLng[1] })),
      );
    } else {
      LocationsPathService.updatePoints(
        trip.tripStops[StopOrders[StopOrders.length - 2]].location.locationId,
        trip.tripStops[StopOrders[StopOrders.length - 1]].location.locationId,
        'router',
        (res.data.data.destination.points ?? []).map((latLng: number[]) => ({ lat: latLng[0], lng: latLng[1] })),
      );
    }

    const returnAt = trip.isRoundTrip
      ? moment(tripStopsSeq[tripStopsSeq.length - 1].routerDepartureAt)
          .add(res.data.data.destination.duration, 'seconds')
          .toDate()
      : undefined;
    const newArrangedTrip: TripWithStopsDetails = {
      ...trip,
      tripStops: tripStopsSeq,
      returnAt,
      totalMiles: stopsDistances.reduce((totalMiles, distance) => totalMiles + distance),
      needsOptimize: trip.needsOptimize && trip.doNotOptimize,
    };

    return newArrangedTrip;
  });
}
async function reCalculateTripByGoogleOptimizer(
  trip: TripWithStopsDetails,
  reArrange: boolean = false,
  startsAt?: Moment,
): Promise<TripWithStopsDetails> {
  console.log(`optimizing trip by Optimizer not implemented`, { reArrange, startsAt });
  return trip;
  // #region TODO: Optimize by Optimizer
}
async function createTripOffer(
  tripId: number,
  createTripOfferRequest: CreateTripOfferRequest,
): Promise<Trip | undefined> {
  const res = await axios.post(`/trips/${tripId}/offer`, createTripOfferRequest);
  return res.data.data;
}

async function deleteTripOffer(tripOfferId: number): Promise<boolean | undefined> {
  const res = await axios.delete(`/trips/offer/${tripOfferId}`);
  return res.data.data;
}

async function deleteAllTrips(): Promise<boolean | undefined> {
  const res = await axios.delete('/trips/delete-all');
  return res.data.data;
}

async function deleteTrip(tripId: number): Promise<boolean | undefined> {
  const res = await axios.delete(`/trips/${tripId}`);
  return res.data.data;
}

async function resetStateAllTrips(): Promise<boolean | undefined> {
  const res = await axios.delete('/trips/reset-all');
  return res.data.data;
}

function deliveryOrderToTripArrangeStop(
  deliveryOrder: CustomerLocationOrder,
  colorClass: string = 'color-unassigned',
): TripArrangeStop {
  const now = new Date();
  return {
    label: `${deliveryOrder.customer.firstName?.substring(0, 1)} ${deliveryOrder.customer.lastName?.substring(0, 1)}`,
    colorClass,
    location: deliveryOrder.customer.location as StopLocation,
    routerArrivalAt: now,
    routerDepartureAt: now,
    stopIndex: 0,
    tripIndex: -1,
    tripStopId: 0,
    additionalInfo: '',
    companyName: deliveryOrder.customer.companyName,
    distanceFromPreviousStop: 0,
    locationId: deliveryOrder.customer.locationId,
    name: ` ${deliveryOrder.customer.firstName} ${deliveryOrder.customer.lastName}`,
    timeWindowFrom: deliveryOrder.packageGroups[0].timeWindowFrom,
    timeWindowTo: deliveryOrder.packageGroups[0].timeWindowTo,
    shipToAddressee: deliveryOrder.shipToAddressee,
    stopPackages: deliveryOrder.packageGroups.reduce((allPackages, packagegroup) => {
      packagegroup.packages.forEach((packageItem) => {
        allPackages.push({
          packageId: packageItem.packageId,
          interactionType: InteractionTypeEnum.DROP_OFF,
          tripStopId: 0,
        });
      });
      return allPackages;
    }, [] as TripArrangeStopPackage[]),
  };
}

async function importFromCSV(formData: FormData) {
  // let lastResponseIndex = 0;
  // let stateObj = {};
  const res = await axios.post('/csv_import/trips', formData, {
    onDownloadProgress: (event) => {
      const response: string = event.currentTarget?.response;
      console.log({ response, event });
    },
  });

  return res.data;
}

async function getDeliveryZones(locationIds?: number[]): Promise<DeliveryZoneWithLocationIds[]> {
  const res = await axios.post('/trips/get-delivery-zones', { locationIds });
  const zones = res.data.data as DeliveryZoneWithLocations[];
  return zones.map((zone) => ({
    caption: zone.caption,
    locationIds: [
      ...zone.bindedLocations.map((loc) => loc.locationId),
      ...zone.calculatedLocations.map((loc) => loc.locationId),
    ],
  }));
}

const tripsService = {
  fetchTrips,
  fetchAliveTrips,
  fetchTripStops,
  createTrip,
  arrangeTrip,
  fetchTripsOfDateForEdit,
  cancelEditTrip,
  createTripOffer,
  deleteTripOffer,
  deleteAllTrips,
  deleteTrip,
  resetStateAllTrips,
  makeStopLabel,
  reCalculateTrip,
  reCalculateTripByGoogleDirections,
  reCalculateTripByGoogleOptimizer,
  optimizeTrips,
  saveArrangedTrip,
  updateArrangedTrip,
  deliveryOrderToTripArrangeStop,
  importFromCSV,
  LocationsPathService,
  getDeliveryZones,
};
export default tripsService;
