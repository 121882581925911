import { ChatBoxInputType, ChatBoxOptionStruct } from '../../components/ChatBox/chatbox.types';

/* eslint-disable */
export enum AIMessageType {
  MESSAGE = 'MESSAGE',
  CONFIRMATION_RESPONSE_CONFIRMED = 'CONFIRMATION_RESPONSE_CONFIRMED',
  CONFIRMATION_RESPONSE_REJECTED = 'CONFIRMATION_RESPONSE_REJECTED',
  CLARIFICATION_RESPONSE = 'CLARIFICATION_RESPONSE',

  CHAIN_OF_TOUGHT = 'CHAIN_OF_TOUGHT',
  DATA_REQUEST = 'DATA_REQUEST',
  CLARIFICATION_REQUEST = 'CLARIFICATION_REQUEST',
  RESULT = 'RESULT',

  ERROR = 'ERROR',
  CONFIRMATION_REQUEST = 'CONFIRMATION_REQUEST',
  USER_OUTPUT = 'USER_OUTPUT',

  SETUP_AGENT = 'SETUP_AGENT',
  SETUP_AGENT_CANCELLED = 'SETUP_AGENT_CANCELLED',
  SETUP_AGENT_SAVED = 'SETUP_AGENT_SAVED',

  WELCOME = 'WELCOME',
}
export enum AIMessageStatuses {
  SENT = 'SENT',
  FAILED = 'FAILED',
}
export enum AIMessageParticipantType {
  USER = 'USER',
  AI = 'AI',
  SOLVER = 'SOLVER',
  AGENT = 'AGENT',
}

export enum AIChatSessionStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  SETUP_AGENT = 'SETUP_AGENT',
}
export enum AIChatSessionType {
  CHAT = 'CHAT',
  AGENT = 'AGENT',
}

export enum AISolutionStatus {
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
  HAS_ERROR = 'HAS_ERROR',
  UNKNOWN = 'UNKNOWN',
}

export enum AIChatStatuses {
  STREAM_START = 'STREAM_START',
  INIT = 'INIT',
  AI_SOLVER_CONVERSATION = 'AI_SOLVER_CONVERSATION',
  RUNNING_SOLUTION = 'RUNNING_SOLUTION',
  ERROR = 'ERROR',
  FINALIZE = 'FINALIZE',
}
/* eslint-enable */

export interface AISolutionStepStruct {
  stepNumber?: number;
  stepType?: string;
  description?: string;
  runtimeMessage?: string;
  sqlQuery?: string;
  functionCall?: any;
}

export interface AIMessageStruct {
  aiChatMessageId?: number;
  // aiSolutionId?: number;
  displayName?: string;
  status?: AIMessageStatuses;
  sender: AIMessageParticipantType;
  reciever?: AIMessageParticipantType;
  type: AIMessageType;
  message: string;
  solution?: AISolutionStepStruct[];
  solutionStatus?: AISolutionStatus;
  date: Date;
  usageInfo?: any;
  originalResponse?: string;
  originalPrompt?: string;
  ragChunks?: AIChunkStruct[];
  relatedAgentId?: number;
  convertableToAgent?: boolean;
}

export interface AIChunkStruct {
  aiChunkId?: number;
  chunk: string;
  metaData: any;
  aiKnowledge: AIKnowledgeStruct;
}

export interface AIKnowledgeStruct {
  aiKnowledgeId?: number;
  type: string;
  content: string;
}

export interface AIChatSession {
  user?: string;
  aiChatSessionId: number;
  date: Date;
  title: string;
  conversation: AIMessageStruct[];
  status: AIChatSessionStatus;
  type: AIChatSessionType;
}

export interface AIAgentSetupSession {
  conversation: AIMessageStruct[];
  title?: string;
  summary?: string;
  solver_instructions?: AISolutionStepStruct[];
  schedule?: string;
  scheduleDescription?: string;
}

export const confirmationOptions: ChatBoxOptionStruct[] = [
  { name: 'yes', caption: 'Looks good', color: 'success' },
  { name: 'no', caption: 'Something Wrong', color: 'warning' },
];

export type AIChatInputMode = 'MESSAGING' | 'CLARIFICATION' | 'CONFIRMATION' | 'CORRECTION' | 'PROCESSING' | 'DISABLED';

export const InputStates = {
  MESSAGING: {
    inputType: 'TEXT' as ChatBoxInputType,
    inputPlaceholder: 'Type a message',
    sendDisabled: false,
    processing: false,
  },
  CLARIFICATION: {
    inputType: 'TEXT' as ChatBoxInputType,
    inputPlaceholder: 'Your clarification',
    sendDisabled: false,
    processing: false,
  },
  CONFIRMATION: {
    inputType: 'SELECT' as ChatBoxInputType,
    inputPlaceholder: 'Is it satisfactory?',
    sendDisabled: true,
    processing: false,
  },
  CORRECTION: {
    inputType: 'TEXT' as ChatBoxInputType,
    inputPlaceholder: 'What is wrong?',
    sendDisabled: false,
    processing: false,
  },
  PROCESSING: {
    inputType: 'TEXT' as ChatBoxInputType,
    inputPlaceholder: 'Wait a moment ...',
    sendDisabled: true,
    processing: true,
  },
  DISABLED: {
    inputType: 'TEXT' as ChatBoxInputType,
    inputPlaceholder: '',
    sendDisabled: true,
    processing: false,
  },
};

export interface AIAgent {
  aiAgentId: number;
  title: string;
  summary: string;
  schedule: string;
  scheduleDescription: string;
  notifications: any[];
  additionalData: { lastExecutionDate: Date; lastExecutionIsSuccessful: boolean };
  active: boolean;
  shipperUserId: number;
  aiChatSessionId: number;
  aiSolutionId: number;
  solution: { stepChain: AISolutionStepStruct[] };
}

export type AIDataFormat =
  | 'text'
  | 'currency_0'
  | 'currency_2'
  | 'number_0'
  | 'number_2'
  | 'number'
  | 'percentage_0'
  | 'percentage_2'
  | 'percentage'
  | 'date'
  | 'date&time';
export interface AIDataMeta {
  [key: string]: {
    caption: string;
    format: AIDataFormat;
  };
}
