/* eslint-disable */
import { Collapse, FormControlLabel, Grid, IconButton, Slide, Switch, Typography } from '@mui/material';
import AISessionList from '../../components/AIFloatChatbox/AISessionList';
import { useState } from 'react';
import { BugReportOutlined, ChevronRight, Close } from '@material-ui/icons';
import { AIChatSession } from '../Prospero/prospero.types';

interface AISessionSidebarProps {
  autoHide?: boolean;
  open?: boolean;
  currentSessionId?: number;
  debugMode?: boolean;
  /* eslint-disable */
  onSessionClick?: (session: AIChatSession) => void;
  onDebugModeChange?: (debugMode: boolean) => void;
  onHideClick?: () => void;
  /* eslint-enable */
}

export default function AISessionSidebar({
  currentSessionId,
  open = true,
  debugMode,
  autoHide,
  onDebugModeChange,
  onSessionClick,
  onHideClick,
}: AISessionSidebarProps) {
  const autoHideStyle: any = autoHide ? { position: 'absolute', right: 0 } : {};
  const handleSessionClick = (session: AIChatSession) => {
    if (onSessionClick) onSessionClick(session);
    if (autoHide && onHideClick) onHideClick();
  };
  return (
    <Collapse
      className="ai-session-sidebar"
      in={open}
      orientation="horizontal"
      style={{ maxHeight: '100%', height: '100%', overflow: 'hidden', ...autoHideStyle }}
    >
      <Grid
        container
        flexDirection="column"
        p={1}
        style={{
          width: 300,
          maxHeight: '100%',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Grid item style={{ width: '100%' }} alignItems="center">
          <Grid container alignItems="center" pl={autoHide ? 0 : 1}>
            {autoHide && onHideClick && (
              <Grid item>
                <IconButton size="small" onClick={() => onHideClick()}>
                  <ChevronRight className="white" />
                </IconButton>
              </Grid>
            )}
            <Grid item flexGrow={1} style={{ height: 32 }} alignContent="center">
              <Typography variant="body1">Sessions</Typography>
            </Grid>
            {onDebugModeChange && (
              <Grid item pr={1}>
                <FormControlLabel
                  control={
                    <Switch size="small" checked={debugMode} onChange={(e) => onDebugModeChange(e.target.checked)} />
                  }
                  label={<BugReportOutlined />}
                  labelPlacement="start"
                  title="Debug Mode"
                  style={{ color: '#888' }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item p={1} flexGrow={1} style={{ width: '100%' }}>
          <div style={{ height: '100%', width: '100%' }}>
            <AISessionList onSessionClick={handleSessionClick} currentSessionId={currentSessionId} />
          </div>
        </Grid>
      </Grid>
    </Collapse>
  );
}
