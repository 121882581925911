import { TripStopTimeWindowStatuses } from '../../store/config/enums/tripStatusFilter.enum';
import { ColorPalette } from '../../store/config/types/ColorSpectrum';
import { DndTimeLineChartItem } from '../DndTimelineChart/DndTimelineChart';
import './TripsDnd.styles.scss';

interface TripDndItemProps {
  item: DndTimeLineChartItem;
  label?: string;
}

export default function TripDndItem({ item, label }: TripDndItemProps) {
  const pColor =
    item.colorIndex !== undefined
      ? ColorPalette[item.colorIndex % ColorPalette.length]
      : { main: 'loghtgray', dark40: 'black', light20: 'beige' };
  const isCompleted = item.additionalInfo.status == 'COMPLETED';
  return (
    <div
      className={`stop ${item.colorClass ?? ''} ${item.additionalInfo.status ?? ''}${
        [TripStopTimeWindowStatuses.TIME_WINDOW_MISSED, TripStopTimeWindowStatuses.TIME_WINDOW_WARNING].includes(
          item.additionalInfo.timeWindowStatus,
        ) || item.additionalInfo.packagesStatus
          ? ' ATTENTION'
          : ''
      }`}
      style={
        isCompleted
          ? {
              boxShadow: `0 0 1px 2px ${pColor.dark40}`,
              backgroundColor: pColor.dark40,
              color: pColor.light20,
              borderColor: 'none',
            }
          : { boxShadow: `0 0 1px 1.5px ${pColor.dark40}`, backgroundColor: pColor.light20, color: pColor.dark40 }
      }
    >
      {label ?? item.label}
    </div>
  );
}
