import { Error } from '@material-ui/icons';
import { Grid } from '@mui/material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import FilterableTable, { FilterableTableColumn } from '../../../components/FilterableTable/FilterableTable';
import { AIDataFormat, AIDataMeta } from '../prospero.types';
import moment from 'moment-timezone';

export interface UserDataTableProps {
  data: any;
  debugMode?: boolean;
  meta?: AIDataMeta;
  // eslint-disable-next-line
  onDisplayAllClick?: (data: any) => void;
}
export default function UserDataTable({ data, meta, debugMode }: UserDataTableProps) {
  // const MAX_VISIBLE_ROWS = 3;
  let isDataOK = Array.isArray(data);
  if (isDataOK) {
    data = (data as any[]).map((row) => {
      const rowObj: any = {};
      Object.keys(row).forEach((field) => {
        try {
          rowObj[field] = JSON.stringify(row[field]);
        } catch {
          rowObj[field] = '#ERROR#';
        }
      });
      return rowObj;
    });
  }

  if (!isDataOK) {
    return (
      <Grid item>
        <Grid container flexDirection="column">
          <Grid item>
            <Error color="error" /> <span>Data is null or not a table.</span>
          </Grid>
          {debugMode && (
            <Grid item>
              <SyntaxHighlighter language="json" style={a11yDark} showLineNumbers wrapLongLines>
                {JSON.stringify(data, null, '   ')}
              </SyntaxHighlighter>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
  const renderCell = (value: any, format: AIDataFormat) => {
    const formatParts = format.split('_');
    const formatType = formatParts[0];
    let prefix = '';
    let postfix = '';
    const digits = formatParts.length > 1 ? +formatParts[1] : undefined;
    console.log({ formatParts, format });

    try {
      if (['currency', 'number', 'percentage'].includes(formatType)) {
        if (!isNaN(+value)) {
          switch (formatType) {
            case 'currency':
              value = digits ? (+value).toFixed(digits) : +value;
              prefix = '$';
              break;
            case 'percentage':
              value = (+value * 100).toFixed(0);
              postfix = '%';
              break;
            default:
              value = digits ? (+value).toFixed(digits) : +value;
          }
        }
      } else if (['date', 'date&time'].includes(formatType)) {
        let dateFormat = 'MM/DD/YYYY';
        if (formatType == 'date&time') dateFormat += ' h:mm a';
        if (moment(value).isValid()) {
          value = moment(value).format(dateFormat);
        }
      } else if (['text', 'time'].includes(format)) {
        const strValue = `${value}`;
        if (strValue.startsWith('"') && strValue.endsWith('"')) {
          value = strValue.substring(1, strValue.length - 1);
        }
      }
    } catch (err) {
      console.error(err);
      return value;
    }
    return prefix + value + postfix;
  };
  const columns: FilterableTableColumn[] =
    isDataOK && data.length
      ? Object.keys(data[0]).map((c) => ({
          field: c,
          caption: meta ? meta[c].caption : c,
          filterType: 'string',
          sortable: true,
          render: meta ? (row) => renderCell(row[c], meta[c].format) : undefined,
        }))
      : [];
  // const visibleData = isDataOK ? (data as Object[]).filter((elm, idx) => idx < MAX_VISIBLE_ROWS) : [];
  // const hiddenRows = isDataOK && data.length > MAX_VISIBLE_ROWS ? data.length - MAX_VISIBLE_ROWS : 0;
  console.log(meta);

  return columns.length > 0 ? (
    <Grid item p={1} fontSize="normal" overflow="auto">
      <FilterableTable columns={columns} data={data} itemPerPage={5} />
    </Grid>
  ) : (
    <Grid item p={1}>
      No data to display
    </Grid>
  );
}
