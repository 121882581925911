import { useState, ReactNode, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { CheckCircle, Error as ErrorIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { drawerWidth, transition, container } from '../../assets/jss/material-dashboard-react';
import { RootState } from '../../store/config/types';
import { AppActions } from '../../store/config/ActionTypes';
import { removeToast } from '../../store/actions/toast.actions';
import { setTime } from '../../store/actions/time.actions';
// import routes from '../../utils/routes';
// import Header from '../../components/Header/Header';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import Snackbar from '../../components/Snackbar/Snackbar';
import moment from 'moment-timezone';
import AIFloatChatbox from '../AIFloatChatbox/AIFloatChatbox';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: { position: 'relative', top: '0', height: '100vh' },
    mainPanel: {
      [theme.breakpoints.up('md')]: { width: `calc(100% - ${drawerWidth}px)` },
      overflow: 'visible',
      position: 'relative',
      float: 'right',
      ...transition,
      maxHeight: '100%',
      width: '100%',
      overflowScrolling: 'touch',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px',
      // minHeight: 'calc(100vh - 123px)',
      minHeight: 'calc(100vh)',
      [theme.breakpoints.down('sm')]: { padding: '0' },
    },
    container: {
      ...container,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: { padding: '0' },
    },
  }),
);

const SNACKBAR_ICON: { [key: string]: any } = {
  success: CheckCircle,
  danger: ErrorIcon,
};

interface LayoutProps {
  children: ReactNode;
  onLogout: () => void;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  showToast: state.toast.show,
  toastMessage: state.toast.message,
  toastMessageType: state.toast.messageType,
  time: state.time,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  onCloseToast: () => dispatch(removeToast()),
  setTimeNow: () => dispatch(setTime()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & LayoutProps;

function Layout({
  auth,
  showToast,
  toastMessage,
  toastMessageType,
  time,
  onCloseToast,
  // onLogout,
  setTimeNow,
  children,
}: PropsType) {
  const classes = useStyles();
  const [showSideDrawer, setShowSideDrawer] = useState<boolean>(false);
  const location = useLocation();
  const [aiFloatingChatOpen, setAiFloatingChatOpen] = useState(false);

  useEffect(() => {
    auth.account?.shipper?.timezone && moment.tz.setDefault(auth.account?.shipper?.timezone);
    setTimeout(() => {
      setTimeNow();
    }, 5000);
  }, [time, setTimeNow]);

  useEffect(() => {
    setAiFloatingChatOpen(false);
  }, [location.pathname]);

  const sideDrawerClosedHandler = () => setShowSideDrawer(false);

  const sideDrawerToggleHandler = () => setShowSideDrawer((prevState) => !prevState);

  return (
    <div className={classes.wrapper}>
      <SideDrawer
        open={showSideDrawer}
        closed={sideDrawerClosedHandler}
        onItemSelected={sideDrawerClosedHandler}
        handleDrawerOpen={sideDrawerToggleHandler}
        timezone={auth.account?.shipper?.timezone}
        time={time.time}
      />

      <div className={classes.mainPanel}>
        {/* <Header drawerToggleClicked={sideDrawerToggleHandler} routes={routes} handleLogout={onLogout} /> */}
        <div className={classes.content}>
          <div className={classes.container}>{children}</div>
        </div>
      </div>
      <Snackbar
        place="bl"
        color={toastMessageType}
        icon={SNACKBAR_ICON[toastMessageType]}
        message={toastMessage}
        open={showToast}
        closeNotification={onCloseToast}
        close
      />
      <AIFloatChatbox
        visible={!location.pathname.toLowerCase().startsWith('/prospero')}
        open={aiFloatingChatOpen}
        onOpen={() => setAiFloatingChatOpen(true)}
      />
    </div>
  );
}

export default connector(Layout);
