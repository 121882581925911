import { Chip } from '@mui/material';

interface TripDndUnassignedAreaProps {
  searchQuery?: string;
  /* eslint-disable */
  onRemoveFilter?: () => void;
  /* eslint-enable */
}

export default function TripDndUnassignedArea({ searchQuery, onRemoveFilter }: TripDndUnassignedAreaProps) {
  if (!searchQuery) return <>UNASSIGNED</>;
  return <Chip label={searchQuery} onDelete={onRemoveFilter} />;
}
