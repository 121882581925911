import { useEffect, useState } from 'react';
import {
  AIAgentSetupSession,
  AIChatInputMode,
  AIChatSession,
  AIChatSessionStatus,
  AIChatSessionType,
  AIChatStatuses,
  AIMessageParticipantType,
  AIMessageStruct,
  AIMessageType,
} from '../../screens/Prospero/prospero.types';
import ProsperoMessage from '../../screens/Prospero/ProsperoMessage';
import ChatBox from '../ChatBox/ChatBox';
import { ChatBoxInputType, ChatBoxOptionStruct } from '../ChatBox/chatbox.types';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import JsonGrid from '../JSONGrid/JSONGrid';
import { Close } from '@material-ui/icons';
import { AIRAGKnowledgeManager } from '../../screens/Prospero/AIRAGKnowledgeManager';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ProsperoIcon } from '../../assets/images';
import ProsperoService from '../../services/prospero.service';
import { RootState } from '../../store/config/types';
import { ThunkDispatch } from 'redux-thunk';
import { aiAddMessageToSessions, aiCreateNewSession } from '../../store/actions/ai.actions';
import { connect, ConnectedProps } from 'react-redux';
import AIWaitingMessage from '../../screens/Prospero/AIWaitingMessage';
import { AIAgentSetupForm } from '../../screens/Prospero/AIAgentSetupForm';

interface AISessionMessagesProps {
  session?: AIChatSession;
  /*  eslint-disable */
  onNewSessionCreated?: (session: AIChatSession) => void;
  /* ellint-enable */
}

const mapStateToProps = (state: RootState, props: AISessionMessagesProps) => ({
  auth: state.auth,
  ai: state.ai,
  ...props,
});
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  sendMessage: (
    message: AIMessageStruct,
    session: AIChatSession,
    onComplete?: (conversation?: AIMessageStruct[]) => void,
    handleStatusChange?: (chatStatus: string) => void,
    handleStreamMessage?: (streamStr: string) => void,
    handleResponseRecieve?: (response: string) => void,
  ) =>
    dispatch(
      aiAddMessageToSessions(
        message,
        session,
        onComplete,
        handleStatusChange,
        handleStreamMessage,
        handleResponseRecieve,
      ),
    ),
  createSession: (
    title?: string,
    message?: AIMessageStruct,
    onComplete?: (s: AIChatSession) => void,
    handleStatusChange?: (chatStatus: string) => void,
    handleStreamMessage?: (streamStr: string) => void,
    handleResponseRecieve?: (response: string) => void,
  ) =>
    dispatch(
      aiCreateNewSession(title, message, onComplete, handleStatusChange, handleStreamMessage, handleResponseRecieve),
    ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const InputStates = {
  MESSAGING: {
    inputType: 'TEXT' as ChatBoxInputType,
    inputPlaceholder: 'Type a message',
    sendDisabled: false,
    processing: false,
  },
  CLARIFICATION: {
    inputType: 'TEXT' as ChatBoxInputType,
    inputPlaceholder: 'Your clarification',
    sendDisabled: false,
    processing: false,
  },
  CONFIRMATION: {
    inputType: 'SELECT' as ChatBoxInputType,
    inputPlaceholder: 'Is it satisfactory?',
    sendDisabled: true,
    processing: false,
  },
  CORRECTION: {
    inputType: 'TEXT' as ChatBoxInputType,
    inputPlaceholder: 'What is wrong?',
    sendDisabled: false,
    processing: false,
  },
  PROCESSING: {
    inputType: 'TEXT' as ChatBoxInputType,
    inputPlaceholder: 'Wait a moment ...',
    sendDisabled: true,
    processing: true,
  },
  DISABLED: {
    inputType: 'TEXT' as ChatBoxInputType,
    inputPlaceholder: '',
    sendDisabled: true,
    processing: false,
  },
};

export const confirmationOptions: ChatBoxOptionStruct[] = [
  { name: 'yes', caption: 'Looks good', color: 'success' },
  { name: 'no', caption: 'Something Wrong', color: 'warning' },
];

export enum AISolutionStatus {
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
  HAS_ERROR = 'HAS_ERROR',
  UNKNOWN = 'UNKNOWN',
}

// region AISessionMessages
function AISessionMessages({ session, ai, auth, onNewSessionCreated, sendMessage, createSession }: PropsFromRedux) {
  const userDisplayName = auth?.account?.firstName ?? 'User';
  const [messages, setMessages] = useState<AIMessageStruct[]>([]);
  const [agentSetupSession, setAgentSetupSession] = useState<AIAgentSetupSession>();
  const [processing, setProcessing] = useState(false);
  const [processDescription, setProcessDescription] = useState<string>('Wait a moment ...');
  const [inputState, setInputState] = useState<AIChatInputMode>('MESSAGING');
  const [streamMessage, setStreamMessage] = useState<string>('Processing');
  const [sendDisabled, setSendDisabled] = useState(false);

  const [isAIAdmin, setIsAIAdmin] = useState(false);
  const [tableViewerData, setTableViewerData] = useState<any[]>([]);
  const [jsonViewerData, setJsonViewerData] = useState<any>();
  const [ragMngId, setRagMngId] = useState<number>(-1);
  const [aiPartialResponse, setAiPartialResponse] = useState<string>();
  const [setupAgentMode, setSetupAgentMode] = useState(false);

  const updateInputStatus = () => {
    if (session?.type === AIChatSessionType.AGENT) {
      setInputState('DISABLED');
      return;
    }
    let inState: AIChatInputMode = 'MESSAGING';
    if (messages?.length) {
      const lastMessage = messages[messages.length - 1];
      switch (lastMessage.type) {
        case AIMessageType.CLARIFICATION_REQUEST:
          inState = 'CLARIFICATION';
          break;
        case AIMessageType.CONFIRMATION_REQUEST:
          inState = 'CONFIRMATION';
          break;
        case AIMessageType.CONFIRMATION_RESPONSE_REJECTED:
          inState = 'CORRECTION';
          break;
      }
    }
    if (setupAgentMode) {
      inState = 'CONFIRMATION';
    }
    if (processing) inState = 'PROCESSING';
    setInputState(inState);
  };

  const updateMessage = (index: number, updates: any) => {
    try {
      setMessages(
        messages.map((message, msgIndex) => {
          if (msgIndex === index) return { ...message, ...updates };
          return message;
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getCurrentMessageType = (): AIMessageType => {
    switch (inputState) {
      case 'CLARIFICATION':
        return AIMessageType.CLARIFICATION_RESPONSE;
      case 'CORRECTION':
        return AIMessageType.CONFIRMATION_RESPONSE_REJECTED;
      case 'CONFIRMATION':
        return AIMessageType.CONFIRMATION_RESPONSE_CONFIRMED;
    }
    return AIMessageType.MESSAGE;
  };

  const addMessageToCurrentSession = (
    message: AIMessageStruct,
    onComplete?: (conversation?: AIMessageStruct[]) => void,
    onStart?: () => void,
  ) => {
    if (onStart) onStart();
    if (session) {
      sendMessage(message, session, onComplete, handleStatusChange, handleStreamMessage, handleResponseRecieve);
    }
  };
  const handleSendClick = (messageBody: string) => {
    if (setupAgentMode) {
      handleSendSetupAgentMessage('MESSAGE', messageBody);
    } else {
      setProcessing(true);
      setProcessDescription('Sending ...');
      setAiPartialResponse('');
      setProcessDescription('');

      if (session) {
        addMessageToCurrentSession(
          {
            displayName: userDisplayName,
            message: messageBody,
            type: getCurrentMessageType(),
            date: new Date(),
            sender: AIMessageParticipantType.USER,
          },
          (conversation) => {
            setProcessing(false);
            console.log(conversation);

            if (conversation) setMessages(conversation);
          },
        );
      } else {
        createSession(
          messageBody,
          {
            type: AIMessageType.MESSAGE,
            message: messageBody,
            date: new Date(),
            displayName: userDisplayName,
            sender: AIMessageParticipantType.USER,
            reciever: AIMessageParticipantType.AI,
          },
          (createdSession) => {
            if (onNewSessionCreated) onNewSessionCreated(createdSession);
            setProcessing(false);
          },
        );
      }
    }
  };

  const renderWelcomeMessage = () => {
    return (
      <Grid container flexGrow={1} justifyContent="center" alignItems="center">
        <div style={{ textAlign: 'center', margin: '10%' }}>
          <img src={ProsperoIcon} alt="Prospero" />
          <Typography variant="h4">Prospero</Typography>
          <Typography variant="caption">Your Buisness AI</Typography>
          <Typography variant="body1" pt={2}>
            Select a previous session to continue the conversation or type a question to create a new session
          </Typography>
        </div>
      </Grid>
    );
  };
  const handleSendSetupAgentMessage = (command: string, message?: string) => {
    if (!session) return;
    const msgType = getCurrentMessageType();
    setProcessing(true);
    setProcessDescription('Sending ...');
    setAiPartialResponse('');
    setProcessDescription('');
    if (command != 'CANCEL' && msgType === AIMessageType.CONFIRMATION_RESPONSE_CONFIRMED) {
      command = 'SAVE';
    }
    addMessageToCurrentSession(
      {
        displayName: userDisplayName,
        message: `${command}:${message ?? ''}`,
        type: AIMessageType.SETUP_AGENT,
        date: new Date(),
        sender: AIMessageParticipantType.USER,
      },
      (conversations) => {
        let sessionStatus: AIChatSessionStatus = AIChatSessionStatus.SETUP_AGENT;
        if (['CANCEL', 'SAVE'].includes(command)) {
          sessionStatus = AIChatSessionStatus.OPEN;
          const convertableIndex = session.conversation.findIndex((msg) => msg.convertableToAgent);
          if (command == 'SAVE' && convertableIndex >= 0) {
            session.conversation[convertableIndex].convertableToAgent = false;
            session.conversation[convertableIndex].relatedAgentId = 1;
          }
          session.conversation.forEach((msg, idx) => {
            if (msg.type === AIMessageType.SETUP_AGENT) {
              session.conversation[idx].type =
                command == 'SAVE' ? AIMessageType.SETUP_AGENT_SAVED : AIMessageType.SETUP_AGENT_CANCELLED;
            }
          });
        }

        session.status = sessionStatus;
        setSetupAgentMode(sessionStatus === AIChatSessionStatus.SETUP_AGENT);
        setAgentSetupSession(ProsperoService.extractAgentSetupSession(session));
        setProcessing(false);
      },
    );
  };

  const handleTableViewerOpen = (data: any[]) => {
    const fixedData: any[] = data.map((row) => {
      let fixedRow: any = {};
      Object.keys(row).forEach((field) => {
        fixedRow[field] = row[field] ?? ' ';
      });
      return fixedRow;
    });
    setTableViewerData(fixedData);
  };

  const handleJsonViewerOpen = (data: string) => {
    setJsonViewerData(data);
  };

  const handleJsonViewerClose = () => {
    setJsonViewerData(undefined);
  };

  const handleRagManagementClick = (messageId: number) => {
    setRagMngId(messageId);
  };
  const handleRagManagementClose = (messageId: number) => {
    setRagMngId(-1);
    const mIdx = messages.findIndex((msg) => msg.aiChatMessageId === messageId);
    if (mIdx >= 0) updateMessage(mIdx, {});
  };

  const handleTableViewerClose = () => {
    setTableViewerData([]);
  };

  const handleStreamMessage = (streamStr: string) => {
    setStreamMessage(streamStr);
  };
  const handleResponseRecieve = (response: string) => {
    setAiPartialResponse(response.replaceAll('\\n', '\n'));
  };
  const handleStatusChange = (chatStatus: string) => {
    let description = 'Processing';
    switch (chatStatus as AIChatStatuses) {
      case AIChatStatuses.INIT:
        description = 'Initializing';
        break;
      case AIChatStatuses.ERROR:
        description = 'Error handling';
        break;
      case AIChatStatuses.STREAM_START:
        description = 'Thinking';
        break;
      case AIChatStatuses.AI_SOLVER_CONVERSATION:
        description = 'Finding solution';
        break;
      case AIChatStatuses.RUNNING_SOLUTION:
        description = 'Testing solution';
        break;
      case AIChatStatuses.FINALIZE:
        description = 'Finalizing output';
        break;
    }
    setProcessDescription(description);
  };

  const handleConfirmation = (answer: string) => {
    const messageBody = confirmationOptions.find((option) => option.name == answer)?.caption ?? answer;

    if (answer == 'yes') {
      updateMessage(messages.length - 1, { solutionStatus: AISolutionStatus.CONFIRMED });
      handleSendClick(messageBody);
    } else {
      setInputState('CORRECTION');
      // updateMessage(messages.length - 1, { solutionStatus: AISolutionStatus.REJECTED });
    }
  };

  useEffect(() => {
    if (session) {
      const isAgentSession = session.type === AIChatSessionType.AGENT;
      const sessionMessages = [...session.conversation];
      const setupMode = session.status === AIChatSessionStatus.SETUP_AGENT;
      if (!setupMode) {
        let lastUserOutputIndex = -1;
        sessionMessages.forEach((msg, idx) => {
          if (msg.type === AIMessageType.USER_OUTPUT) lastUserOutputIndex = idx;
        });
        if (!isAgentSession && lastUserOutputIndex >= 0 && !sessionMessages[lastUserOutputIndex].relatedAgentId) {
          sessionMessages[lastUserOutputIndex].convertableToAgent = true;
        }
      }
      if (setupMode !== setupAgentMode) setSetupAgentMode(setupMode);
      setMessages(sessionMessages);

      setAgentSetupSession(ProsperoService.extractAgentSetupSession(session));
    }
  }, [session, setupAgentMode]);
  useEffect(() => {
    updateInputStatus();
  }, [session, messages, processing]);
  useEffect(() => {
    setSendDisabled(InputStates[inputState].sendDisabled);
  }, [inputState]);

  const NewConversationMessage = (
    <ProsperoMessage
      message={{
        message: `Welcome back ${auth.account?.firstName}, to start type a question `,
        date: new Date(),
        type: AIMessageType.WELCOME,
        displayName: 'AI',
        sender: AIMessageParticipantType.AI,
      }}
      mIndex={0}
    />
  );

  return (
    <>
      <ChatBox
        conversationHeaderList={[]}
        messages={messages ?? []}
        selectOptions={confirmationOptions}
        hideConversationList
        inputType={inputState && InputStates[inputState].inputType}
        inputClassName={setupAgentMode ? 'input-setup-agent' : undefined}
        inputPlaceholder={inputState && InputStates[inputState].inputPlaceholder}
        sendDisabled={sendDisabled}
        renderMessage={(message: any, mIndex: number) => (
          <ProsperoMessage
            debugMode={ai?.debugMode}
            message={message}
            mIndex={mIndex}
            onTableViewerOpen={handleTableViewerOpen}
            onJsonViewerOpen={handleJsonViewerOpen}
            onRagManageClick={(message, mindex) => handleRagManagementClick(mindex)}
            onSetupAsAgentClick={() => handleSendSetupAgentMessage('START')}
            processing={processing}
          />
        )}
        renderAfterMessageList={() => (
          <AIAgentSetupForm setupSession={agentSetupSession} onCancel={() => handleSendSetupAgentMessage('CANCEL')} />
        )}
        // renderWelcomeMessage={renderWelcomeMessage}
        renderWaitingMessage={() => (
          <AIWaitingMessage
            aiPartialResponse={aiPartialResponse}
            debugMode={ai?.debugMode}
            processDescription={processDescription}
            streamMessage={streamMessage}
          />
        )}
        renderNewConversationMessage={() => NewConversationMessage}
        onSelectOption={handleConfirmation}
        onSendClick={handleSendClick}
        waiting={processing}
      />
      <Dialog open={tableViewerData.length > 0} onClose={handleTableViewerClose}>
        <JsonGrid data={tableViewerData} />
      </Dialog>
      <Dialog open={ragMngId >= 0} onClose={handleRagManagementClose} fullScreen>
        <DialogTitle>
          <Grid container>
            <Grid item flexGrow={1}>
              RAG Management
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  handleRagManagementClose(ragMngId);
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          {session && (
            <Grid container>
              <Typography variant="subtitle1">{session.title}</Typography>
            </Grid>
          )}
        </DialogTitle>
        <DialogContent>
          <AIRAGKnowledgeManager
            message={messages?.find((msg) => msg.aiChatMessageId == ragMngId)}
            knowledgeType="EXAMPLE"
            question={session?.title ?? ''}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={jsonViewerData} onClose={handleJsonViewerClose}>
        <DialogTitle>Prompt</DialogTitle>
        <DialogContent>
          <SyntaxHighlighter language="json" style={a11yDark} showLineNumbers wrapLongLines>
            {jsonViewerData}
          </SyntaxHighlighter>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default connector(AISessionMessages);
