import { Grid, InputBase } from '@mui/material';
import './chatbox.scss';
import { MoreVert, Send } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';
import { useRef, KeyboardEvent, useState } from 'react';
import { ChatBoxInputType, ChatBoxOptionStruct } from './chatbox.types';
import ChatboxOptionsInput from './ChatboxOptionsInput';
import ChatboxNameSearchForm from './chatboxNameSearchForm';

export interface ChatBoxProps {
  conversationHeaderList: any[];
  hideConversationList?: boolean;
  messages: any[];
  currentConversationIndex?: number;
  sendDisabled?: boolean;
  waiting?: boolean;

  inputType?: ChatBoxInputType;
  inputClassName?: string;
  inputPlaceholder?: string;
  selectOptions?: ChatBoxOptionStruct[];
  /* eslint-disable */
  renderWelcomeMessage?: () => JSX.Element;
  renderWaitingMessage?: () => JSX.Element;
  renderNewConversationMessage?: () => JSX.Element;

  renderConversationHeader?: (conversationHeader: any, index: number, isCurrent: boolean) => JSX.Element;
  renderSideBarFooter?: () => JSX.Element;
  renderSideBarHeader?: () => JSX.Element;

  renderChatHeader?: (conversationHeader?: any) => JSX.Element;
  renderMessage?: (message: any, mIndex: number) => JSX.Element;
  renderAfterMessageList?: () => JSX.Element;

  onSendClick?: (messageBody: string, currentConversationIndex?: number) => void;

  onSelectOption?: (name: string, currentConversationIndex?: number) => void;

  onSearch?: (query: string) => Promise<string[]>;
  typingMenu?: any[];
  // onConversationClick?: (conversationIndex: number) => void;
  /* eslint-enable */
}

export default function ChatBox({
  renderSideBarHeader,
  renderConversationHeader,
  renderSideBarFooter,
  renderWelcomeMessage,
  renderWaitingMessage,
  renderNewConversationMessage,
  renderMessage,
  renderAfterMessageList,
  renderChatHeader,

  onSendClick,
  onSelectOption,
  typingMenu,

  onSearch,

  hideConversationList,
  currentConversationIndex,
  conversationHeaderList,
  messages,
  sendDisabled,
  waiting,

  inputType = 'TEXT',
  inputClassName,
  inputPlaceholder,
  selectOptions = [
    { name: 'Yes', color: 'success' },
    { name: 'No', color: 'error' },
  ],
}: ChatBoxProps) {
  // const [message, setMessage] = useState<string>();
  const [searchStr, setSearchStr] = useState<string>();
  const [searching, setSearching] = useState(false);
  const inputBoxRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSendClick = () => {
    const inputValue = inputRef.current?.value;
    if (inputValue && !sendDisabled && onSendClick) {
      onSendClick(inputValue, currentConversationIndex);
      inputRef.current.value = '';
    }
  };
  const handleSelectOption = (name: string) => {
    if (onSelectOption) onSelectOption(name, currentConversationIndex);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key == 'Enter') {
      handleSendClick();
      e.preventDefault();
    }

    const isSearching = searching || e.key == '@';
    if (isSearching !== searching) {
      setSearching(isSearching);
    }
    // if (e.key == ' ') {
    //   isSearching = false;
    //   setSearching(false)
    // }
  };
  const handleChange = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (searching) {
      try {
        const caretPos = e.currentTarget.selectionStart;
        const fullText = e.currentTarget.value.substring(0, caretPos ?? undefined);
        const searchStart = fullText.lastIndexOf('@');
        if (searchStart >= 0) {
          if (searchStart < fullText.length - 2) {
            setSearchStr(fullText.substring(searchStart + 1));
          }
        } else {
          setSearching(false);
          setSearchStr('');
        }
      } catch {
        // debug
      }
    }
  };

  const handleChooseName = (choice: string) => {
    if (inputRef.current) {
      const firstPart = inputRef.current?.value.substring(0, inputRef.current?.selectionStart ?? undefined);
      const lastPart =
        (inputRef.current?.selectionStart ?? 0) < (inputRef.current?.selectionEnd ?? 0)
          ? inputRef.current?.value.substring(inputRef.current?.selectionEnd ?? 0)
          : '';
      const searchStart = firstPart.lastIndexOf('@');

      if (searchStart >= 0) {
        inputRef.current.value = firstPart.substring(0, searchStart) + ' ' + choice + ' ' + lastPart;
        inputRef.current.selectionStart = searchStart + choice.length + 2;
        inputRef.current.focus();
      }
    }
    setSearching(false);
    setSearchStr('');
  };
  const reverseMessages = [...messages].reverse();
  const reverseHeaders = [...conversationHeaderList].reverse();

  return (
    <Grid container className="chatbox-main-container">
      <Grid container className="chatbox-chat-wrapper" flexDirection="column">
        {renderChatHeader && (
          <Grid item className="chatbox-chat-header" p={2} mb={2}>
            {renderChatHeader(currentConversationIndex ? conversationHeaderList[currentConversationIndex] : undefined)}
          </Grid>
        )}
        {currentConversationIndex === undefined && renderWelcomeMessage ? (
          <>
            {renderWelcomeMessage()}
            {waiting && renderWaitingMessage && renderWaitingMessage()}
          </>
        ) : (
          <Grid item className="chatbox-conversation-box" flexGrow={1}>
            <Grid container flexDirection="row">
              <div className="scrollbox align-bottom">
                {waiting && renderWaitingMessage && renderWaitingMessage()}
                {reverseMessages.map((message, mIndex) => {
                  if (renderMessage) return renderMessage(message, mIndex);
                })}
                {reverseMessages.length === 0 && renderNewConversationMessage && renderNewConversationMessage()}
              </div>
            </Grid>
          </Grid>
        )}
        {renderAfterMessageList && (
          <Grid item className="chatbox-conversation-box">
            {renderAfterMessageList()}
          </Grid>
        )}
        {searching && (
          <ChatboxNameSearchForm
            onSearch={onSearch}
            onChoose={handleChooseName}
            anchorEl={inputBoxRef.current}
            query={searchStr ?? ''}
          />
        )}

        <Grid item className={`chatbox-chat-input${inputClassName ? ' ' + inputClassName : ''}`} p={1}>
          <Grid container alignItems="center">
            {typingMenu && (
              <Grid item>
                <IconButton size="small">
                  <MoreVert />
                </IconButton>
              </Grid>
            )}
            <Grid item flexGrow={1}>
              {inputType === 'TEXT' && (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid flexGrow={1} pl={2}>
                    <InputBase
                      placeholder={inputPlaceholder ?? 'Type a message'}
                      fullWidth
                      multiline
                      disabled={sendDisabled}
                      maxRows={5}
                      tabIndex={1}
                      inputRef={inputRef}
                      inputProps={{
                        onKeyDown: handleKeyDown,
                        onChange: handleChange,
                      }}
                      ref={inputBoxRef}
                    />
                  </Grid>
                  <Grid justifyItems="flex-end" style={{ display: 'flex' }}>
                    <Button color="primary" onClick={handleSendClick} disabled={sendDisabled}>
                      <Send />
                    </Button>
                  </Grid>
                </Grid>
              )}
              {inputType === 'SELECT' && (
                <Grid container justifyContent="center">
                  <ChatboxOptionsInput
                    options={selectOptions}
                    caption={inputPlaceholder}
                    onSelectOption={handleSelectOption}
                    disabled={waiting}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!hideConversationList && (
        <Grid container className="cahtbox-sidebar" md={3} xl={2} sm={0}>
          <Grid className="chatbox-sidear-header" p={1} pt={2}>
            {renderSideBarHeader ? renderSideBarHeader() : ''}
          </Grid>
          <Grid className="chatbox-sidebar-body">
            <div className="scrollbox">
              {reverseHeaders.map((conversationHeader, index) => {
                const cIndex = conversationHeaderList.length - index - 1;
                return renderConversationHeader ? (
                  renderConversationHeader(conversationHeader, cIndex, cIndex === currentConversationIndex)
                ) : (
                  <></>
                );
              })}
            </div>
          </Grid>
          <Grid className="chatbox-sidebar-footer" p={1}>
            {renderSideBarFooter ? renderSideBarFooter() : ''}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
