import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { AppActions } from './ActionTypes';
import { authReducer } from '../reducers/auth.reducer';
import { couriersReducer } from '../reducers/couriers.reducer';
import { customersReducer } from '../reducers/customers.reducer';
import { tripsReducer } from '../reducers/trips.reducer';
import { deliveryOrdersReducer } from '../reducers/deliveryOrders.reducer';
import { toastReducer } from '../reducers/toast';
import { warehousesReducer } from '../reducers/warehouses.reducer';
import { shippersReducer } from '../reducers/shippers.reducer';
import { NotificationSettingsReducer } from '../reducers/notificationSystem.reducer';
import { customersDeliveryOrdersReducer } from '../reducers/customersDeliveryOrders.reducer';
import { timeReducer } from '../reducers/time.reducer';
import { aiReducer } from '../reducers/ai.reducer';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const rootReducer = combineReducers({
  auth: authReducer,
  couriers: couriersReducer,
  customers: customersReducer,
  trips: tripsReducer,
  deliveryOrders: deliveryOrdersReducer,
  customersDeliveryOrders: customersDeliveryOrdersReducer,
  shippers: shippersReducer,
  toast: toastReducer,
  warehouses: warehousesReducer,
  notificationSettings: NotificationSettingsReducer,
  time: timeReducer,
  ai: aiReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)),
);
