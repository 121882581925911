import {
  CSVLine,
  CustomerLocationOrder,
  DeliveryOrder,
  PackageGroupPODinfo,
  DeliveryOrderRequest,
  PackageGroupEditStruct,
  PackageGroup,
} from '../store/config/types/deliveryOrders.types';
import axios from './axios';

async function fetchDeliveryOrders(date?: string): Promise<DeliveryOrder[] | undefined> {
  const query = encodeURI('/delivery_orders' + (date ? `?date=${date}` : ''));
  const res = await axios.get(query);
  return res.data.data;
}

async function fetchCustomersOrders(
  shipperId: number,
  date?: string | { from: string; to: string },
  fetchOnlyUnassign: boolean = false,
  shipVia: string = 'DEFAULT',
  fulfillment: boolean = false,
  orderDate?: string,
): Promise<CustomerLocationOrder[] | undefined> {
  const queries: string[] = [`shipVia=${shipVia}`, `fulfillment=${fulfillment ? 'true' : 'false'}`];
  if (date) {
    if (typeof date === 'string') queries.push('date=' + date);
    else {
      date.from && queries.push('from=' + date.from);
      date.to && queries.push('to=' + date.to);
    }
  }
  if (orderDate) queries.push('orderDate=' + orderDate);

  if (fetchOnlyUnassign) queries.push('unassigned=1');
  const res = await axios.get(encodeURI(`/delivery_orders/shipper/${shipperId}?${queries.join('&')}`));
  return res.data.data;
}

async function fetchUnassignedOrders(date: string): Promise<CustomerLocationOrder[] | undefined> {
  const res = await axios.get(encodeURI(`/trips2/unassigned?date=${date}`));
  return res.data.data;
}

async function fetchCustomerOrderDetais(
  warehouseId: number,
  customerId: number,
  deliveryExpectedAt: Date,
): Promise<CustomerLocationOrder[] | undefined> {
  const res = await axios.get(`/delivery_orders/details/${warehouseId}/${customerId}/${deliveryExpectedAt}`);
  return res.data.data;
}
async function fetchPackageGroupPODinfo(packageGroupId: number): Promise<PackageGroupPODinfo[]> {
  const res = await axios.get(`/package_groups/${packageGroupId}/pod-info`);
  return res.data.data;
}
async function createDeliveryOrder(
  createDeliveryOrderRequest: DeliveryOrderRequest,
): Promise<DeliveryOrder | undefined> {
  const res = await axios.post('/delivery_orders/package_groups', createDeliveryOrderRequest);
  return res.data.data;
}

async function parseCSVToPackages(formData: FormData): Promise<CSVLine[] | undefined> {
  const res = await axios.post('/delivery_orders/csv', formData);
  return res.data.data;
}

async function getDeliveryOrderOfPackageGroup(packageGroupId: number): Promise<DeliveryOrder> {
  const res = await axios.get(`/delivery_orders/package_group/${packageGroupId}`);
  return res.data.data;
}
async function deletePackageGroups(packageGroupIds: number[]): Promise<number[]> {
  const res = await axios.delete('/package_groups/delete', { data: { packageGroupIds } });
  return res.data.data;
}

async function editPackageGroup(
  packageGroup: PackageGroup,
  packageGroupEditStruct: PackageGroupEditStruct,
): Promise<boolean> {
  const res = await axios.patch('/package_groups/' + packageGroup.packageGroupId, packageGroupEditStruct);
  return res.data.data;
}

async function changeDateofPackageGroups(packageGroupsIds: number[], date: Date) {
  const res = await axios.patch('/package_groups/change-date', { data: { packageGroupsIds, date } });
  return res.data.data;
}

async function getTripStopPackageGroupsDetails(tripStopId: number) {
  const res = await axios.get(`/package_groups/details/${tripStopId}`);
  return res.data.data;
}

export const deliveryOrdersService = {
  fetchDeliveryOrders,
  fetchCustomersOrders,
  fetchUnassignedOrders,
  fetchCustomerOrderDetais,
  createDeliveryOrder,
  parseCSVToPackages,
  getDeliveryOrderOfPackageGroup,
  editPackageGroup,
  deletePackageGroups,
  fetchPackageGroupPODinfo,
  changeDateofPackageGroups,
  getTripStopPackageGroupsDetails,
};
