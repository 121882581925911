export default function MotiveLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="86" height="20" viewBox="0 0 86 20" fill="none">
      <path
        d="M47.0964 16.3398V8.19564H50.4242V4.89571H47.0964V0H43.3087V4.89299H41.1578V8.19293H43.3101V19.7197H50.4242V16.333L47.0964 16.3398Z"
        fill="black"
      ></path>
      <path
        d="M14.6003 4.89116H10.0753L3.31154 16.3393H0V19.7259H5.28792L10.8221 10.3504V19.7192H15.2862L20.7945 10.3233V19.7192H24.5823V4.89116H20.037L14.6003 14.0866V4.89116Z"
        fill="black"
      ></path>
      <path d="M55.8138 4.89116H52.0261V19.7192H55.8138V4.89251V4.89116Z" fill="black"></path>
      <path d="M55.8099 0H52.0317V3.3785H55.8099V0Z" fill="black"></path>
      <path
        d="M33.4728 4.71886H33.4444C29.3713 4.71886 26.1003 8.10547 26.1003 12.3049C26.1003 16.5043 29.3713 19.8909 33.4444 19.8909H33.4728C37.5473 19.8909 40.7885 16.4718 40.7885 12.3049C40.7885 8.13797 37.5473 4.71886 33.4728 4.71886ZM33.4728 16.0979H33.4444C31.4369 16.0979 29.888 14.4033 29.888 12.3049C29.888 10.2066 31.4369 8.51191 33.4444 8.51191H33.4728C35.4235 8.51191 37.0049 10.235 37.0049 12.3049C37.0022 14.4005 35.4533 16.0952 33.4728 16.0952V16.0979Z"
        fill="black"
      ></path>
      <path
        d="M79.2198 16.5222C80.7422 16.5222 81.6902 15.6323 81.9482 14.8547H85.7364C85.5928 16.0915 83.9561 19.8885 79.217 19.8885C74.9385 19.8885 71.7773 16.6672 71.7773 12.2944C71.7773 8.00958 75.0238 4.70155 79.1012 4.70155C82.9782 4.70155 86 8.09492 86 12.2375C86 12.6682 85.9715 13.072 85.9431 13.3591H75.6619C75.9178 15.1717 77.0095 16.5222 79.2198 16.5222ZM75.7784 10.6552H82.2721C81.8657 9.16515 80.8649 8.06652 79.113 8.06652C77.2772 8.06652 76.2084 9.13126 75.7826 10.6552H75.7784Z"
        fill="black"
      ></path>
      <path
        d="M68.4504 4.89543L64.8412 14.5947L61.2321 4.89543H57.1819L62.9109 19.7208H66.7229L72.4856 4.90356H68.4504C68.4504 4.90356 68.4504 4.89407 68.4504 4.89543Z"
        fill="black"
      ></path>
    </svg>
  );
}
