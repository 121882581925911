import { Switch } from '@mui/material';
import { AIAgent } from '../Prospero/prospero.types';

import { useState } from 'react';

interface AIAgentActiveToggleProps {
  agent: AIAgent;
  /* eslint-disable */
  onOperation?: (operation: string, agent: AIAgent) => Promise<any>;
  /* eslint-enable */
}
export default function AIAgentActiveToggle({ agent, onOperation }: AIAgentActiveToggleProps) {
  const [executing, setExecuting] = useState(false);

  const handleActiveChange = (e: any) => {
    if (onOperation && !executing) {
      setExecuting(true);
      onOperation(e.target.checked ? 'active' : 'deactive', agent).finally(() => {
        setExecuting(false);
      });
    }
  };

  return (
    <Switch
      color="success"
      onClick={(e) => e.stopPropagation()}
      checked={agent.active}
      onChange={handleActiveChange}
      disabled={executing}
    />
  );
}
