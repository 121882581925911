/* eslint-disable */
import { Box, Collapse, Fab, Grid, IconButton, Slide, Typography, Zoom } from '@mui/material';
import { AiAgentIcon, ProsperoIcon } from '../../assets/images';
import { useEffect, useRef, useState } from 'react';
import { AddBox, Close, Menu } from '@material-ui/icons';
import './AIFloatChatbox.scss';
import AISessionList from './AISessionList';
import ChatBox from '../ChatBox/ChatBox';
import { RootState } from '../../store/config/types';
import { ConnectedProps, connect } from 'react-redux';
import ProsperoService from '../../services/prospero.service';
import {
  AIAgentSetupSession,
  AIChatInputMode,
  AIChatSession,
  AIChatSessionType,
  AIMessageStruct,
} from '../../screens/Prospero/prospero.types';
import ProsperoMessage from '../../screens/Prospero/ProsperoMessage';
import { aiCreateNewSession, aiFetchMoreSessions } from '../../store/actions/ai.actions';
import { ThunkDispatch } from 'redux-thunk';
import AISessionMessages from './AISessionMessages';
import { hasPermission } from '../../utils/permissions';
// import { ThunkDispatch } from 'redux-thunk';

interface AIFloatChatboxProps {
  visible?: boolean;
  open?: boolean;
  onOpen?: () => void;
}

const mapStateToProps = (state: RootState, props: AIFloatChatboxProps) => ({
  auth: state.auth,
  ai: state.ai,
  ...props,
});
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  createSession: (
    title?: string,
    message?: AIMessageStruct,
    onComplete?: (s: AIChatSession) => void,
    handleStatusChange?: (chatStatus: string) => void,
    handleStreamMessage?: (streamStr: string) => void,
    handleResponseRecieve?: (response: string) => void,
  ) =>
    dispatch(
      aiCreateNewSession(title, message, onComplete, handleStatusChange, handleStreamMessage, handleResponseRecieve),
    ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

interface DragInfo {
  startY: number;
  startX: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

function AIFloatChatbox({ auth, ai, visible, open, onOpen, createSession }: PropsFromRedux) {
  const [chatOpen, setChatOpen] = useState(false);
  const [chatboxVisible, setChatboxVisibility] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [isFullscreen, setFullscreen] = useState(false);
  const [hasAIPermission, setHasAIPermission] = useState(false);
  const chatboxRef = useRef<HTMLElement>(null);
  const [posY, setPosY] = useState(16);
  const [posX, setPosX] = useState(0);
  const dragInfo = useRef<DragInfo>({ startY: 0, startX: 0 });

  const [currentSession, setCurrentSession] = useState<AIChatSession>();

  useEffect(() => {
    if (auth?.account) {
      setHasAIPermission(hasPermission(auth.account, ['ai.prospero']));
    }
  }, [auth]);

  useEffect(() => {
    setChatOpen(open ?? false);
    if (sideBarOpen && !open) {
      setSideBarOpen(false);
      setFullscreen(false);
    }
  }, [open]);

  function openChatbox() {
    setChatOpen(true);
    setChatboxVisibility(true);
    if (onOpen) {
      onOpen();
    }
  }
  function handleSessionChange(session: AIChatSession): void {
    setCurrentSession(session);
    setSideBarOpen(false);
  }

  function handleCreateSession() {
    createSession(undefined, undefined, (s) => {
      setCurrentSession(s);
    });
  }

  if (!visible || !hasAIPermission) return <></>;

  return (
    <Box className={`ai-float-chatbox${isFullscreen ? ' fullscreen' : ''}`} ref={chatboxRef}>
      <Slide
        in={chatOpen}
        direction="up"
        container={chatboxRef.current}
        onAnimationEnd={() => {
          setChatboxVisibility(chatOpen);
        }}
      >
        <Grid
          container
          flexDirection="row"
          wrap="nowrap"
          className={`chatbox-container${chatboxVisible ? '' : ' hidden'}`}
        >
          <Grid item className={`chatbox-menu${sideBarOpen ? ' open' : ''}`}>
            <Collapse in={sideBarOpen} orientation="horizontal" style={{ maxHeight: '100%', overflow: 'hidden' }}>
              <Grid
                container
                flexDirection="column"
                className="chatbox-session-side"
                p={1}
                style={{ maxHeight: '100%', overflow: 'hidden', position: 'relative' }}
              >
                <Grid item style={{ height: 32, width: '100%' }} alignItems="center">
                  <Grid container alignItems="center" pl={2}>
                    <Grid item flexGrow={1}>
                      <Typography variant="body1">Sessions</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSideBarOpen(false);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item p={1} flexGrow={1} style={{ width: '100%' }}>
                  <div style={{ height: '100%', width: '100%' }}>
                    <AISessionList
                      onSessionClick={handleSessionChange}
                      currentSessionId={currentSession?.aiChatSessionId}
                    />
                  </div>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid item>
            <Grid container flexDirection="column" className="chatbox-chat-side">
              <Grid item>
                <Grid container p={1} className="chat-title" wrap="nowrap">
                  <Grid item flexGrow={1}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item pr={1}>
                        <Grid container alignItems="center" flexWrap="nowrap">
                          {!sideBarOpen && (
                            <IconButton
                              size="small"
                              onClick={() => {
                                setSideBarOpen(true);
                              }}
                            >
                              <Menu />
                            </IconButton>
                          )}
                          <IconButton size="small" onClick={handleCreateSession}>
                            <AddBox />
                          </IconButton>
                          <img
                            src={currentSession?.type === AIChatSessionType.AGENT ? AiAgentIcon : ProsperoIcon}
                            style={{ width: 24, height: 24 }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item flexGrow={1} textOverflow={'ellipsis'}>
                        <Typography
                          variant="subtitle1"
                          noWrap
                          textOverflow="ellipsis"
                          style={{ color: 'whitesmoke', width: '320px' }}
                        >
                          {currentSession?.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => setChatOpen(false)} size="small">
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item flexGrow={1} p={2} position="relative">
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                  <AISessionMessages session={currentSession} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
      <Zoom in={!chatOpen}>
        <Fab
          color="default"
          style={{ position: 'absolute', right: posX, bottom: posY, backgroundColor: '#555' }}
          variant="circular"
          onClick={openChatbox}
          onDragStart={(e) => {
            dragInfo.current.startY = e.screenY;
            dragInfo.current.startX = e.screenX;
          }}
          onDragEnd={(e) => {
            setPosY(Math.max(posY + dragInfo.current.startY - e.screenY, 0));
            setPosX(Math.max(posX + dragInfo.current.startX - e.screenX, 0));
          }}
        >
          <img src={ProsperoIcon} style={{ width: 50, height: 50 }} />
        </Fab>
      </Zoom>
    </Box>
  );
}
export default connector(AIFloatChatbox);
