import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import CumulativeOperationRunner, { ExecutableOperation } from '../../utils/CumulativeOperationRunner';
import { useEffect, useRef, useState } from 'react';
import { PlayCircleOutline } from '@material-ui/icons';
import { styled } from '@mui/material/styles';

const CustomProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  '& .MuiLinearProgress-bar1Determinate': {
    backgroundColor: theme.palette.success.main, // Green for the progress (value)
  },
  '& .MuiLinearProgress-bar2Buffer': {
    backgroundColor: theme.palette.error.main, // Red for the buffer (valueBuffer)
  },
}));

/* eslint-disable */
interface CumulativeOperationDialogProps {
  title?: string;
  message?: string;
  onComplete?: (stopped?: boolean) => void;
  onCancel: (stopped?: boolean) => void;
  open: boolean;
  operations: ExecutableOperation[];
}
/* eslint-enable */
export default function CumulativeOperationDialog({
  open,
  title,
  message,
  operations,
  onCancel,
  onComplete,
}: CumulativeOperationDialogProps) {
  const [executing, setExecuting] = useState(false);
  const [stopping, setStopping] = useState(false);
  const failed = useRef<number>(0);
  const succeeded = useRef<number>(0);
  const [total, setTotal] = useState(1);
  const [id, setId] = useState<any>();
  const executor = useRef<CumulativeOperationRunner>();

  useEffect(() => {
    if (!executor.current) {
      executor.current = new CumulativeOperationRunner(
        {
          onCompleted: (stopped) => {
            if (onComplete) onComplete(stopped);
            handleCancel();
          },
          onCallFailed: () => {
            failed.current++;
          },
          onCallSuccess: () => {
            succeeded.current++;
          },
          onStartExecution: (index) => setId(index),
        },
        [],
      );
    }
    if (open) {
      setExecuting(false);
      setStopping(false);
      failed.current = 0;
      succeeded.current = 0;
      setTotal(Math.max(1, operations.length));
    }
    executor.current?.setOperation(operations);
  }, [open, operations]);
  function handleStop() {
    setStopping(true);
    executor.current?.stop();
  }
  function handleCancel() {
    if (!executing && onCancel) onCancel();
  }
  async function handleExecution() {
    setExecuting(true);
    await executor.current?.execute();
  }

  return (
    <Dialog open={open} onClose={!executing ? handleCancel : undefined}>
      <DialogTitle>{title ?? 'Cumulative Operations'}</DialogTitle>
      <DialogContent style={{ width: 450 }}>
        <Typography variant="subtitle1">
          {executing ? (
            <>
              Processing <span style={{ fontWeight: 'bold' }}>{id}</span>
            </>
          ) : (
            message
          )}
        </Typography>
        <CustomProgressBar
          variant="buffer"
          value={(succeeded.current * 100) / total}
          valueBuffer={((succeeded.current + failed.current) * 100) / total}
          style={{ minWidth: 300 }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={executing ? handleStop : handleCancel} disabled={stopping}>
          {executing ? 'Stop' : 'Cancel'}
        </Button>
        <Button
          variant={executing ? 'text' : 'contained'}
          color={'success'}
          onClick={handleExecution}
          disabled={executing}
        >
          {executing ? (
            <CircularProgress size={20} style={{ marginRight: 8 }} />
          ) : (
            <PlayCircleOutline style={{ marginRight: 8 }} />
          )}
          {stopping ? 'Stopping' : executing ? 'Executing' : 'Start'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
