/* eslint-disable */
import { useEffect, useState } from 'react';
import {
  Courier,
  Customer,
  LatLng,
  TripArrangeStop,
  TripArrangeStopPackage,
  TripOfferUpdateRequest,
  TripWithStopsDetails,
} from '../../store/config/types';
import { DeliveryServiceProvider } from '../../store/config/types/deliveryServiceProvider.types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { TripOptimizingMethod } from '../../store/config/enums/trips.enum';
import moment from 'moment-timezone';
import { TripsChangeStructure } from './TripChangeHandlers';
import * as TripChangeHandler from './TripChangeHandlers';
import { InteractionTypeEnum } from '../../store/config/enums/interaction.enum';
import tripsService from '../../services/trips.service';
import { ThirdPartyDeliveryConfirm } from '../../components/ThirdPartyDeliveryDialog/ThirdPartyDeliveryDialogs';
import { CircularProgress, Grid, IconButton } from '@mui/material';
import { Cancel, Save } from '@material-ui/icons';
import Map from '../../components/Map/Map';
import TripDndTimeline from '../../components/TripsDnd/TripDndTimeline';

export interface TripEditViewProps {
  couriers: Courier[];
  availableCourierIds: number[];
  deliveryServiceProviders: DeliveryServiceProvider[];
  customers: Customer[];
  warehouses: Warehouse[];
  trips: TripWithStopsDetails[];
  unassigned: TripArrangeStop[];
  date: string;
  timezone?: string;
  defaultOptimizingMethod: TripOptimizingMethod;
  allTripsIsNew?: boolean;
  onAfterSave?: () => void;
  onCancel?: () => void;
}

export default function TripEditView({
  trips,
  couriers,
  availableCourierIds,
  customers,
  deliveryServiceProviders,
  unassigned,
  warehouses,
  date,
  timezone,
  defaultOptimizingMethod,
  allTripsIsNew,
  onAfterSave,
  onCancel,
}: TripEditViewProps) {
  const [arrangedTrips, setArrangedTrips] = useState<TripWithStopsDetails[] | undefined>(undefined);
  const [unAssignStops, setUnAssignStops] = useState<TripArrangeStop[]>([]);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<number[]>([]);
  const [originalUnAssignStops, setOriginalUnAssignStops] = useState<TripArrangeStop[]>([]);
  const [originalTrips, setOriginalTrips] = useState<TripWithStopsDetails[]>([]);
  const [saving, setSaving] = useState(false);
  const [optimizing, setOptimizing] = useState(false);
  const [thirdPartyConfirmation, setThirdPartyConfirmation] = useState(false);
  const [mapBoundPoints, setMapBoundPoints] = useState<LatLng[]>([]);
  const [selectedStopId, setSelectedStopId] = useState<number>();

  const getWarehouseOfStop = (stop: TripArrangeStop): TripArrangeStop | undefined => {
    if (stop && stop.stopPackages) {
      const warehouseId =
        stop.warehouseId ??
        (originalTrips
          ? originalTrips[stop.tripIndex].warehouseId ?? originalTrips[stop.tripIndex].tripStops[0].placeInfo?.placeId
          : 0);
      const warehouse = warehouses.find((warehouse) => warehouse.warehouseId === warehouseId) as any;
      const timelineStart = moment
        .max(
          arrangedTrips?.length
            ? arrangedTrips?.reduce((minDate, trip) => {
                const arrival = moment(trip.tripStops[0].schedulerArrivalAt ?? trip.tripStops[0].routerArrivalAt);
                return minDate < arrival ? minDate : arrival;
              }, moment(`${date} 23:59:59`, 'YYYY-MM-DD HH:mm'))
            : moment(`${date} 08:00:00`, 'YYYY-MM-DD HH:mm'),
          moment(),
        )
        .toDate();

      if (warehouse) {
        const warehouseArrivalTime = moment(timelineStart, 'YYYY-MM-DD HH:mm');

        return {
          label: 'W',
          name: warehouse.name,
          locationId: warehouse.locationId,
          location: warehouse.location,
          routerArrivalAt: warehouseArrivalTime.toDate(),
          routerDepartureAt: warehouseArrivalTime.add(5, 'minutes').toDate(),
          placeInfo: { placeId: warehouse.warehouseId, placeType: 'WAREHOUSE' },
          stopIndex: 0,
          tripIndex: stop.tripIndex,
          tripStopId: 0,
        } as TripArrangeStop;
      }
    }
    return undefined;
  };

  const startSaving = () => {
    if (saving) return;
    setSaving(true);
    setThirdPartyConfirmation(true);
  };

  const isDifferentTrip = (trip1: TripWithStopsDetails | undefined, trip2: TripWithStopsDetails | undefined) => {
    return (
      !(trip1 && trip2) ||
      trip1.isRoundTrip !== trip2.isRoundTrip ||
      !moment(trip1.startsAt).isSame(trip1.startsAt, 'minute') ||
      trip1.tripStops?.length !== trip2.tripStops.length ||
      trip1.tripStops?.some((stop1, stopIndex) => {
        const stop2 = trip2.tripStops[stopIndex];
        return (
          stop1.tripStopId !== stop2.tripStopId ||
          !moment(stop1.routerArrivalAt).isSame(stop2.routerArrivalAt, 'second')
        );
      })
    );
  };

  const removeFailedThirdPartyAndSave = (trips: TripWithStopsDetails[]) => {
    setSaving(true);
    const changeChain: TripsChangeStructure[] = [];
    trips
      .filter((trip) => trip.editable)
      .forEach((trip) => {
        if (trip.dspStatus !== 'SUBMITTED') {
          const tripToDelete = arrangedTrips?.find((aTrip) => aTrip.dspRefrenceId === trip.dspRefrenceId);
          if (tripToDelete !== undefined) {
            changeChain.push({
              changeType: TripChangeHandler.TripsChangeType.REMOVE_TRIP,
              sourceTripId: tripToDelete?.tripId,
            });
          }
        }
      });
    const chainChangeResult = TripChangeHandler.handleChangeChain(
      JSON.parse(JSON.stringify(arrangedTrips)),
      JSON.parse(JSON.stringify(unAssignStops)),
      changeChain,
      getWarehouseOfStop,
    );

    if (chainChangeResult.ok) {
      saveTrips(chainChangeResult.trips ?? [], chainChangeResult.unAssignStops ?? []);
    }
  };

  const saveTrips = (tripsToSave: TripWithStopsDetails[], unAssignStopsToSave: TripArrangeStop[]) => {
    const tripOffers: TripOfferUpdateRequest[] = [];
    const unassignedStopsIds = unAssignStopsToSave
      .filter((stop) => (stop.tripStopId ?? 0) > 0)
      .map((stop) => stop.tripStopId ?? 0);
    const deletedTripsIds = originalTrips
      .filter(
        (oTriop) =>
          (oTriop.tripId ?? -1) > 0 &&
          tripsToSave &&
          tripsToSave?.findIndex((aTrip) => (aTrip.tripId ?? -1) === (oTriop.tripId ?? 0)) < 0,
      )
      .map((trip) => trip.tripId ?? 0);
    const edittedTrips = tripsToSave
      ? tripsToSave
          .filter((filteredTrip) => {
            if (allTripsIsNew) return filteredTrip.tripStops?.length > 1;
            const oTrip = originalTrips?.find((trip) => trip.tripId === filteredTrip.tripId);
            if (filteredTrip.tripId && filteredTrip.tripStops?.length > 1) {
              tripOffers.push({ tripId: filteredTrip.tripId, courierId: filteredTrip.courierId });
            }
            return filteredTrip.tripStops?.length > 1 && isDifferentTrip(oTrip, filteredTrip);
          })
          .map((trip) => {
            trip.tripStops[0].stopPackages = trip.tripStops
              .filter((stop, stopIndex) => stopIndex > 0)
              .reduce((allPackage, stop) => {
                return [
                  ...allPackage,
                  ...(stop.stopPackages ?? []).map(
                    (stopPackage) =>
                      ({
                        ...stopPackage,
                        interactionType: InteractionTypeEnum.LOAD,
                        tripStopId: stop.tripStopId,
                      } as TripArrangeStopPackage),
                  ),
                ];
              }, [] as TripArrangeStopPackage[]);
            if (allTripsIsNew) trip.tripId = 0;
            return {
              ...trip,
              tripStops: trip.tripStops.map((stop, stopIndex) => ({
                ...stop,
                routePoints:
                  stopIndex === 0
                    ? []
                    : tripsService.LocationsPathService.getPointsOfLocations([
                        trip.tripStops[stopIndex - 1].location,
                        trip.tripStops[stopIndex].location,
                      ]),
              })),
            };
          })
      : [];
    // console.log(edittedTrips, deletedTripsIds, unassignedStopsIds);

    tripsService.updateArrangedTrip(edittedTrips, deletedTripsIds, unassignedStopsIds, tripOffers).then((result) => {
      if (result) {
        if (onAfterSave) onAfterSave();
      }
    });
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const handleChange = (changeStructs: TripsChangeStructure[]): boolean => {
    const chainChangeResult = TripChangeHandler.handleChangeChain(
      JSON.parse(JSON.stringify(arrangedTrips)),
      JSON.parse(JSON.stringify(unAssignStops)),
      changeStructs,
      getWarehouseOfStop,
    );

    if (chainChangeResult.ok) {
      setUnAssignStops(chainChangeResult.unAssignStops ?? []);
      setArrangedTrips((chainChangeResult.trips ?? []).filter((trip) => trip.tripStops.length > 1));
      setOptimizing(true);
      tripsService
        .optimizeTrips(
          (chainChangeResult.trips ?? [])
            .filter((trip) => trip.tripStops.length > 1)
            .map((trip) => ({
              ...trip,
              optimizingMethod: TripOptimizingMethod.GOOGLE_DIRECTIONS,
              doNotOptimize: true,
            })),
          false,
          defaultOptimizingMethod,
        )
        .then((trips) => {
          setArrangedTrips(trips);
          // TODO: here is suitable for route manipulate
        })
        .finally(() => {
          setOptimizing(false);
        });
    }
    return chainChangeResult.ok;
  };

  function optimizeArrangedTrips() {
    if (arrangedTrips) {
      setOptimizing(true);
      tripsService
        .optimizeTrips(arrangedTrips, false, defaultOptimizingMethod)
        .then((trips) => setArrangedTrips(trips))
        .finally(() => {
          setOptimizing(false);
        });
    }
  }

  function handleCustomerClick(customerId: number, keyStatus?: any): void {
    const customer = customers.find((c) => c.customerId === customerId);
    if (customer) {
      if (keyStatus?.ctrl || keyStatus?.shift) {
        const newIds = [...selectedCustomerIds];
        const idx = selectedCustomerIds.findIndex((id) => id === customerId);
        if (idx < 0) {
          newIds.push(customerId);
        } else {
          newIds.splice(idx, 1);
        }
        setSelectedCustomerIds(newIds);
      } else {
        setSelectedCustomerIds([customerId]);
      }
    }
  }

  function handleTripVisibilityOnMapChange(tripIndex: number, visible?: boolean) {
    const allTrips = [...(arrangedTrips ?? [])];
    if (tripIndex < allTrips.length) {
      allTrips[tripIndex].additionalData = { ...(allTrips[tripIndex].additionalData ?? {}), unvisibleOnMap: !visible };
      setArrangedTrips(allTrips);
    }
  }

  function handleAllTripsVisibilityChange(visible?: boolean) {
    const allTrips = [
      ...(arrangedTrips ?? []).map((t) => ({
        ...t,
        additionalData: { ...(t.additionalData ?? {}), unvisibleOnMap: !visible },
      })),
    ];
    setArrangedTrips(allTrips);
  }

  useEffect(() => {
    setUnAssignStops(unassigned);
    setArrangedTrips(trips ?? []);
    setOriginalTrips(JSON.parse(JSON.stringify(trips ?? [])));
    setOriginalUnAssignStops(JSON.parse(JSON.stringify(unassigned ?? [])));
    if (trips?.length || unassigned.length) {
      const allPointsOfVisibleTrips: LatLng[] = [];
      trips.forEach((trip) => {
        trip.tripStops?.forEach((stop) => {
          allPointsOfVisibleTrips.push({ lat: stop.location.latitude, lng: stop.location.longitude });
        });
      });
      unassigned.forEach((order) => {
        allPointsOfVisibleTrips.push({ lat: order.location.latitude, lng: order.location.longitude });
      });
      setMapBoundPoints(allPointsOfVisibleTrips);
    }
  }, [trips]);

  return (
    <div className="trips-dnd-full-page">
      {(arrangedTrips || unAssignStops) && (
        <>
          <div className="trips-dnd">
            <div className="trips-dnd-title-area">
              <Grid container>
                {!saving && (
                  <Grid flexGrow={1} textAlign="right">
                    <IconButton
                      size="small"
                      onClick={() => {
                        startSaving();
                      }}
                      disabled={saving || optimizing}
                    >
                      <Save style={{ color: saving ? 'gray' : 'white' }} />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        handleCancel();
                      }}
                      disabled={saving}
                    >
                      <Cancel style={{ color: 'white' }} />
                    </IconButton>
                  </Grid>
                )}
                {saving && (
                  <Grid flexGrow={1} textAlign="right">
                    <IconButton size="small">
                      <CircularProgress size={24} />
                    </IconButton>
                    <IconButton size="small">
                      <CircularProgress size={24} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </div>
            <div className="map-area">
              <Map
                trips={(arrangedTrips ?? []).filter((t) => !t.additionalData?.unvisibleOnMap)}
                warehouses={warehouses}
                customersLocations={unAssignStops.map((stop) => stop.location)}
                onCustomerClick={handleCustomerClick}
                boundPoints={mapBoundPoints}
                highlightStopId={selectedStopId}
                onStopClick={(stopId) => {
                  setSelectedStopId(stopId);
                }}
              />
            </div>
            <div className="chart-area">
              <TripDndTimeline
                onTripVisibilityOnMapChange={handleTripVisibilityOnMapChange}
                onAllTripsVisibilityoggle={handleAllTripsVisibilityChange}
                trips={arrangedTrips ?? []}
                unAssignStops={unAssignStops}
                customers={customers}
                selectedCusomerIds={selectedCustomerIds}
                warehouses={warehouses}
                couriers={couriers ?? []}
                availableCouriersIds={availableCourierIds}
                deliveryServiceProviders={deliveryServiceProviders}
                onReset={() => {
                  setArrangedTrips(JSON.parse(JSON.stringify(originalTrips?.filter((trip) => trip.tripId !== -1))));
                  setUnAssignStops(JSON.parse(JSON.stringify(originalUnAssignStops)));
                }}
                onBeforeChange={handleChange}
                onOptimizeLockChange={(tripIndex, value) => {
                  const trips: TripWithStopsDetails[] = JSON.parse(JSON.stringify(arrangedTrips));
                  trips[tripIndex].doNotOptimize = value;
                  setArrangedTrips(trips);
                }}
                onOptimizeClick={optimizeArrangedTrips}
                showCurrentTime={true}
                timezone={timezone}
                disableDnd={false}
                optimizing={optimizing}
                editMode={true}
              />
            </div>
          </div>
        </>
      )}

      <ThirdPartyDeliveryConfirm
        open={thirdPartyConfirmation}
        trips={arrangedTrips?.filter((trip) => (trip.editable ?? true) && trip.DSP)}
        customers={customers}
        onClose={() => {
          setThirdPartyConfirmation(false);
        }}
        onDeliveryCreationComplete={removeFailedThirdPartyAndSave}
      />
    </div>
  );
}
