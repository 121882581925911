import { LoginRequest, LoggedAccount } from '../store/config/types/auth.types';
import axios from './axios';

async function login({
  email,
  password,
  device = 'admin',
  messagingToken = '',
  loginType = 'SHIPPER',
  userType = 'SHIPPER_USER',
}: LoginRequest) {
  const res = await axios.post('/auth/login', { email, password, device, messagingToken, loginType, userType });

  return res.data;
}

async function logout() {
  const res = await axios.get('/auth/logout');
  return res.data;
}

async function getAccount(shipperUserId: number) {
  const res = await axios.get(`/shipper_users/${shipperUserId}`);
  return res.data;
}

async function updateAccount(shipperUserId: number, shipperData: LoggedAccount) {
  const res = await axios.patch(`/shippers/${shipperUserId}`, shipperData);
  return res.data;
}

async function getDataProviderToken(currentToken?: string) {
  const res = await axios.get(`/data-provider/token?currentToken=${currentToken ?? ''}`);
  return res.data;
}

export const authService = { login, logout, getAccount, updateAccount, getDataProviderToken };
