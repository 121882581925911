import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { AssemblyBuild } from '../../store/config/types';
import { Input, MoreVert, Sync } from '@material-ui/icons';
import { useRef, useState } from 'react';
import { AssemblyBuildStatus } from '../../store/config/enums/warehouseWork.enum';

interface WorkOrderActionProps {
  assemblyBuild: AssemblyBuild;
  // eslint-disable-next-line
  onAction?: (action: string, params: any) => void;
}
export default function WorkOrderAction({ assemblyBuild, onAction }: WorkOrderActionProps) {
  const [mnuOpen, setMnuOpen] = useState(false);
  const btnRef = useRef<HTMLElement>();
  const handleClick = (e: any) => {
    e.stopPropagation();
    btnRef.current = e.target;
    setMnuOpen(true);
  };
  const handleMnuClose = () => {
    // e.stopPropagation();
    setMnuOpen(false);
  };
  const handleMnuClick = (e: any, action: string, params: any) => {
    // e.stopPropagation();
    setMnuOpen(false);
    if (onAction) onAction(action, params);
  };
  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  const canSync = [AssemblyBuildStatus.BUILT].includes(assemblyBuild.status);
  const canPending = [AssemblyBuildStatus.INSUFFICIENT_INVENTORY, AssemblyBuildStatus.NEW].includes(
    assemblyBuild.status,
  );
  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu open={mnuOpen} onClose={handleMnuClose} anchorEl={btnRef.current} onClick={stopPropagation}>
        <MenuItem disabled={true}>AB#{assemblyBuild.assemblyBuildId}</MenuItem>
        {canSync && (
          <MenuItem
            onClick={(e) => handleMnuClick(e, 'sync-with-esm', { assemblyBuildId: assemblyBuild.assemblyBuildId })}
          >
            <ListItemIcon>
              <Sync color="primary" />
            </ListItemIcon>
            <ListItemText>Sync</ListItemText>
          </MenuItem>
        )}
        {canPending && (
          <MenuItem
            onClick={(e) => handleMnuClick(e, 'convert-to-pending', { assemblyBuildId: assemblyBuild.assemblyBuildId })}
          >
            <ListItemIcon>
              <Input color="primary" />
            </ListItemIcon>
            <ListItemText>Add to Build Queue</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
