/* eslint-disable */
import { useEffect, useState } from 'react';
import { RootState } from '../../store/config/types';
import { ConnectedProps, connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { setToast } from '../../store/actions/toast.actions';
import {
  aiAddMessageToSessions,
  aiCreateNewSession,
  aiFetchMoreSessions,
  aiFetchToSession,
  setAIDebugMode,
} from '../../store/actions/ai.actions';
import { Grid, Hidden, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import ScrollablePage from '../../components/ScrollablePage/ScrollablePage';
import AISessionSidebar from './AISessionSidebar';
import './AIPage.scss';
import PageHeader from '../../components/PageHeader/PageHeader';
import { AddBox, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { AIChatSession, AIChatSessionType, AIMessageStruct } from '../Prospero/prospero.types';
import { AiAgentIcon, ProsperoIcon } from '../../assets/images';
import ChatBox from '../../components/ChatBox/ChatBox';
import ProsperoMessage from '../Prospero/ProsperoMessage';
import AISessionMessages from '../../components/AIFloatChatbox/AISessionMessages';
import { hasPermission } from '../../utils/permissions';
import { useParams } from 'react-router-dom';
import ProsperoService from '../../services/prospero.service';

interface AIUrlParams {
  aiChatSessionId?: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    ai: state.ai,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
  fetchMoreSession: () => dispatch(aiFetchMoreSessions()),
  fetchToSession: (sessionId: number) => dispatch(aiFetchToSession(sessionId)),
  createSession: (
    title?: string,
    message?: AIMessageStruct,
    onComplete?: (s: AIChatSession) => void,
    handleStatusChange?: (chatStatus: string) => void,
    handleStreamMessage?: (streamStr: string) => void,
    handleResponseRecieve?: (response: string) => void,
  ) =>
    dispatch(
      aiCreateNewSession(title, message, onComplete, handleStatusChange, handleStreamMessage, handleResponseRecieve),
    ),

  setDebugMode: (debugMode?: boolean) => dispatch(setAIDebugMode(debugMode)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
function AIPage({ auth, ai, fetchMoreSession, createSession, setToast, fetchToSession, setDebugMode }: PropsFromRedux) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [sessionSidebarOpen, setSessionSidebarOpen] = useState(true);
  const [currentSession, setCurrentSession] = useState<AIChatSession>();
  const isSm = useMediaQuery('(max-width:700px)');
  const { aiChatSessionId } = useParams<AIUrlParams>();

  useEffect(() => {
    if (!ai?.sessions.length) {
      fetchMoreSession();
    }
    setIsAdmin(hasPermission(auth?.account, ['AI.adminMode']));
  }, [auth]);

  useEffect(() => {
    if (aiChatSessionId) {
      const session = ai?.sessions?.find((s) => s.aiChatSessionId == +aiChatSessionId);
      if (session) {
        setCurrentSession(session);
      } else {
        ProsperoService.getSession(+aiChatSessionId).then(
          (s) => {
            if (s.aiChatSessionId) setCurrentSession(s);
          },
          (err) => {},
        );
        fetchToSession(+aiChatSessionId);
      }
    }
  }, [aiChatSessionId]);

  function handleNewSession(): void {
    createSession(undefined, undefined, (s) => {
      setCurrentSession(s);
    });
  }

  function handleSessionClick(session: AIChatSession): void {
    setCurrentSession(session);
  }

  return (
    <Grid className="ai-page" container>
      <Grid item flexGrow={1} style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
          <Grid container flexDirection="column" height={'100%'}>
            <Grid item>
              <PageHeader
                renderTitleArea={() => (
                  <Grid container p={1} pl={0} wrap="nowrap">
                    <Grid item flexGrow={1}>
                      <Grid container alignItems="center" wrap="nowrap">
                        <Grid item pr={1}>
                          <Grid container alignItems="center" flexWrap="nowrap">
                            <img src={ProsperoIcon} style={{ width: 24, height: 24 }} />
                          </Grid>
                        </Grid>
                        <Grid item flexGrow={1} textOverflow={'ellipsis'}>
                          <Tooltip
                            arrow
                            title={
                              <Typography variant="body1">
                                {ai.debugMode && <div>{`#${currentSession?.aiChatSessionId ?? ''}`}</div>}
                                <div>{currentSession?.title ?? 'New conversation'}</div>
                              </Typography>
                            }
                          >
                            <Typography variant="subtitle1" textOverflow="ellipsis" noWrap maxWidth={300}>
                              {currentSession?.title ?? 'New conversation'}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                renderActionArea={() => (
                  <Grid container>
                    <Grid item>
                      <IconButton size="small" onClick={() => setSessionSidebarOpen(!sessionSidebarOpen)}>
                        {sessionSidebarOpen ? <ChevronRight /> : <ChevronLeft />}
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                renderLeftArea={() => (
                  <Grid container>
                    <IconButton size="small" onClick={() => handleNewSession()}>
                      <AddBox />
                    </IconButton>
                  </Grid>
                )}
              />
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container height={'100%'} width={'100%'}>
                <AISessionMessages session={currentSession} onNewSessionCreated={(s) => setCurrentSession(s)} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item>
        <AISessionSidebar
          open={sessionSidebarOpen}
          autoHide={isSm}
          currentSessionId={currentSession?.aiChatSessionId}
          onSessionClick={(session) => handleSessionClick(session)}
          onDebugModeChange={isAdmin ? (mode) => setDebugMode(mode) : undefined}
          debugMode={ai?.debugMode}
          onHideClick={() => setSessionSidebarOpen(false)}
        />
      </Grid>
    </Grid>
  );
}
export default connector(AIPage);
