import { Dispatch } from 'redux';
import { AIActionTypes } from '../config/ActionTypes';
import { AIChatSession, AIMessageStruct } from '../../screens/Prospero/prospero.types';
import ProsperoService from '../../services/prospero.service';
import { RootState } from '../config/types';

export const aiFetchMoreSessions = () => {
  return (dispatch: Dispatch<AIActionTypes>, getState: () => RootState) => {
    if (getState().ai?.fetching) {
      return;
    }
    dispatch(fetchingMoreSessions());
    const minDate = getState().ai?.sessions.reduce(
      (min: number, session) => Math.min(new Date(session.date).valueOf(), min),
      new Date().valueOf() + 1000,
    );

    return ProsperoService.loadSessions(minDate).then(
      (response) => {
        dispatch(fetchMoreSessionsSuccess(response ?? []));
      },
      (error) => {
        dispatch(fetchMoreSessionsFailed(error));
      },
    );
  };
};

export const aiFetchToSession = (sessionId: number) => {
  return (dispatch: Dispatch<AIActionTypes>, getState: () => RootState) => {
    dispatch(fetchingMoreSessions());
    const session = getState().ai?.sessions.find((s) => s.aiChatSessionId === sessionId);
    if (session) {
      dispatch(fetchMoreSessionsSuccess([]));
    } else {
      const minDate = getState().ai?.sessions.reduce(
        (min: number, session) => Math.min(new Date(session.date).valueOf(), min),
        new Date().valueOf() + 1000,
      );
      return ProsperoService.loadSessions(minDate, sessionId).then(
        (response) => {
          dispatch(fetchMoreSessionsSuccess(response ?? []));
        },
        (error) => {
          dispatch(fetchMoreSessionsFailed(error));
        },
      );
    }
  };
};

export const aiAddMessageToSessions = (
  message: AIMessageStruct,
  session: AIChatSession,
  /* eslint-disable */
  onComplete?: (conversation?: AIMessageStruct[]) => void,
  handleStatusChange?: (chatStatus: string) => void,
  handleStreamMessage?: (streamStr: string) => void,
  handleResponseRecieve?: (response: string) => void,
  /* eslint-enable */
) => {
  return (dispatch: Dispatch<AIActionTypes>) => {
    dispatch(fetchingMoreSessions());
    if (session) {
      const sessionId = session?.aiChatSessionId;
      if (session?.conversation.length === 0) {
        ProsperoService.editChatSessionTitle(sessionId, message.message)
          .then(() => {
            session.title = message.message;
            ProsperoService.sendChatMessage(
              sessionId,
              message,
              handleStatusChange,
              handleStreamMessage,
              handleResponseRecieve,
            )
              .then((conversation) => {
                session.conversation = conversation;
                dispatch(fetchMoreSessionsSuccess([session]));
                if (onComplete) onComplete(conversation);
              })
              .catch((err) => {
                throw err;
              });
          })
          .catch((err) => {
            console.log(err);
            dispatch(fetchMoreSessionsFailed(err.message));
          });
      } else {
        ProsperoService.sendChatMessage(
          sessionId,
          message,
          handleStatusChange,
          handleStreamMessage,
          handleResponseRecieve,
        )
          .then((conversation) => {
            // console.log(responseMessages);
            session.conversation = conversation;
            dispatch(fetchMoreSessionsSuccess([session]));
            if (onComplete) onComplete(conversation);
          })
          .catch((err) => {
            console.log(err);
            dispatch(fetchMoreSessionsFailed(err.message));
            if (onComplete) onComplete();
          });
      }
    }
  };
};

export const aiCreateNewSession = (
  title?: string,
  message?: AIMessageStruct,
  /* eslint-disable */
  onComplete?: (s: AIChatSession) => void,
  handleStatusChange?: (chatStatus: string) => void,
  handleStreamMessage?: (streamStr: string) => void,
  handleResponseRecieve?: (response: string) => void,
  /* eslint-enable */
) => {
  return (dispatch: Dispatch<AIActionTypes>, getState: () => RootState) => {
    const aiState = getState().ai;
    if (aiState.sessions.length && !aiState.sessions[0].conversation.length) {
      if (onComplete) onComplete(aiState.sessions[0]);
      return;
    }
    dispatch(fetchingMoreSessions());
    ProsperoService.createChatSession(title)
      .then((session: AIChatSession) => {
        if (message) {
          ProsperoService.sendChatMessage(
            session.aiChatSessionId,
            message,
            handleStatusChange,
            handleStreamMessage,
            handleResponseRecieve,
          )
            .then((conversation) => {
              session.conversation = conversation;
              dispatch(fetchMoreSessionsSuccess([session]));
              if (onComplete) onComplete(session);
            })
            .catch((err) => {
              throw err;
            });
        } else {
          dispatch(fetchMoreSessionsSuccess([session]));
          if (onComplete) onComplete(session);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(fetchMoreSessionsFailed(err.message));
      });
  };
};

export const fetchingMoreSessions = (): AIActionTypes => ({
  type: 'AI_FETCHING_SESSIONS',
});

export const fetchMoreSessionsSuccess = (sessions: AIChatSession[]): AIActionTypes => ({
  type: 'AI_FETCH_SESSION_SUCCESS',
  sessions,
});

export const fetchMoreSessionsFailed = (error: string): AIActionTypes => ({
  type: 'AI_FETCH_SESSION_FAILED',
  error,
});

export const setAIDebugMode = (debugMode: boolean | undefined): AIActionTypes => ({
  type: 'AI_DEBUG_MODE',
  debugMode,
});
