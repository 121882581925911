import { Button, CardActionArea, Grid, IconButton, Paper } from '@mui/material';
import CardHeader from '../../components/Card/CardHeader';
import Card from '../../components/Card/Card';
import { Add } from '@material-ui/icons';
import MotiveLogo from './connectors/Motive/logo';
import { useHistory } from 'react-router-dom';
import CardBody from '../../components/Card/CardBody';

export default function IntegrationPage() {
  const history = useHistory();
  const connect = (connector: string) => {
    history.push(`integrations/${connector}`);
  };
  return (
    <Card>
      <CardHeader color="primary">
        <Grid container>
          <Grid item flexGrow={1}>
            Integrations
          </Grid>
          <Grid item>
            <IconButton size="small">
              <Add style={{ color: 'white' }} />
            </IconButton>
          </Grid>
        </Grid>
      </CardHeader>
      <Paper>
        <Grid container p={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardHeader>
                <MotiveLogo />
              </CardHeader>
              <CardBody>
                Motive is a technology company that creates hardware and software to improve the safety and productivity
                of physical operations. Motive serves a wide range of industries including transportation and logistics,
                construction, energy, field service, manufacturing, agriculture, food and beverage, passenger transit,
                retail, and the public sector.
              </CardBody>
              <CardActionArea>
                <Button
                  onClick={() => {
                    connect('motive');
                  }}
                >
                  Connect to Motive
                </Button>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Card>
  );
}
